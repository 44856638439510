import React, { useContext, useEffect, useState, useImperativeHandle, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import { useForm, FormProvider } from 'react-hook-form';
import { useStateMachine } from 'little-state-machine';
import {
  Box,
  Grid,
  Typography,
  makeStyles,
  Container,
  Collapse
} from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import AdGroupBasics from './AdGroupBasics';
import AdGroupBudgeting from './AdGroupBudgeting';
import FileUploader from './FileUploader';
import AdGroupDemo from './AdGroupDemo';
import AdGroupGeo from './AdGroupGeo';
import AdvertiserContext from './AdvertiserContext';
import CreativePreview from './CreativePreview';
import CustomAppTargeting from './CustomAppTargeting';
import DayParting from './DayParting';
import FrequencyCaps from './FrequencyCaps';
import DeviceOverlay from './DeviceOverlay';
import ModalWrapper from './ModalWrapper';
import Inventory from './Inventory';
import SelectCategories from './SelectCategories';
import SelectGenres from './SelectGenres';
import SelectDeals from './SelectDeals';
import Title from './Title';
import { convertArrayToObjectByKey, getMinorityData } from '../helpers';
import { updateDeviceTypes, updateOperatingSystems } from '../actions';
import {
  CurrentDayPartsCopiesThemes,
  TargetingTypes,
  Themes,
} from '../constants';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails
} from './ui/Accordians';
import { useAPI } from './hooks/api';
import { useDate } from './hooks/date';
import { useGeo } from './hooks/geo';
import { useLoader } from './hooks/loader';
import { useUpload } from './hooks/upload';
import { useSelectEntireUS } from './hooks';
import { getTargetingList } from './util';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    height: 'auto',
  },
  panelHeader: {
    backgroundColor: '#e5e7eb',
  },
  panels: {
    height: 720,
    minHeight: 720,
  },
}));

// Edit sections for ad groups
const editPanels = [
  { title: 'Ad Group Basics', id: 'basics' },
  { title: 'Targeting', id: 'targeting' },
  { title: 'Demographics', id: 'demo' },
  { title: 'Inventory', id: 'inventory' },
  { title: 'Creative', id: 'creatives' },
  { title: 'Budgeting', id: 'budgeting' },
];

const initialTag = { name: '', vast_tag_url: '' };

const compareArrays = (data1 = [], data2 = []) => {
  if (data1.length !== data2.length) {
    return true;
  }

  let hasEdited = false;

  data1.forEach(d => {
    if (!data2.includes(d)) {
      hasEdited = true;
    }
  });

  return hasEdited;
};

const MemoDemo = memo(AdGroupDemo);

const ManageAdGroup = props => {
  const {
    adGroup,
    campaign,
    hasControlAdGroup,
    isOpen,
    isModal,
    isNew,
    isSingleEdit,
    showVastTag,
    showGenre,
    onClose,
    adGroupRef,
    setHasSaved,
    setCampaign,
    title,
  } = props;

  const classes = useStyles();
  const adContext = useContext(AdvertiserContext);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { useGet, useGetAll, usePatch, usePost } = useAPI();
  const { isLoading, setIsLoading } = useLoader(true);
  const { isLoading: isUploading, setIsLoading: setIsUploading } =
    useLoader();
  const {
    isLoading: isFetchingCreatives,
    setIsLoading: setIsFetchingCreatives
  } = useLoader(true);
  const { formatDateTime } = useDate();
  const { deleteFile } = useUpload();

  const {
    formatGeo,
    formatGeoName,
    geo,
    geoFilters,
    geoUrl,
    setGeo,
    setGeoFilters,
  } = useGeo();

  const { selectEntireUS, setSelectEntireUS } = useSelectEntireUS({ geo, setGeo });

  const { state, actions } = useStateMachine({ updateDeviceTypes, updateOperatingSystems });

  const formMethods = useForm({
    defaultValues: {
      vastTags: [initialTag],
      creatives: []
    },
    mode: 'onBlur'
  });

  const { watch, formState, setValue, handleSubmit, control } = formMethods;

  const creatives = watch('creatives');

  const {
    cpm: adGroupCpm,
    creatives: adGroupCreatives,
    daily_budget,
    id: adGroupId,
    end_date,
    freq_caps,
    name: adGroupName,
    start_date,
  } = adGroup;

  const [isExpanded, setIsExpanded] = useState({
    basics: true,
    targeting: true,
    demo: true,
    inventory: true,
    creatives: true,
    budgeting: true,
  });

  // Ad Group Basics state
  const [name, setName] = useState(adGroupName);
  const [startDate, setStartDate] = useState(start_date);
  const [endDate, setEndDate] = useState(end_date);
  const initialCpm = () => adGroupCpm ? adGroupCpm : '';
  const [cpm, setCpm] = useState(initialCpm());
  const [dayParts, setDayParts] = useState([]);
  const [isDayParts, setIsDayParting] = useState(false);
  const [freqCaps, setFreqCaps] = useState(freq_caps);
  const [isFreqCaps, setIsFreqCaps] = useState(false);
  const [deliveryTime, setDeliveryTime] = useState('est');

  const isStartBefore = !adGroup.draft && moment.utc(start_date).isBefore();
  const isEndBefore = !adGroup.draft && moment.utc(end_date).isBefore();

  // Demographics state
  const [age, setAge] = useState([2, 65]);
  const [gender, setGender] = useState('All');
  const [income, setIncome] = useState([30, 250]);
  const [isAdvanced, setIsAdvanced] = useState(false);
  const [segments, setSegments] = useState([]);
  const [audiences, setAudiences] = useState([]);
  const [isDefaultDemo, setIsDefaultDemo] = useState(true);
  const [isNoBidFees, setIsNoBidFees] = useState(false);

  // Inventory state
  const [bundles, setBundles] = useState([]);
  const [deals, setDeals] = useState([]);
  const [tier, setTier] = useState('premium-reach');
  const [isCustomTargeting, setIsCustomTargeting] = useState(false);
  const [customTargeting, setCustomTargeting] = useState({});
  const [screenSize, setScreenSize] = useState('custom');

  // Creatives state
  const [creativeUrls, setCreativeUrls] = useState([]);
  const [deleteQueue, setDeleteQueue] = useState([]);

  const [currentPreview, setCurrentPreview] = useState(null);
  const [isCreativePreview, setIsCreativePreview] = useState(false);
  const [overlayShown, showOverlay] = useState(false);
  const [inventoryOverlay, setInventoryOverlay] = useState(false);

  const initialCampaignBudget = () =>
    campaign && campaign.daily_budget ? campaign.daily_budget : '';
  const [campaignBudget, setCampaignBudget] = useState(
    initialCampaignBudget()
  );
  const [campaignHoldOut, setCampaignHoldOut] = useState(null);
  const [adGroups, setAdGroups] = useState([]);
  const [displays, setDisplays] = useState([]);
  const [dailyBudget, setDailyBudget] = useState(daily_budget);
  const [isDateBeforeStartDate, setIsDateBeforeStartDate] = useState(false);
  const [isDateAfterEndDate, setIsDateAfterEndDate] = useState(false);

  const [errors, setErrors] = useState([]);
  const [isAgeError, setIsAgeError] = useState(false);

  const [isDeviceOverlay, setIsDeviceOverlay] = useState(false);
  const [allowedTargeting, setAllowedTargeting] = useState(null);

  const [advertiserHasPrivateDeals, setAdvertiserHasPrivateDeals] = useState(false)

  const targetingData = () => JSON.parse(adGroup.targeting);

  async function getAllowedTargeting() {
    const { results: allowedTargeting } = await useGet('/allowed_targeting/');

    setAllowedTargeting(allowedTargeting);
  }

  async function getPrivateBundles() {
    try {
      setIsLoading(true)
      await useGetAll('/bundles', [], total => {
        const bundlesWithPrivateDeals = total.filter(p => p.private);
        if (bundlesWithPrivateDeals.length > 0) {
          setAdvertiserHasPrivateDeals(true);
        }
      })
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  useImperativeHandle(adGroupRef, () => ({
    handleUpdateAdGroup: () => updateAdGroup({ creatives }),
  }));

  // HACK: make sure formState is read before render to enable the Proxy [https://react-hook-form.com/advanced-usage#FormProviderPerformance]
  useEffect(() => {
    formState.isDirty;
    formState.dirtyFields;
  }, [formState.isDirty, formState.dirtyFields]);

  useEffect(() => {
    getAllowedTargeting();
  }, []);

  useEffect(() => {
    if (!allowedTargeting) {
      return;
    }

    const { targeting } = adGroup || {};
    const targetingData = typeof targeting === 'string' ? JSON.parse(targeting) : targeting || {};
    const list = getTargetingList(targetingData, allowedTargeting);

    actions.updateDeviceTypes(list[TargetingTypes.DEVICE]);
    actions.updateOperatingSystems(list[TargetingTypes.OS]);
  }, [allowedTargeting, adGroup && adGroup.targeting]);


  useEffect(() => {
    if (adGroup) {
      getPrivateBundles();
    }
  }, [adGroup]);


  useEffect(() => {
    // Handle stringified targeting data from BE
    if (adGroup && adGroup.targeting) {
      const targeting = targetingData();

      if (targeting) {
        if (targeting.geo) {
          parseGeoData(targeting.geo);
        }

        if (targeting.inventory) {
          parseInventoryData(targeting.inventory, targeting.bundles);
        }

        if (targeting.bundles && targeting.bundles.length > 0) {
          if (targeting.inventory === 'bundles') {
            setBundles(targeting.bundles);
          }

          if (targeting.inventory === 'private') {
            setDeals(targeting.bundles);
          }
        }

        if (targeting.age) {
          parseDemographics(targeting);
        }

        if (targeting.segments && targeting.segments.length > 0) {
          parseSegments(targeting);
        }

        if (targeting.dayparting) {
          setDayParts(targeting.dayparting)
        }

        if (targeting.timezone) {
          setDeliveryTime(targeting.timezone)
        }

        setCustomTargeting({
          app_name: targeting.app_name,
          app_id_list: targeting.app_id_list,
          app_bundle_list: targeting.app_bundle_list,
          publisher_id: targeting.publisher_id,
        });

        if (targeting.small_screen) {
          setScreenSize('both');
        }

        if (targeting.screen_size) {
          setScreenSize(targeting.screen_size);
        }
      }
    }

    return () => {
      closeSnackbar();
    }
  }, []);

  useEffect(() => {
    if (
      age &&
      age[0] === 2 &&
      age[1] === 18
    ) {
      setIsAgeError(true);

      if (props.setIsAgeError) {
        props.setIsAgeError(true);
      }
    } else {
      setIsAgeError(false);

      if (props.setIsAgeError) {
        props.setIsAgeError(false);
      }
    }
  }, [age]);

  useEffect(() => {
    const isDateBeforeStartDate = moment(startDate).isBefore(campaign.start_date);

    setIsDateBeforeStartDate(isDateBeforeStartDate);
  }, [startDate, campaign]);

  useEffect(() => {
    const isDateAfterEndDate = moment(endDate).isAfter(campaign.end_date);

    setIsDateAfterEndDate(isDateAfterEndDate);
  }, [endDate, campaign]);

  useEffect(() => {
    if (isDateBeforeStartDate || isDateAfterEndDate) {
      const dateWarning =
        "Extending a date outside the Campaign alters the Campaign's dates";
      enqueueSnackbar(dateWarning, {
        variant: 'warning',
      });
    }
  }, [isDateBeforeStartDate, isDateAfterEndDate]);

  useEffect(() => {
    const campaignHoldOut = campaign && campaign.experiment_type ? campaign.experiment_type : '1PCT_HOLDOUT';

    setCampaignHoldOut(campaignHoldOut);
  }, [campaign]);

  useEffect(() => {
    setCreativeUrls(creatives.filter(c => c.url).map(c => c.url));
    setIsFetchingCreatives(false);
  }, [creatives]);

  useEffect(() => {
    if (adGroup && adGroup.id) {
      loadCreatives(adGroup);
    }
  }, [adGroup]);

  useEffect(() => {
    const creativesWithTagUrl = creatives.filter(i => i.vast_tag_url);
    const vastTags = creativesWithTagUrl.length > 0 ? creativesWithTagUrl : [initialTag];

    setValue('vastTags', vastTags);
  }, [creatives]);

  const isSaveDisabled = useMemo(() => (
    isFetchingCreatives || isAgeError || creativeUrls.length === 0
  ), [isAgeError, isFetchingCreatives, creativeUrls])

  const loadCreatives = async ({ id, creatives: creativeUrls = [] }) => {
    const { results: creativeAdGroups } = await getCreativeAdGroups(id);
    const creatives = await getCreatives(creativeUrls);
    const creativeAdGroupsByCreativeUrl = convertArrayToObjectByKey(creativeAdGroups, 'creative');

    const nextCreatives = creatives.map((creative) => {
      const { weighting } = creativeAdGroupsByCreativeUrl[creative.url] || {};

      return ({
        ...creative,
        weighting,
      });
    });

    setValue('creatives', nextCreatives, { shouldDirty: false, shouldTouch: false });
  };

  async function getAudiences() {
    return await useGetAll('/audiences', [], total => {
      if (total && total.length > 0) {
        setAudiences(total);
      }
    });
  }

  const parseGeoData = geoData => {
    // First flatten array
    const geoResults = Object.keys(geoData).reduce(
      (results, current) =>
        geoData[current] && geoData[current].length > 0
          ? [...results, ...geoData[current]]
          : results,
      []
    );

    geoResults.forEach(g => {
      const id = g.place_name ? g.id : g.code;

      setGeoFilters(filters => ({
        ...filters,
        [id]: g.blacklist,
      }));
    })
  };

  const parseInventoryData = (inventory, bundles) => {
    if (
      inventory === 'premium-reach' ||
      (inventory === 'bundles' && bundles && bundles.includes('Run Of Network'))
    ) {
      setTier('reach');
    } else {
      setTier(inventory);
    }
  };

  const parseDemographics = ({ age, gender, income, advanced }) => {
    setAge(age);
    setGender(gender);
    setIncome(income);
    setIsAdvanced(advanced);
    handleDefaultDemo(gender, age, income);
  };

  const parseSegments = async targeting => {
    await getAudiences();
    handleNoBidFees(targeting.segments);
    setSegments(targeting.segments);
  };

  const validateName = () => name && name !== '';
  const validateCPM = () => cpm && cpm !== '' && parseFloat(cpm) !== 0;

  const getNewAdGroupObject = () => {
    const { deviceTypes, operatingSystems } = state.data.inventoryStep.deviceOverlayModal;

    const deviceTypeMinorityData = getMinorityData(deviceTypes);
    const operatingSystemMinorityData = getMinorityData(operatingSystems);

    let data = {
      ...adGroup,
      cpm: parseFloat(cpm).toFixed(2),
      creatives: creativeUrls,
      daily_budget: dailyBudget,
      freq_caps: isNew ? props.freqCaps : freqCaps,
      name,
      targeting: {
        advanced: isAdvanced,
        age,
        dayparting: isNew ? props.dayParts : dayParts,
        gender,
        geo: formatGeo(geo),
        income,
        inventory: tier === 'reach' ? 'premium-reach' : tier,
        timezone: deliveryTime,
        screen_size: screenSize,
        device: {
          blacklist: !deviceTypeMinorityData.isSelectedMinority,
          ids: deviceTypeMinorityData.data.map(item => item.id)
        },
        os: {
          blacklist: !operatingSystemMinorityData.isSelectedMinority,
          ids: operatingSystemMinorityData.data.map(item => item.id)
        },
      },
    };

    if (!isDateBeforeStartDate) {
      data = {
        ...data,
        start_date: formatDateTime(startDate, startDate),
      };
    }

    if (!isDateAfterEndDate) {
      data = {
        ...data,
        end_date: formatDateTime(endDate, endDate),
      };
    }

    if (isAdvanced && segments.length > 0) {
      data = {
        ...data,
        targeting: {
          ...data.targeting,
          segments,
        },
      };
    }

    if (
      tier === 'reach' &&
      adContext.theme &&
      adContext.theme === Themes.NBCU
    ) {
      data = {
        ...data,
        targeting: {
          ...data.targeting,
          inventory: 'bundles',
          bundles: ['Run Of Network'],
        }
      }
    }

    if (bundles && bundles.length > 0 && tier === 'bundles') {
      data = {
        ...data,
        targeting: {
          ...data.targeting,
          bundles,
        },
      };
    }

    if (deals && deals.length > 0 && tier === 'private') {
      data = {
        ...data,
        targeting: {
          ...data.targeting,
          bundles: deals,
        },
      };
    }

    // Stringify targeting data for BE
    if (data.targeting) {
      data.targeting = JSON.stringify({
        ...data.targeting,
      });
    }

    return data;
  };

  const getAdGroupObject = () => {
    const { deviceTypes, operatingSystems } = state.data.inventoryStep.deviceOverlayModal;

    const deviceTypeMinorityData = getMinorityData(deviceTypes);
    const operatingSystemMinorityData = getMinorityData(operatingSystems);

    const data = {
      targeting: {
        screen_size: "custom",
        device: {
          blacklist: !deviceTypeMinorityData.isSelectedMinority,
          ids: deviceTypeMinorityData.data.map(item => item.id)
        },
        os: {
          blacklist: !operatingSystemMinorityData.isSelectedMinority,
          ids: operatingSystemMinorityData.data.map(item => item.id)
        },

      }
    };
    const targeting = targetingData();

    // Clear old `small_screen` field as it is deprecated
    if (targeting.small_screen) {
      delete targeting.small_screen;
    }

    if (name !== adGroupName) {
      data.name = name;
    }

    if (cpm !== adGroupCpm) {
      data.cpm = cpm;
    }

    if (startDate !== start_date) {
      data.start_date = startDate;
    }

    if (endDate !== end_date) {
      data.end_date = endDate;
    }

    const hasEditedFreqCaps = compareArrays(freq_caps, freqCaps);

    if (hasEditedFreqCaps) {
      data.freq_caps = freqCaps;
    }

    const hasEditedDayParts = compareArrays(dayParts, targeting.dayparting)

    if (hasEditedDayParts) {
      data.targeting = {
        ...data.targeting,
        dayparting: dayParts,
      }
    }

    const formattedGeo = formatGeo(geo);
    const hasEditedGeo =
      JSON.stringify(formattedGeo) !== JSON.stringify(targeting.geo);

    if (hasEditedGeo) {
      data.targeting = {
        ...data.targeting,
        geo: formattedGeo,
      };
    }

    if (age[0] !== targeting.age[0] || age[1] !== targeting.age[1]) {
      data.targeting = {
        ...data.targeting,
        age,
      };
    }

    if (gender !== targeting.gender) {
      data.targeting = {
        ...data.targeting,
        gender,
      };
    }

    if (
      income[0] !== targeting.income[0] ||
      income[1] !== targeting.income[1]
    ) {
      data.targeting = {
        ...data.targeting,
        income,
      };
    }

    if (isAdvanced !== targeting.advanced) {
      data.targeting = {
        ...data.targeting,
        advanced: isAdvanced,
      };
    }

    if (isAdvanced) {
      if (
        targeting.segments && compareArrays(segments, targeting.segments) ||
        !targeting.segments && compareArrays(segments, [])
      ) {
        data.targeting = {
          ...data.targeting,
          segments,
        };
      }
    } else {
      data.targeting = {
        ...data.targeting,
        segments: [],
      }
    }

    const dealId = tier === 'reach' ? 'premium-reach' : tier;

    if (dealId !== targeting.inventory) {
      data.targeting = {
        ...data.targeting,
        inventory: dealId,
      };
    }

    if (
      tier === 'reach' &&
      adContext.theme &&
      adContext.theme === Themes.NBCU
    ) {
      data.targeting = {
        ...data.targeting,
        inventory: 'bundles',
        bundles: ['Run Of Network'],
      };
    }

    if (tier === 'bundles') {
      const adGroupBundles = targeting.bundles ? targeting.bundles : [];
      const hasEditedBundles = compareArrays(bundles, adGroupBundles);
      const bundlesArray = bundles.filter(b => b !== 'Run Of Network');

      if (hasEditedBundles) {
        data.targeting = {
          ...data.targeting,
          bundles: bundlesArray,
        };
      }
    }

    if (tier === 'private') {
      const adGroupBundles = targeting.bundles ? targeting.bundles : [];
      const hasEditedBundles = compareArrays(deals, adGroupBundles);

      if (hasEditedBundles) {
        data.targeting = {
          ...data.targeting,
          bundles: deals,
        };
      }
    }

    if (screenSize !== targeting.screen_size) {
      data.targeting = {
        ...data.targeting,
        screen_size: screenSize,
      };
    }

    if (customTargeting?.app_name !== targeting.app_name)
      data.targeting.app_name = customTargeting?.app_name

    if (customTargeting?.app_id_list !== targeting.app_id_list)
      data.targeting.app_id_list = customTargeting?.app_id_list

    if (customTargeting?.app_bundle_list !== targeting.app_bundle_list)
      data.targeting.app_bundle_list = customTargeting?.app_bundle_list

    if (customTargeting?.publisher_id !== targeting.publisher_id)
      data.targeting.publisher_id = customTargeting?.publisher_id

    if (deliveryTime !== targeting.timezone) {
      data.targeting = {
        ...data.targeting,
        timezone: deliveryTime,
      }
    }

    const hasEditedCreatives = compareArrays(creativeUrls, adGroupCreatives);

    if (hasEditedCreatives) {
      data.creatives = creativeUrls;
    }

    // Stringify targeting data for BE
    if (data.targeting) {
      data.targeting = JSON.stringify({
        ...targeting,
        ...data.targeting,
      });
    }

    console.log('dataObj', data);
    console.log('dataObj in validate', data);

    return data;
  };

  const handleNoBidFees = segs => {
    if (audiences && audiences.length > 0) {
      const selected = segs.reduce((acc, curr) => {
        const audience = audiences.find(a => a.audience_name === curr);

        if (audience) {
          return [...acc, audience];
        }

        return acc;
      }, []);

      setIsNoBidFees(selected.some(a => a.no_fee));
    }
  };

  const handleDefaultDemo = (gender, age, income) => {
    let isDefault = true;

    if (gender && gender.toLowerCase() !== 'all') {
      isDefault = false;
    }

    if (age && age[0] !== 2 || age[1] !== 65) {
      isDefault = false;
    }

    if (income && income[0] !== 30 || income[1] !== 250) {
      isDefault = false;
    }

    setIsDefaultDemo(isDefault);
  };

  const handleClosePreview = () => {
    setCurrentPreview(null);
    setIsCreativePreview(false);
  };

  const handleIsUploading = val => {
    setIsUploading(val);

    if (props.setIsUploading) {
      props.setIsUploading(val);
    }
  };

  const handleExpanded = panel => event => {
    if (event && event.target != null && !isUploading && !isLoading) {
      setIsExpanded(x => ({
        ...x,
        [panel]: !x[panel],
      }));
    }
  };

  const handleDeleteQueue = id => {
    if (!deleteQueue.includes(id)) {
      setDeleteQueue(prev => [...prev, id]);
    }
  };

  const getCreativeAdGroups = adGroupId => useGet(`${process.env.API_URL}/v1/creative_lineitems/?line_item_id=${adGroupId}`, true);

  const getCreatives = (urls) => {
    const creativesPromises = urls.map(url => useGet(url, true));

    return Promise.all(creativesPromises);
  };

  const patchCreativeAdGroup = (id, data) => usePatch(`/creative_lineitems/${id}`, data);

  const patchCreative = (id, creative) => usePatch(`/creatives/${id}`, creative);

  const handleDeleteFile = key => {
    return deleteFile(`/creatives/${key}`)
      .then(response => {
        console.log('response from removing file', response);
        setDeleteQueue(prev => prev.filter(p => p !== key));
        return response;
      })
      .catch(error => console.log('Error deleting file:', error));
  };

  const handleDeleteCreatives = () => {
    const deleteRequests = deleteQueue.map(d => handleDeleteFile(d));

    return Promise.all(deleteRequests);
  };

  const patchAdGroup = (data) => {
    return usePatch(`/lineitems/${adGroupId}`, data);
  };

  const postAdGroup = (data) => {
    return usePost(`/lineitems/`, data);
  };

  const syncBudgets = () => {
    if (adGroups.length === 0 && displays.length === 0) {
      return;
    }

    const adGroupRequests = adGroups
      .filter(group => !group.is_control_group)
      .map(group => usePatch(`lineitems/${group.id}`, {
        active: group.active,
        daily_budget: hasControlAdGroup ? campaignBudget : group.daily_budget,
      }));

    const displayRequests = displays
      .filter(display => !display.is_control_group)
      .map(display => usePatch(`static_display_lineitems/${display.id}`, {
        active: display.active,
        daily_budget: display.daily_budget,
      }));

    return Promise.all([...adGroupRequests, ...displayRequests]);
  };

  const patchCampaign = (data) => {
    return usePatch(`/campaigns/${campaign.id}`, data);
  };

  const saveDraftAdGroup = async (e) => {
    e.preventDefault()
    setErrors([]);
    setIsLoading(true);

    // If ad group doesn't have a name, return
    if (!validateName()) {
      setIsLoading(false);
      setErrors(prev => [...prev, 'name']);
      enqueueSnackbar('Ad Group must have a name', {
        variant: 'warning',
      });
      return;
    }

    // If ad group doesn't have a cpm, return
    if (!validateCPM()) {
      setIsLoading(false);
      setErrors(prev => [...prev, 'cpm']);
      enqueueSnackbar('Ad Group must have a Max CPM Bid', {
        variant: 'warning',
      });
      return;
    }

    // If ad group doesn't have any creatives, return
    if (creativeUrls && creativeUrls.length === 0) {
      setIsLoading(false);
      enqueueSnackbar('Ad Group must have at least one creative', {
        variant: 'warning',
      });
      return;
    }

    const dataObj = getNewAdGroupObject();

    try {
      const { data, status } = await patchAdGroup({
        ...dataObj,
        draft: true,
        active: false
      });

      if (data && status && [400, 403].includes(status) && (data.targeting || data.detail)) {
        throw new Error(data);
      }

      if (status && [200, 201].includes(status) && deleteQueue.length) {
        await handleDeleteCreatives();
      }

      if (campaign.daily_budget && campaignBudget !== '' && campaign.daily_budget !== campaignBudget) {
        const { data } = await patchCampaign({
          daily_budget: campaignBudget
        });

        setCampaign(data);
      }

      if (campaign.experiment_type && campaign.experiment_type !== campaignHoldOut) {
        const { data } = await patchCampaign({
          experiment_type: campaignHoldOut
        });

        setCampaign(data);
      }

      if (startDate && endDate) {
        const { data } = await patchCampaign({
          start_date: startDate,
          end_date: endDate
        });

        setCampaign(data);
      }

      if (adGroups.length > 0) {
        await syncBudgets();
      }

      setHasSaved(true);
      onClose();
    } catch (error) {
      console.error(error);

      return error;
    } finally {
      setIsLoading(false);
    }
  };

  // Final API call to patch ad group
  const updateAdGroup = async ({ creatives }) => {
    setErrors([]);
    setIsLoading(true);

    // If ad group doesn't have a name, return
    if (!validateName()) {
      setIsLoading(false);
      setErrors(prev => [...prev, 'name']);
      enqueueSnackbar('Ad Group must have a name', {
        variant: 'warning',
      });
      return;
    }

    // If ad group doesn't have a cpm, return
    if (!validateCPM()) {
      setIsLoading(false);
      setErrors(prev => [...prev, 'cpm']);
      enqueueSnackbar('Ad Group must have a Max CPM Bid', {
        variant: 'warning',
      });
      return;
    }

    // If ad group doesn't have any creatives, return
    if (creativeUrls && creativeUrls.length === 0) {
      setIsLoading(false);
      enqueueSnackbar('Ad Group must have at least one creative', {
        variant: 'warning',
      });
      return;
    }

    const dataObj = isNew ? getNewAdGroupObject() : getAdGroupObject();

    try {
      const { data, status } = isNew
        ? await postAdGroup(dataObj)
        : await patchAdGroup({
          ...dataObj,
          draft: false,
        });

      if (data && status && [400, 403].includes(status) && (data.targeting || data.detail)) {
        throw new Error(data);
      }

      if (status && [200, 201].includes(status) && deleteQueue.length) {
        await handleDeleteCreatives();
      }

      if (campaign.daily_budget && campaignBudget !== '' && campaign.daily_budget !== campaignBudget) {
        const { data } = await patchCampaign({
          daily_budget: campaignBudget
        });

        setCampaign(data);
      }

      if (formState.dirtyFields.creatives && !isNew) {
        const { results: creativeAdGroups } = await getCreativeAdGroups(adGroup.id);
        const creativeAdGroupsByCreativeUrl = convertArrayToObjectByKey(creativeAdGroups, 'creative');
        await Promise.all(creatives.map(async ({ id, weighting, url, ...creative }) => {
          const { id: creativeAdGroupId } = creativeAdGroupsByCreativeUrl[url] || {};

          const { data: nextCreativeAdGroup } = await patchCreativeAdGroup(creativeAdGroupId, { weighting });
          const { data: nextCreative } = await patchCreative(id, creative);

          setValue(`creatives.${id}`, {
            ...creative,
            ...nextCreative,
            weighting: nextCreativeAdGroup.weighting,
          });
        }));
      }

      if (campaign.experiment_type && campaign.experiment_type !== campaignHoldOut) {
        const { data } = await patchCampaign({
          experiment_type: campaignHoldOut
        });

        setCampaign(data);
      }

      if (startDate && endDate) {
        const { data } = await patchCampaign({
          start_date: startDate,
          end_date: endDate
        });

        setCampaign(data);
      }

      if (adGroups.length > 0) {
        await syncBudgets();
      }

      setHasSaved(true);
      onClose();
    } catch (error) {
      console.warn(error);

      return error;
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseOverlay = () => {
    setIsFreqCaps(false);
    setIsDayParting(false);
    setIsDeviceOverlay(false);
  };

  const renderBasics = () => (
    <AdGroupBasics
      isError={errors.includes('name')}
      isNew={isNew}
      isStartBefore={isStartBefore}
      isEndBefore={isEndBefore}
      name={name}
      freqCaps={isNew ? props.freqCaps : freqCaps}
      setFreqCaps={isNew ? props.setFreqCaps : setFreqCaps}
      setIsFreqCaps={isNew ? props.setIsFreqCaps : setIsFreqCaps}
      dayParts={isNew ? props.dayParts : dayParts}
      setDayParts={isNew ? props.setDayParts : setDayParts}
      setIsDayParting={isNew ? props.setIsDayParting : setIsDayParting}
      currentDayPartsCopy={CurrentDayPartsCopiesThemes[adContext.theme]}
      endDate={endDate}
      startDate={startDate}
      setName={setName}
      setStartDate={setStartDate}
      setEndDate={setEndDate}
      deliveryTime={deliveryTime}
      setDeliveryTime={setDeliveryTime}
    />
  );

  const renderTargeting = () => (
    <AdGroupGeo
      overlayShown={overlayShown}
      showOverlay={showOverlay}
      formatGeo={formatGeo}
      formatGeoName={formatGeoName}
      geo={geo}
      geoFilters={geoFilters}
      geoUrl={geoUrl}
      includeUS={selectEntireUS}
      setIncludeUS={setSelectEntireUS}
      setGeo={setGeo}
      setGeoFilters={setGeoFilters}
    />
  );

  const renderDemo = () => (
    <MemoDemo
      age={age}
      gender={gender}
      income={income}
      isAdvanced={isAdvanced}
      segments={segments}
      setAge={setAge}
      setGender={setGender}
      setIncome={setIncome}
      setIsAdvanced={setIsAdvanced}
      setSegments={setSegments}
      setIsDefaultDemo={setIsDefaultDemo}
    />
  );

  const renderInventory = () => (
    <Box mt={2} width="100%">
      <Container disableGutters maxWidth={false}>
        <Inventory
          screenSize={screenSize}
          handleScreenSize={setScreenSize}
          isManage
          isEditing
          tier={tier}
          setTier={setTier}
          showOverlay={showOverlay}
          overlayShown={overlayShown}
          onDeviceOverlayShow={isNew ? props.setIsDeviceOverlay : setIsDeviceOverlay}
          setInventoryOverlay={setInventoryOverlay}
          inventoryOverlay={inventoryOverlay}
          showRunOfNetwork={adContext.theme === Themes.NBCU}
          showBrandLogos={adContext.theme === Themes.DEFAULT}
          showAdvanced={adContext.theme === Themes.DEFAULT}
          showCustomApp={adContext.theme === Themes.DEFAULT}
          showGenre={showGenre}
          isDefaultDemo={isDefaultDemo}
          isNoBidFees={isNoBidFees}
          setIsLoading={setIsLoading}
          isCustomTargeting={isCustomTargeting}
          setIsCustomTargeting={setIsCustomTargeting}
          advertiserHasPrivateDeals={advertiserHasPrivateDeals}
        />

        <Box mt={2}>
          {/* {Bundles Inventory} */}
          <Collapse
            in={tier === 'bundles' && !showGenre}
          >
            <SelectCategories
              screenSize={screenSize}
              isEditing
              bundles={bundles}
              setBundles={setBundles}
              showOverlay={showOverlay}
              overlayShown={overlayShown}
              setInventoryOverlay={setInventoryOverlay}
              inventoryOverlay={inventoryOverlay}
              isDefaultDemo={isDefaultDemo}
              isNoBidFees={isNoBidFees}
            />
          </Collapse>

          {/* {Genre Inventory} */}
          <Collapse
            in={tier === 'bundles' && showGenre}
          >
            <SelectGenres
              screenSize={screenSize}
              isEditing
              isDefaultDemo={isDefaultDemo}
              isNoBidFees={isNoBidFees}
              tier={tier}
              setTier={setTier}
              bundles={bundles}
              setBundles={setBundles}
              showGenre={showGenre}
            />
          </Collapse>

          {/* {Private Inventory} */}
          <Collapse
            in={tier === 'private'}
          >
            <SelectDeals
              screenSize={screenSize}
              isEditing
              tier={tier}
              setTier={setTier}
              bundles={deals}
              setBundles={setDeals}
              isNoBidFees={isNoBidFees}
              isDefaultDemo={isDefaultDemo}
            />
          </Collapse>
        </Box>
      </Container>
    </Box>
  );

  // TODO: Handle race condition with useGet
  // so we dont have to pass it as a prop
  const renderCreatives = () => (
    <Box mt={2} width="100%">
      <Container disableGutters maxWidth={false}>
        <FileUploader
          accept="video/mp4, video/x-m4v, video/*"
          adGroupId={adGroupId}
          handleDeleteFile={handleDeleteQueue}
          setIsLoading={handleIsUploading}
          setCurrentPreview={setCurrentPreview}
          setIsCreativePreview={setIsCreativePreview}
          type="video"
          showVastTag={showVastTag}
          control={control}
          uploadView={
            creativeUrls && creativeUrls.length > 0
              ? 'progress'
              : 'start'
          }
        />
      </Container>
    </Box>
  );

  const renderBudgeting = () => (
    <AdGroupBudgeting
      isError={errors.includes('cpm')}
      isNew={isNew}
      isSingleEdit={isSingleEdit}
      adGroup={{
        ...adGroup,
        id: adGroup.id ? adGroup.id : '00-new',
        name,
      }}
      campaign={campaign}
      cpm={cpm}
      setAdGroups={setAdGroups}
      setCpm={setCpm}
      setCampaignBudget={setCampaignBudget}
      setCampaignHoldOut={setCampaignHoldOut}
      setDailyBudget={setDailyBudget}
      setDisplays={setDisplays}
    />
  );

  // Handle component renders
  const renderPanelDetails = id => {
    switch (id) {
      case 'basics':
        return renderBasics();
      case 'targeting':
        return renderTargeting();
      case 'demo':
        return renderDemo();
      case 'inventory':
        return renderInventory();
      case 'creatives':
        return renderCreatives();
      case 'budgeting':
        return renderBudgeting();
      default:
        return null;
    }
  };

  // Render an accordion panel for each editable section
  const renderPanel = panel => (
    <Accordion
      className={`panel-${panel.id}`}
      expanded={isExpanded[panel.id]}
      onChange={handleExpanded(panel.id)}
      TransitionProps={{ unmountOnExit: panel.id !== 'demo' }}
    >
      <AccordionSummary aria-controls="adgroup-panel" id={`edit-${panel.id}`}>
        <Grid
          className={classes.panelHeader}
          container
          alignItems="center"
          justify="space-between"
        >
          <Grid item>
            <Typography>{panel.title}</Typography>
          </Grid>

          <Grid item>
            {isExpanded[panel.id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Grid>
        </Grid>
      </AccordionSummary>

      <AccordionDetails
        style={{
          backgroundColor:
            overlayShown && panel.id === 'targeting' ? '#262f3c' : '',
        }}
      >
        <Box width="100%" mb={6}>
          {renderPanelDetails(panel.id)}
        </Box>
      </AccordionDetails>
    </Accordion>
  );

  const renderOverlay = () => (
    <>
      {isFreqCaps && (
        <FrequencyCaps
          isManage
          setIsFreqCaps={setIsFreqCaps}
          freqCaps={freqCaps}
          setFreqCaps={setFreqCaps}
        />
      )}

      {isDayParts && (
        <DayParting
          isManage
          setDayParting={setIsDayParting}
          dayParts={dayParts}
          setDayParts={setDayParts}
          title={`Configure ${adContext.theme === Themes.NBCU ? 'dayparting' : 'day-parting'}`}
        />
      )}

      {isCustomTargeting && (
        <CustomAppTargeting
          isManage
          setIsCustomTargeting={setIsCustomTargeting}
          customTargeting={customTargeting}
          setCustomTargeting={setCustomTargeting}
        />
      )}

      {isDeviceOverlay && (
        <DeviceOverlay onDeviceOverlayShow={isNew ? props.setIsDeviceOverlay : setIsDeviceOverlay} />
      )}
    </>
  )

  const renderContent = () => (
    <Box className={classes.container}>
      <Box
        className={classes.panels}
        display="flex"
        flexDirection="column"
        px={0}
        pb={22}
      >
        {!isNew &&
          <Box mb={2}>
            <Title>
              {title ? title : 'Manage Ad Group'}
            </Title>
          </Box>}

        <Box flexGrow={1}>
          <Grid container spacing={3}>
            {editPanels.map((s, i) => (
              <Grid key={s.id} item xs={12}>
                {s.id === 'budget'
                  ? (
                    <Box pb={10}>
                      {renderPanel(s, i)}
                    </Box>
                  ) : renderPanel(s, i)}
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      {isCreativePreview && (
        <Box>
          <CreativePreview
            handleClose={handleClosePreview}
            name={currentPreview.name}
            url={currentPreview.preview_url}
          />
        </Box>
      )}
    </Box>
  );

  const renderWithModal = () => (
    <ModalWrapper
      maxWidth="lg"
      isDisabled={isSaveDisabled}
      isOpen={isOpen}
      onClose={onClose}
      isLoading={isLoading}
      isUploading={isUploading}
      isOverlay={isFreqCaps || isDayParts || isCustomTargeting || isDeviceOverlay}
      overlay={renderOverlay()}
      onCloseOverlay={handleCloseOverlay}
      onSubmit={handleSubmit(updateAdGroup)}
      onSaveDraft={saveDraftAdGroup}
      submit={adGroup.draft ? 'Save & Activate Ad Group' : 'Save Ad Group'}
      hasSubmit
      hasSaveDraft={adGroup.draft}
      aria-labelledby="edit-ad-group-dialog"
    >
      {renderContent()}
    </ModalWrapper>
  );

  return (
    <FormProvider {...formMethods}>
      {isModal ? renderWithModal() : renderContent()}
    </FormProvider>
  );
};

ManageAdGroup.propTypes = {
  adGroup: PropTypes.object,
  campaign: PropTypes.object,
  hasControlAdGroup: PropTypes.bool,
  isOpen: PropTypes.bool,
  isModal: PropTypes.bool,
  isNew: PropTypes.bool,
  isSingleEdit: PropTypes.bool,
  showGenre: PropTypes.bool,
  showVastTag: PropTypes.bool,
  onClose: PropTypes.func,
  adGroupRef: PropTypes.object,
  setCampaign: PropTypes.func,
  setHasSaved: PropTypes.func,
  setIsAgeError: PropTypes.func,
  setIsUploading: PropTypes.func,
  setIsFreqCaps: PropTypes.func,
  freqCaps: PropTypes.array,
  setFreqCaps: PropTypes.func,
  dayParts: PropTypes.array,
  setDayParts: PropTypes.func,
  setIsDayParting: PropTypes.func,
  setIsDeviceOverlay: PropTypes.func,
  title: PropTypes.string,
};

export default ManageAdGroup;
