import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import Title from './Title';
import googleTagIcon from '../images/icon-google-tag-manager/icon-google-tag-manager.png';

const useStyles = makeStyles(() => ({
  red: {
    color: '#f30000',
  },
  alertInfo: {
    color: '#0081c8',
    fontSize: '0.75rem',
    fontWeight: 'normal',
    boxShadow: 'none',
    borderRadius: '8px',
    backgroundColor: 'rgba(29, 175, 255, 0.25)',
  },
}));

const InstallTrackingCode = ({
  handleInstallCompleted,
  handleInstallLater,
  installCompleted,
  installLater,
  isCampaign,
  ecommerce,
  exposureLid,
  outcomeLid,
  selectedEvents,
  setStep,
  trackingMethod,
  triggerSave,
  updateBreadcrumbs,
}) => {
  const classes = useStyles();

  const selectedEventsList = useMemo(() => {
    if (ecommerce === 'shopify') {
      return selectedEvents.filter(
        event => event.slug === 'complete_purchase'
      );
    }
    return selectedEvents;
  }, [selectedEvents]);

  const totalVisibleScripts = useMemo(() => {
    // selected events + 1 for the universal pixel
    let total = selectedEventsList.length + 1;

    // google will be shown only if there is no shopify
    if (ecommerce !== 'shopify') {
      total += 1;
    }

    return total;
  }, [selectedEventsList]);

  const handleEmailCode = id => {
    const text = document.getElementById(id).textContent;
    window.location = `mailto:?subject=tvScientific tracking code&body=${text}`;
  };

  const handleCopyCode = id => {
    const text = document.getElementById(id).textContent;
    window.navigator.clipboard.writeText(text);
  };

  const renderRed = text => <span className={classes.red}>{text}</span>;

  const renderPostbackDescription = slug => (
    <p>
      Configure your server or MMP to fire a post back using the following url
      when a {slug} event occurs. If you’d like to collect a dynamic event
      insert the appropriate variable in place of {'{{placeholders}}'}
    </p>
  );

  const renderPostbackCode = (event, value) => (
    <Fragment>
      {event !== 'complete_purchase' ? (
        <code>
          {`https://tvspix.com/t.png?ip=`}
          {renderRed('{{userIP}}')}
          {`&l=${outcomeLid}&u1=${event}&u2=`}
          {value && value !== '' && value !== '0'
            ? renderRed(value)
            : renderRed('{{value}}')}
          {`&u5=`}
          {renderRed('{{Referral Source}}')}
        </code>
      ) : (
        <code>
          {`https://tvspix.com/t.png?ip=`}
          {renderRed('{{userIP}}')}
          {`&l=${outcomeLid}&u1=${event}&u2=`}
          {value && value !== '' && value !== '0'
            ? renderRed(value)
            : renderRed('{{value}}')}
          {`&u4=`}
          {renderRed('{{transactionId}}')}
          {`&u5=`}
          {renderRed('{{Referral Source}}')}
        </code>
      )}
    </Fragment>
  );

  const renderPixelDescription = (slug, event, value) => (
    <Fragment>
      {{
        complete_purchase:
          ecommerce === 'shopify' ? (
            <>
              <p>
                Add the following code snippet to the page where
                complete_purchase occurs. For a page load event, add the code
                immediately below the {'<head>'} tag. For an inline action,
                add the code to the relevant HTML element or on a button click
                via a tag manager like GTM. For additional questions, please
                see documentation here.
              </p>
              <p>Shopify Instructions for placing conversion pixels:</p>
              <ol>
                <li>Add conversion tracking to your order status page</li>
                <li>
                  From your Shopify admin, go to Settings {'>'} Checkout.
                </li>
                <li>
                  Under Order Processing, go to the Additional Scripts text
                  box.
                </li>
                <li>Add the tvScientific Pixel and save.</li>
              </ol>
            </>
          ) : (
            <p>
              Add the following code snippet to the page where
              complete_purchase occurs. For a page load event, add the code
              immediately below the &nbsp;{'<head>'} tag. For an inline
              action, add the code to the relevant HTML element or on a button
              click via a tag manager like GTM. If you&apos;d like to collect
              a dynamic purchase value insert the appropriate variable in
              place of &nbsp;{`{{Order Value}}`} and if you&apos;d like to
              collect a dynamic order ID place the appropriate variable in
              place of &nbsp;{`{{Order ID}}`}.
            </p>
          ),
        viewed_product: (
          <p>
            Add the following code snippet to the page where viewed_product
            occurs. For a page load event, add the code immediately below the
            &nbsp;{'<head>'} tag. For an inline action, add the code to the
            relevant HTML element or on a button click via a tag manager like
            GTM. If you&apos;d like to collect a dynamic Viewed product insert
            the appropriate variable in place of &nbsp;
            {`{{Product Page Name}}`}. If you&apos;d like to collect a dynamic
            referral (last touch channel), place the appropriate variable in
            place of &nbsp;{`{{Referral Source}}`}.
          </p>
        ),
        lead_generated: (
          <p>
            Add the following code snippet to the page where lead_generated
            occurs. For a page load event, add the code immediately below the
            &nbsp;{'<head>'} tag. For an inline action, add the code to the
            relevant HTML element or on a button click via a tag manager like
            GTM. If you&apos;d like to collect a dynamic referral (last touch
            channel), place the appropriate variable in place of &nbsp;
            {`{{Referral Source}}`}. If you&apos;d like to collect a dynamic
            Lead ID place the appropriate variable in place of&nbsp;{' '}
            {`{{Lead Id}}`}.
          </p>
        ),
        add_to_cart: (
          <p>
            Add the following code snippet to the page where add_to_cart
            occurs. For a page load event, add the code immediately below the
            &nbsp;{'<head>'} tag. For an inline action, add the code to the
            relevant HTML element or on a button click via a tag manager like
            GTM. If you&apos;d like to collect a dynamic referral (last touch
            channel), place the appropriate variable in place of &nbsp;
            {`{{Referral Source}}`}.
          </p>
        ),
        trial_started: (
          <p>
            Add the following code snippet to the page where trial_started
            occurs. For a page load event, add the code immediately below the
            &nbsp;{'<head>'} tag. For an inline action, add the code to the
            relevant HTML element or on a button click via a tag manager like
            GTM. If you&apos;d like to collect a dynamic order ID, place the
            appropriate variable in place of &nbsp;{`{{Trial ID}}`}. If
            you&apos;d like to collect a dynamic referral (last touch
            channel), place the appropriate variable in place of &nbsp;
            {`{{Referral Source}}`}.
          </p>
        ),
        start_checkout: (
          <p>
            Add the following code snippet to the page where start_checkout
            occurs. For a page load event, add the code immediately below the
            &nbsp;{'<head>'} tag. For an inline action, add the code to the
            relevant HTML element or on a button click via a tag manager like
            GTM. If you&apos;d like to collect a dynamic referral (last touch
            channel), place the appropriate variable in place of &nbsp;
            {`{{Referral Source}}`}.
          </p>
        ),
        keyword_search: (
          <p>
            Add the following code snippet to the page where keyword_search
            occurs. For a page load event, add the code immediately below the
            &nbsp;{'<head>'} tag. For an inline action, add the code to the
            relevant HTML element or on a button click via a tag manager like
            GTM. If you&apos;d like to collect a keyword insert the
            appropriate variable in place of &nbsp;{`{{Keyword}}`}. If
            you&apos;d like to collect a dynamic referral (last touch
            channel), place the appropriate variable in place of &nbsp;
            {`{{Referral Source}}`}.
          </p>
        ),
        complete_registration: (
          <p>
            Add the following code snippet to the page where
            complete_registration occurs. For a page load event, add the code
            immediately below the &nbsp;{'<head>'} tag. For an inline action,
            add the code to the relevant HTML element or on a button click via
            a tag manager like GTM. If you&apos;d like to collect a dynamic
            order ID, place the appropriate variable in place of &nbsp;
            {`{{Registration ID}}`}. If you&apos;d like to collect a dynamic
            referral (last touch channel) , place the appropriate variable in
            place of &nbsp;{`{{Referral Source}}`}.
          </p>
        ),
        add_payment_info: (
          <p>
            Add the following code snippet to the page where add_payment_info
            occurs. For a page load event, add the code immediately below the
            &nbsp;{'<head>'} tag. For an inline action, add the code to the
            relevant HTML element or on a button click via a tag manager like
            GTM. If you&apos;d like to collect a dynamic referral (last touch
            channel), place the appropriate variable in place of &nbsp;
            {`{{Referral Source}}`}.
          </p>
        ),
      }[slug] || (
        <p>
          Add the following code snippet to the page where {slug} occurs. For
          a page load event, add the code immediately below the &nbsp;
          {'<head>'} tag. For an inline action, add the code to the relevant
          HTML element or on a button click via a tag manager like GTM. If
          you&apos;d like to collect a dynamic {event} insert the appropriate
          variable in place of {value} in &quot;u2={value}&quot;.
        </p>
      )}
    </Fragment>
  );

  const renderGoogleTagManagerIcon = () => (
    <Fragment>
      <Grid item xs={4}>
        <Button
          color="secondary"
          size="small"
          className="Button--small"
          href="https://tagmanager.google.com/"
          target="_blank"
        >
          <img src={googleTagIcon} style={{ marginRight: 5 }} />
          Google Tag Manager
        </Button>
      </Grid>
    </Fragment>
  );

  const renderPixelCode = (slug, value) => {
    switch (slug) {
      case 'complete_purchase':
        return (
          <code>
            {ecommerce === 'shopify' && `{% if first_time_accessed %}`}
            {ecommerce === 'shopify' && <br />}
            {`<script type="text/javascript">(function () {var p, s, d, w;d = document;w = window.location;p = d.createElement("IMG");s = w.protocol + "//tvspix.com/t.png?&t=" + (new Date).getTime() + "&l=${outcomeLid}&u3=" + encodeURIComponent(w.href) + "&u1=complete_purchase&u2=`}
            {renderRed(`{{Order Value}}`)}
            {`&u4=`}
            {renderRed(`{{Order ID}}`)}
            {`&u5=`}
            {renderRed(`{{Referral Source}}`)}
            {`";p.setAttribute("src", s);p.setAttribute("height", "0");p.setAttribute("width", "0");p.setAttribute("alt", "");p.style.setProperty("display", "none");p.style.setProperty("position", "absolute");p.style.setProperty("visibilty", "hidden");d.body.appendChild(p);})();</script>`}
            {ecommerce === 'shopify' && <br />}
            {ecommerce === 'shopify' && `{% endif %}`}
          </code>
        );

      case 'lead_generated':
        return (
          <code>
            {`<script type="text/javascript">(function () {var p, s, d, w;d = document;w = window.location;p = d.createElement("IMG");s = w.protocol + "//tvspix.com/t.png?&t=" + (new Date).getTime() + "&l=${outcomeLid}&u3=" + encodeURIComponent(w.href) + "&u1=${slug}&u4=`}
            {renderRed(`{{Lead Id}}`)}
            {`&u5=`}
            {renderRed(`{{Referral Source}}`)}
            {`";p.setAttribute("src", s);p.setAttribute("height", "0");p.setAttribute("width", "0");p.setAttribute("alt", "");p.style.setProperty("display", "none");p.style.setProperty("position", "absolute");p.style.setProperty("visibilty", "hidden");d.body.appendChild(p);})();</script>`}
          </code>
        );

      case 'viewed_product':
        return (
          <code>
            {`<script type="text/javascript">(function () {var p, s, d, w;d = document;w = window.location;p = d.createElement("IMG");s = w.protocol + "//tvspix.com/t.png?&t=" + (new Date).getTime() + "&l=${outcomeLid}&u3=" + encodeURIComponent(w.href) + "&u1=${slug}&u6=`}
            {renderRed(`{{Product Page Name}}`)}
            {`&u5=`}
            {renderRed(`{{Referral Source}}`)}
            {`";p.setAttribute("src", s);p.setAttribute("height", "0");p.setAttribute("width", "0");p.setAttribute("alt", "");p.style.setProperty("display", "none");p.style.setProperty("position", "absolute");p.style.setProperty("visibilty", "hidden");d.body.appendChild(p);})();</script>`}
          </code>
        );

      case 'trial_started':
        return (
          <code>
            {`<script type="text/javascript">(function () {var p, s, d, w;d = document;w = window.location;p = d.createElement("IMG");s = w.protocol + "//tvspix.com/t.png?&t=" + (new Date).getTime() + "&l=${outcomeLid}&u3=" + encodeURIComponent(w.href) + "&u1=${slug}&u4=`}
            {renderRed(`{{Trial Id}}`)}
            {`&u5=`}
            {renderRed(`{{Referral Source}}`)}
            {`";p.setAttribute("src", s);p.setAttribute("height", "0");p.setAttribute("width", "0");p.setAttribute("alt", "");p.style.setProperty("display", "none");p.style.setProperty("position", "absolute");p.style.setProperty("visibilty", "hidden");d.body.appendChild(p);})();</script>`}
          </code>
        );

      case 'keyword_search':
        return (
          <code>
            {`<script type="text/javascript">(function () {var p, s, d, w;d = document;w = window.location;p = d.createElement("IMG");s = w.protocol + "//tvspix.com/t.png?&t=" + (new Date).getTime() + "&l=${outcomeLid}&u3=" + encodeURIComponent(w.href) + "&u1=${slug}&u6=`}
            {renderRed(`{{Keyword}}`)}
            {`&u5=`}
            {renderRed(`{{Referral Source}}`)}
            {`";p.setAttribute("src", s);p.setAttribute("height", "0");p.setAttribute("width", "0");p.setAttribute("alt", "");p.style.setProperty("display", "none");p.style.setProperty("position", "absolute");p.style.setProperty("visibilty", "hidden");d.body.appendChild(p);})();</script>`}
          </code>
        );

      case 'complete_registration':
        return (
          <code>
            {`<script type="text/javascript">(function () {var p, s, d, w;d = document;w = window.location;p = d.createElement("IMG");s = w.protocol + "//tvspix.com/t.png?&t=" + (new Date).getTime() + "&l=${outcomeLid}&u3=" + encodeURIComponent(w.href) + "&u1=${slug}&u6=`}
            {renderRed(`{{Registration ID}}`)}
            {`&u5=`}
            {renderRed(`{{Referral Source}}`)}
            {`";p.setAttribute("src", s);p.setAttribute("height", "0");p.setAttribute("width", "0");p.setAttribute("alt", "");p.style.setProperty("display", "none");p.style.setProperty("position", "absolute");p.style.setProperty("visibilty", "hidden");d.body.appendChild(p);})();</script>`}
          </code>
        );

      case 'add_to_cart':
      case 'add_payment_info':
      case 'start_checkout':
        return (
          <code>
            {`<script type="text/javascript">(function () {var p, s, d, w;d = document;w = window.location;p = d.createElement("IMG");s = w.protocol + "//tvspix.com/t.png?&t=" + (new Date).getTime() + "&l=${outcomeLid}&u3=" + encodeURIComponent(w.href) + "&u1=${slug}`}
            {`&u5=`}
            {renderRed(`{{Referral Source}}`)}
            {`";p.setAttribute("src", s);p.setAttribute("height", "0");p.setAttribute("width", "0");p.setAttribute("alt", "");p.style.setProperty("display", "none");p.style.setProperty("position", "absolute");p.style.setProperty("visibilty", "hidden");d.body.appendChild(p);})();</script>`}
          </code>
        );

      default:
        return (
          <code>
            {`<script type="text/javascript">(function () {var p, s, d, w;d = document;w = window.location;p = d.createElement("IMG");s = w.protocol + "//tvspix.com/t.png?&t=" + (new Date).getTime() + "&l=${outcomeLid}&u3=" + encodeURIComponent(w.href) + "&u1=${slug}&u2=`}
            {renderRed(`${value}`)}
            {`&u5=`}
            {renderRed(`{{Referral Source}}`)}
            {`";p.setAttribute("src", s);p.setAttribute("height", "0");p.setAttribute("width", "0");p.setAttribute("alt", "");p.style.setProperty("display", "none");p.style.setProperty("position", "absolute");p.style.setProperty("visibilty", "hidden");d.body.appendChild(p);})();</script>`}
          </code>
        );
    }
  };

  const dynamicTrackingEvents = selectedEventsList.map(
    (trackingEvent, index) => {
      const { name, slug, value } = trackingEvent;
      const param = slug.replace(/-/g, ' ');

      return (
        <Fragment key={index}>
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <div className="Pill--primary">
                Step {index + 2} of {totalVisibleScripts}
              </div>
            </Grid>

            <Grid item container xs={6} alignContent="flex-start">
              <Grid item xs={12}>
                <Typography variant="h4" component="h4">
                  {trackingMethod === 'postback'
                    ? `Set up postback for ${slug}`
                    : `Install Code for ${slug}`}
                </Typography>

                {trackingMethod === 'postback'
                  ? renderPostbackDescription(slug)
                  : renderPixelDescription(slug, name, value)}
              </Grid>
            </Grid>

            <Grid item container xs={6}>
              <Grid item xs={12}>
                <Box id={`code-area-${index + 3}`}>
                  {trackingMethod === 'pixel' &&
                    renderPixelCode(trackingEvent.slug, value)}
                  {trackingMethod === 'postback' &&
                    renderPostbackCode(param, value)}
                </Box>
              </Grid>

              <Grid item container xs={12}>
                <Grid item xs={4}>
                  <Button
                    onClick={() => handleEmailCode(`code-area-${index + 3}`)}
                    color="secondary"
                    size="small"
                    className="Button--small"
                    startIcon={<MailOutlineIcon />}
                  >
                    Share via Email
                  </Button>
                </Grid>

                <Grid item xs={4}>
                  <Button
                    onClick={() => handleCopyCode(`code-area-${index + 3}`)}
                    color="secondary"
                    size="small"
                    className="Button--small"
                    startIcon={<FileCopyOutlinedIcon />}
                  >
                    Copy Snippet
                  </Button>
                </Grid>

                {trackingMethod === 'pixel' && renderGoogleTagManagerIcon()}
              </Grid>
            </Grid>
          </Grid>

          <Box p={3} />
        </Fragment>
      );
    }
  );

  return (
    <Fragment>
      {isCampaign && <Title>Install Tracking Code</Title>}

      <Grid container style={{ position: 'relative' }}>
        <Box
          border={1}
          borderColor="grey.300"
          borderRadius={9}
          p={4}
          mt={2}
          style={{ maxHeight: 509, overflow: 'auto' }}
        >
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <div className="Pill--primary">
                Step 1 of {totalVisibleScripts}
              </div>
            </Grid>

            <Grid item container xs={6} alignContent="flex-start">
              <Grid item xs={12}>
                <Typography variant="h4" component="h4">
                  {trackingMethod === 'pixel' &&
                    'Install Universal Site Pixel'}

                  {trackingMethod === 'postback' &&
                    'Install Universal Visit Postback'}
                </Typography>

                {trackingMethod === 'pixel' && (
                  <p>
                    Copy and paste the universal site code snippet on every
                    page of your website just before the closing {`</head>`}
                    tag or via a tag manager such as Google Tag Manager. This
                    small piece of code will give you the ability to see which
                    visits to your website came after being exposed to your TV
                    ad:
                  </p>
                )}

                {trackingMethod === 'postback' && (
                  <p>
                    In order to track visits to your website that can be
                    attributed to your tvScientfic ad exposure, you can send
                    this postback for each visit to your website. Instrument
                    your server or MMP to fire a post back using the following
                    url replacing the {`{{placeholders}}`}
                    with the appropriate variables for your use.
                  </p>
                )}
              </Grid>
            </Grid>

            <Grid item container xs={6}>
              <Grid item xs={12}>
                <Box id="code-area-1">
                  <code>
                    {trackingMethod === 'pixel' &&
                      `<script type="text/javascript">(function () {var p, s, d, w;d = document;w = window.location;p = d.createElement("IMG");s = w.protocol + "//tvspix.com/t.png?&t=" + (new Date).getTime() + "&l=${outcomeLid}&u3=" + encodeURIComponent(w.href);p.setAttribute("src", s);p.setAttribute("height", "0");p.setAttribute("width", "0");p.setAttribute("alt", "");p.style.setProperty("display", "none");p.style.setProperty("position", "absolute");p.style.setProperty("visibilty", "hidden");d.body.appendChild(p);})();</script>`}

                    {trackingMethod === 'postback' && (
                      <Fragment>
                        {`https://tvspix.com/t.png?ip=`}
                        {renderRed('{{userIP}}')}
                        {`&l=${outcomeLid}&u3=`}
                        {renderRed('{{pageURL}}')}
                      </Fragment>
                    )}
                  </code>
                </Box>
              </Grid>

              <Grid item container xs={12}>
                <Grid item xs={4}>
                  <Button
                    onClick={() => handleEmailCode('code-area-1')}
                    color="secondary"
                    size="small"
                    className="Button--small"
                    startIcon={<MailOutlineIcon />}
                  >
                    Share via Email
                  </Button>
                </Grid>

                <Grid item xs={4}>
                  <Button
                    onClick={() => handleCopyCode('code-area-1')}
                    color="secondary"
                    size="small"
                    className="Button--small"
                    startIcon={<FileCopyOutlinedIcon />}
                  >
                    Copy Snippet
                  </Button>
                </Grid>

                {trackingMethod === 'pixel' && renderGoogleTagManagerIcon()}
              </Grid>
            </Grid>
          </Grid>

          <Box p={3} />

          {dynamicTrackingEvents}

          {ecommerce !== 'shopify' && (
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <div className="Pill--primary">
                  Step {totalVisibleScripts} of {totalVisibleScripts}
                </div>
              </Grid>
              <Grid item container xs={6} alignContent="flex-start">
                <Grid item xs={12}>
                  <Typography variant="h4" component="h4">
                    tvScientific Google Analytics Plug-In
                  </Typography>

                  <p>
                    Deploy the following code snippet across your entire site
                    in Google Tag Manager or similar. Fire the script against
                    a trigger called &quot;landing page&quot; where you only
                    fire when the referrer is not equal to your website domain
                    ie yoursite.com. You will also need to create custom
                    &nbsp; &nbsp; &nbsp; dimensions in Google Analytics for
                    tvScientific Exposed as a session scope and tvScientific
                    Location as a session scope and let your account manager
                    know the dimension index. The&nbsp;
                    {`{{UA Tracking ID}}`} should either be a variable in your
                    GTM or replaced with your UA ID in-between the &apos;and
                    the&apos;.
                  </p>
                  <Alert
                    className={classes.alertInfo}
                    elevation={6}
                    variant="filled"
                    severity="info"
                  >
                    This script is for Universal Analytics only. It does not
                    function in GA4.
                  </Alert>
                </Grid>
              </Grid>
              <Grid item container xs={6}>
                <Grid item xs={12}>
                  <Box
                    mb={2}
                    id={`code-area-${
                      selectedEventsList.length
                        ? selectedEventsList.length + 3
                        : 3
                    }`}
                  >
                    <code>
                      {
                        '<script async src="https://www.googletagmanager.com/gtag/js?id={{UA Tracking ID}}"></script>'
                      }
                      <br />
                      {'<script>'}
                      <br />
                      {'window.dataLayer = window.dataLayer || [];'}
                      <br />
                      {'function gtag(){dataLayer.push(arguments)};'}
                      <br />
                      {`gtag('js', new Date());`}
                      <br />
                      <br />
                      {`gtag('config', '{{UA Tracking ID}}', {`}
                      <br />
                      {`name: 'tvScientific',`}
                      <br />
                      {`transport_url: 'https://proxy.tvsci.net',`}
                      <br />
                      {`custom_map: {`}
                      <br />
                      {`dimension1: 'tvsci_exp'`}
                      <br />
                      {`},`}
                      <br />
                      {`send_page_view: false`}
                      <br />
                      {`});`}
                      <br />
                      {`gtag('event', 'page_view', {`}
                      <br />
                      {`tvsci_exp: '${exposureLid}',`}
                      <br />
                      {`non_interaction: true`}
                      <br />
                      {`});`}
                      <br />
                      {`</script>`}
                    </code>
                  </Box>
                </Grid>

                <Grid item container xs={12}>
                  <Grid item xs={4}>
                    <Button
                      onClick={() =>
                        handleEmailCode(
                          `code-area-${
                            selectedEventsList.length
                              ? selectedEventsList.length + 2
                              : 2
                          }`
                        )
                      }
                      color="secondary"
                      size="small"
                      className="Button--small"
                      startIcon={<MailOutlineIcon />}
                    >
                      Share via Email
                    </Button>
                  </Grid>

                  <Grid item xs={4}>
                    <Button
                      onClick={() =>
                        handleCopyCode(
                          `code-area-${
                            selectedEventsList.length
                              ? selectedEventsList.length + 2
                              : 2
                          }`
                        )
                      }
                      color="secondary"
                      size="small"
                      className="Button--small"
                      startIcon={<FileCopyOutlinedIcon />}
                    >
                      Copy Snippet
                    </Button>
                  </Grid>

                  {renderGoogleTagManagerIcon()}
                </Grid>
              </Grid>
            </Grid>
          )}

          <Box p={3} />
        </Box>
      </Grid>

      {isCampaign && (
        <>
          <Box my={2}>
            <Grid container>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="completedAllSteps"
                      color="secondary"
                      checked={installCompleted}
                      onChange={event =>
                        handleInstallCompleted(event.target.checked)
                      }
                    />
                  }
                  label="I have completed all steps"
                />
              </Grid>

              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="willCompleteLater"
                      color="secondary"
                      checked={installLater}
                      onChange={event =>
                        handleInstallLater(event.target.checked)
                      }
                    />
                  }
                  label="I'll do this later"
                />
              </Grid>
            </Grid>
          </Box>

          <Box mt={2}>
            <Grid container alignItems="center" justify="flex-end">
              <Grid container item xs={6} justify="flex-start">
                <Button
                  color="secondary"
                  className="Button-campaign-nav"
                  onClick={() => {
                    setStep('ConfigureTrackingEvents');
                    updateBreadcrumbs('tracking', 50);
                  }}
                  startIcon={<ArrowBackIcon />}
                  variant="outlined"
                  disableElevation
                >
                  Configure Tracking Events
                </Button>
              </Grid>

              <Grid
                item
                container
                xs={6}
                justify="flex-end"
                alignItems="flex-end"
              >
                <Button
                  color="secondary"
                  className="Button--medium"
                  disabled={!(installCompleted || installLater)}
                  onClick={() => {
                    triggerSave(
                      'InstallTrackingCode',
                      false,
                      'LaunchCampaign'
                    );

                    setStep('LaunchCampaign');
                    updateBreadcrumbs('tracking', 100, 'launch', 0);
                  }}
                  size="medium"
                  variant="contained"
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </Fragment>
  );
};

InstallTrackingCode.propTypes = {
  handleInstallCompleted: PropTypes.func,
  handleInstallLater: PropTypes.func,
  installCompleted: PropTypes.bool,
  installLater: PropTypes.bool,
  isCampaign: PropTypes.bool,
  ecommerce: PropTypes.string,
  exposureLid: PropTypes.string,
  outcomeLid: PropTypes.string,
  selectedEvents: PropTypes.array,
  setStep: PropTypes.func,
  trackingMethod: PropTypes.string,
  save: PropTypes.object,
  triggerSave: PropTypes.func,
  updateBreadcrumbs: PropTypes.func,
};

export default InstallTrackingCode;
