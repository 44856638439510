// TODO:
// 1. Retrieve saved data and submit new data
// 2. Dynamic Chips (i.e. Agency, Admin, Active, etc)
// 3. "Cancel button" resets to original/cached data
// 4. Enable save button with useMemo when changes are made to data

import React, { useContext, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  Typography,
  Tabs,
  Tab,
  makeStyles,
} from '@material-ui/core';

import { useAPI } from '../hooks/api';
import AdvertiserContext from '../AdvertiserContext';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useForm } from 'react-hook-form';
import {
  getInitialValues,
  parseFormValuesToApi,
  validationSchema,
} from './formConfig';
import { yupResolver } from '@hookform/resolvers/yup';
import { RHFTextField } from '../RHFTextField';
import { OrgChips } from '../OrgChips';
import stateOptions from '../utilStates';

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
  root: {
    flexGrow: 1,
    marginTop: 60,
    marginBottom: 60,
  },
  active: {
    backgroundColor: '#c2f1e3',
    color: '#05734e',
  },
  admin: {
    backgroundColor: '#bfe7ff',
    color: '#2592cf',
  },
  chip: {
    marginLeft: spacing(2),
    padding: spacing(1),
  },
  company: {
    color: '#1dafff',
    fontSize: '0.75rem',
  },
  dropdown: {
    width: '48%',
  },
  headerWrap: {
    position: 'absolute',
    top: spacing(7),
    width: 125,
  },
  header: {
    fontSize: '0.75rem',
  },
  state: {
    width: '65%',
  },
  submit: {
    ...typography.h4,
    color: '#fff',
    marginLeft: spacing(2),
    padding: spacing(2),
    width: 175,
  },
  subsection: {
    margin: 0,
    marginTop: spacing(3),
  },
  successMsg: {
    color: palette.primary.light,
  },
  textfield: {
    marginTop: spacing(3),
  },
  tabs: {
    borderRight: `1px solid ${palette.divider}`,
    paddingTop: spacing(18),
  },
  zip: {
    maxWidth: '30%',
  },
}));

const attribution = {
  model: [
    { label: 'Last Touch', value: 'LAST_TOUCH' },
    { label: 'First Touch', value: 'FIRST_TOUCH' },
    { label: 'Linear', value: 'LINEAR' },
  ],
  window: [
    { label: '30 days', value: '30' },
    { label: '14 days', value: '14' },
    { label: '7 days', value: '7' },
    { label: '3 days', value: '3' },
    { label: '2 days', value: '2' },
    { label: '1 day', value: '1' },
  ],
};

const ManageOrganization = ({ org, setOrg }) => {
  const classes = useStyles();
  const adContext = useContext(AdvertiserContext);
  const { usePatch } = useAPI();

  const [status, setStatus] = useState('');
  const isLoading = status === 'loading';
  const isSuccess = status === 'success';

  const [tabIndex, setTabIndex] = useState(0);

  const { control, handleSubmit, reset } = useForm({
    defaultValues: getInitialValues(org),
    resolver: yupResolver(validationSchema),
  });

  const isSaveDisabled = useMemo(() => {
    return isLoading;
  }, [isLoading]);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleCancel = () => {
    reset(getInitialValues(org));
  };

  useEffect(() => {
    reset(getInitialValues(org));
  }, [org]);

  const onSubmit = handleSubmit(async values => {
    setStatus('loading');

    const { primary_org } = adContext;
    const [, orgId] = primary_org.split('/').reverse();

    try {
      const { data } = await usePatch(
        `/organizations/${orgId}/`,
        parseFormValuesToApi(values)
      );

      setOrg(data);
      setStatus('success');
    } catch (err) {
      console.error(err);
      setStatus('error');
    }
  });

  const renderGeneralInfo = () => (
    <Grid container spacing={10}>
      <Grid item xs={6}>
        <Box>
          <Typography variant="h4">Company Address</Typography>

          <RHFTextField
            className={classes.textfield}
            disabled
            label="Legal Company Name"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <LockOutlinedIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            control={control}
            name="name"
          />

          <RHFTextField
            className={classes.textfield}
            label="Street Address"
            control={control}
            name="address1"
          />

          <RHFTextField
            className={classes.textfield}
            label="Street Address 2"
            control={control}
            name="address2"
          />

          <RHFTextField
            className={classes.textfield}
            label="City"
            control={control}
            name="city"
          />

          <Box
            pt={3}
            mb={3}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <RHFTextField
              className={classes.state}
              label="State"
              control={control}
              name="state"
              select
            >
              {stateOptions.map(m => (
                <MenuItem key={m.abbreviation} value={m.abbreviation}>
                  {m.name}
                </MenuItem>
              ))}
            </RHFTextField>

            <RHFTextField
              className={classes.zip}
              label="Zip Code"
              control={control}
              name="zip"
            />
          </Box>
        </Box>
      </Grid>

      <Grid item xs={6}>
        <Box>
          <Typography variant="h4">Other Company Information</Typography>

          <RHFTextField
            className={classes.textfield}
            label="Company Phone Number"
            control={control}
            name="phone"
          />

          <RHFTextField
            className={classes.textfield}
            label="DBA / Trade Name (optional)"
            control={control}
            name="dba"
          />

          <Box mt={3} display="flex" justifyContent="space-between">
            <RHFTextField
              select
              className={classes.dropdown}
              label="Attribution Model"
              control={control}
              name="attrModel"
            >
              {attribution.model.map(m => (
                <MenuItem key={m.value} value={m.value}>
                  {m.label}
                </MenuItem>
              ))}
            </RHFTextField>

            <RHFTextField
              select
              className={classes.dropdown}
              label="Attribution Window"
              control={control}
              name="attrWindow"
            >
              {attribution.window.map(w => (
                <MenuItem key={w.value} value={w.value}>
                  {w.label}
                </MenuItem>
              ))}
            </RHFTextField>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );

  // TODO: storing for finance implentation
  // const renderFinance = () => (
  //   <Grid container spacing={10}>
  //     <Grid item xs={12}>
  //       <Alert severity="info" style={{ marginBottom: 12 }}>
  //         Need more credit?
  //       </Alert>

  //       <Alert severity="info">Want to pay by invoice?</Alert>
  //     </Grid>

  //     <Grid item xs={6}>
  //       <Box>
  //         <TextField
  //           color="secondary"
  //           disabled
  //           fullWidth
  //           label="Legal Company Name"
  //           margin="none"
  //           onChange={event => setCredit(event.target.value)}
  //           value={credit}
  //           InputLabelProps={{ shrink: true }}
  //           InputProps={{
  //             endAdornment: (
  //               <InputAdornment position="end">
  //                 <LockOutlinedIcon fontSize="small" />
  //               </InputAdornment>
  //             ),
  //           }}
  //           variant="outlined"
  //         />

  //         <TextField
  //           className={classes.textfield}
  //           color="secondary"
  //           disabled
  //           fullWidth
  //           label="Legal Company Name"
  //           margin="none"
  //           onChange={event => setLiability(event.target.value)}
  //           value={liability}
  //           InputLabelProps={{ shrink: true }}
  //           InputProps={{
  //             endAdornment: (
  //               <InputAdornment position="end">
  //                 <LockOutlinedIcon fontSize="small" />
  //               </InputAdornment>
  //             ),
  //           }}
  //           variant="outlined"
  //         />
  //       </Box>
  //     </Grid>

  //     <Grid item xs={6}>
  //       <Box>
  //         <Typography variant="h4">Billing Terms</Typography>
  //         <Typography>Net 0</Typography>
  //       </Box>
  //     </Grid>
  //   </Grid>
  // );

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <Box>
        <Box
          mb={7}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              aria-label="Organization Tabs"
            >
              <Tab
                label={<Typography variant="h4">General Info</Typography>}
              />
              {/* TODO: storing for finance implentation */}
              {/* <Tab label="Finance" /> */}
            </Tabs>
          </Box>

          <OrgChips org={org} />
        </Box>

        {tabIndex === 0 && renderGeneralInfo()}
        {/* TODO: storing for finance implentation
        {tabIndex === 1 && renderFinance()} */}
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        style={{ textAlign: 'right' }}
        width="100%"
      >
        {isSuccess && (
          <Typography component={'span'} className={classes.successMsg}>
            Your Settings have been saved!
          </Typography>
        )}

        <Button
          color="secondary"
          disabled={isLoading}
          disableElevation
          disableRipple
          onClick={handleCancel}
          type="reset"
        >
          Cancel
        </Button>

        <Button
          className={clsx(classes.submit)}
          color="secondary"
          disabled={isSaveDisabled}
          disableElevation={isSuccess}
          disableRipple
          variant="contained"
          type="submit"
          onClick={onSubmit}
        >
          {isLoading ? 'Saving...' : 'Save Settings'}
        </Button>
      </Box>
    </Box>
  );
};

ManageOrganization.propTypes = {
  org: PropTypes.object,
  setOrg: PropTypes.func,
};

export default ManageOrganization;
