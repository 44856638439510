import { useEffect, useState } from 'react';
import { Geo } from '../../constants';

export const useSelectEntireUS = ({ geo, setGeo }) => {
  const [selectEntireUS, setSelectEntireUS] = useState(true);

  useEffect(() => {
    if (geo.length > 0) {
      const hasEntireUS = geo.some(g => {
        const name = g.place_name || g.name;

        return name === Geo.entireUS.place_name;
      });

      setSelectEntireUS(hasEntireUS);
    }
  }, []);

  useEffect(() => {
    setGeo(prev => {
      const nextGeoResults = [...prev.filter(r => r.place_name !== Geo.entireUS.place_name)];

      if (selectEntireUS) {
        nextGeoResults.unshift(Geo.entireUS);
      }

      return nextGeoResults;
    });
  }, [selectEntireUS]);

  return {
    selectEntireUS,
    setSelectEntireUS,
  };
};
