import React, { useState, memo, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  Grid,
  IconButton,
  makeStyles,
  TextField,
} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import ErrorMessage from './ErrorMessage';

const MESSAGE = 'The Creative Title is longer than 36 characters and may appear cut-off';

const useStyles = makeStyles(() => ({
  action: {
    margin: '5px 0',
  },
  submit: {
    '&:disabled': {
      color: 'gray',
    },
  }
}));

const VastTagListExtension = ({
  name = 'tag',
  onSubmit,
  onRemove,
}) => {
  const classes = useStyles();

  const [warning, setWarning] = useState({
    vastTags: {},
  });

  const { control, formState, getFieldState, handleSubmit } = useFormContext();
  const { invalid, isDirty } = getFieldState(name, formState);

  const createHandleTagsTitleBlur = useCallback((onBlur) => (event) => {
    const { value } = event.target;
    const title = value.length > 36 ? MESSAGE : null;

    onBlur(event);
    setWarning({ title });
  }, []);

  return (
    <Grid
      container
      alignItems="flex-start"
      wrap="nowrap"
      spacing={2}
    >
      <Grid item xs={4}>
        <Controller
          control={control}
          name={`${name}.name`}
          defaultValue=""
          rules={{
            required: {
              value: true,
              message: 'The field must not be empty',
            },
          }}
          render={({ field: { onBlur, ...field }, fieldState: { error} }) => {
            const warningMessage = warning.title || null;
            const errorMessage = error ? error.message : null;
            const alertMessage = errorMessage || warningMessage;

            return (
              <>
                <TextField
                  {...field}
                  onBlur={createHandleTagsTitleBlur(onBlur)}
                  error={alertMessage !== null}
                  color="secondary"
                  label="Creative Title"
                  placeholder="Enter Creative Title"
                  variant="outlined"
                  fullWidth
                />
                {alertMessage && <ErrorMessage>{alertMessage}</ErrorMessage>}
              </>
            );
          }}
        />
      </Grid>
      <Grid item xs={7}>
        <Controller
          control={control}
          name={`${name}.vast_tag_url`}
          defaultValue=""
          rules={{
            required: {
              value: true,
              message: 'The field must not be empty',
            },
            pattern: {
              value: /https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/i,
              message: 'The VAST Tag URL is not configured correctly'
            }
          }}
          render={({ field, fieldState: { error } }) => (
            <>
              <TextField
                {...field}
                error={error !== undefined}
                color="secondary"
                label="VAST Tag URL"
                placeholder="Enter VAST Tag URL"
                variant="outlined"
                multiline
                fullWidth
              />
              {error && <ErrorMessage>{error.message}</ErrorMessage>}
            </>
          )}
        />
      </Grid>
      <Grid item xs container wrap="nowrap">
        <Grid item>
          <IconButton
            className={classes.action}
            disabled={invalid || !isDirty}
            onClick={handleSubmit(onSubmit)}
          >
            <CheckCircleOutlineIcon
              color={invalid || !isDirty ? 'disabled' : 'primary'}
            />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton
            className={classes.action}
            onClick={onRemove}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

VastTagListExtension.propTypes = {
  name: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default memo(VastTagListExtension);
