import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, makeStyles } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles(({ palette, spacing }) => ({
  info: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginLeft: spacing(1),
  },
  tooltip: {
    backgroundColor: `#EDF1F2`,
    color: palette.text.primary,
    padding: spacing(1.5),
    maxWidth: 184,
    fontFamily: 'Noto Sans JP',
    lineHeight: '1.25rem',
    fontSize: '0.625rem',
    fontWeight: 500,
  },
  tooltipArrow: {
    fontSize: '1rem',
    '&:before': {
      backgroundColor: `#EDF1F2`,
    },
  },
}));

export default function InfoTooltip(props) {
  const classes = useStyles();
  return (
    <Tooltip
      classes={{ arrow: classes.tooltipArrow, tooltip: classes.tooltip }}
      title={props.title}
      placement="top"
      sx={{ fontWeight: 'regular' }}
      arrow
    >
      <InfoOutlinedIcon
        className={classes.info}
        fontSize="small"
        color="secondary"
      />
    </Tooltip>
  );
}

InfoTooltip.propTypes = {
  title: PropTypes.string,
};
