import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import AsyncButton from './AsyncButton';
import CreditCardForm from './CreditCardForm';
import InfoTooltip from './InfoTooltip';
import { useLoader } from './hooks/loader';
import { states } from '../constants/usaStates';
import amexIcon from '../images/american-express-dark.svg';
import discoverIcon from '../images/discover-dark.svg';
import mastercardIcon from '../images/master-card-dark.svg';
import visaIcon from '../images/visa-dark.svg';

const useStyles = makeStyles(({ palette, spacing }) => ({
  card: {
    fontSize: '0.75rem',
  },
  container: {
    minHeight: 710,
  },
  state: {
    width: 270,
  },
  subtitle: {
    fontSize: '0.75rem',
    color: '#47505d',
    marginTop: spacing(1),
  },
  successMsg: {
    color: palette.primary.light,
  },
  textfield: {
    marginTop: spacing(3),
  },
  textfieldSameRow: {
    marginTop: spacing(3),
    width: '48%',
  },
  tooltip: {
    backgroundColor: `#EDF1F2`,
    color: palette.text.primary,
    padding: spacing(1.5),
    maxWidth: 184,
    fontFamily: 'Noto Sans JP',
    lineHeight: '1.25rem',
    fontSize: '0.625rem',
    fontWeight: 500,
  },
  tooltipArrow: {
    fontSize: '1rem',
    '&:before': {
      backgroundColor: `#EDF1F2`,
    },
  },
  zip: {
    maxWidth: 150,
  },
}));

const paymentIcons = {
  amex: amexIcon,
  discover: discoverIcon,
  visa: visaIcon,
  mastercard: mastercardIcon,
};

const formatLastFour = digits => digits.split('XXXX')[1];

const tooltips = {
  lockedCompanyName: `This field is locked because Billing Company Name must match the Legal Company Name provided with your general information. You can change this value in Settings.`,
  billingAccountContact: `The Billing Account Contact will receive invoices, and will be the primary contact for any billing related issues.`,
}

const OrganizationBilling = props => {
  const classes = useStyles();

  const { isLoading, setIsLoading } = useLoader();
  const isNbcuSubdomain = window.location.href.includes('peacock');

  const {
    advertiserId,
    cards,
    name,
    onBack,
    org,
    isSameAddress,
    setIsSameAddress,
    selectedCard,
    setSelectedCard,
    isSkip,
    setIsSkip,
    getProfilesRefresh,
  } = props;

  const {
    billingName,
    setBillingName,
    billingAddress1,
    setBillingAddress1,
    billingAddress2,
    setBillingAddress2,
    billingCity,
    setBillingCity,
    billingState,
    setBillingState,
    billingZip,
    setBillingZip,
    billingAccountName,
    billingFirstName,
    setBillingFirstName,
    billingLastName,
    setBillingLastName,
    billingAccountEmail,
    setBillingAccountEmail,
  } = org;

  const [isEnterCard, setIsEnterCard] = useState(false);

  const isNextDisabled = useMemo(() => {
    if (isSkip) {
      return false;
    }

    const requiredFields = [
      billingAddress1,
      billingCity,
      billingState,
      billingZip,
      billingFirstName,
      billingLastName,
      billingAccountEmail,
    ];

    if (isNbcuSubdomain) {
      return isLoading || requiredFields.some(i => i === '');
    }

    return (
      isLoading ||
      (!selectedCard) ||
      requiredFields.some(i => i === '')
    );
  }, [
    isSkip,
    isLoading,
    selectedCard,
    billingAddress1,
    billingCity,
    billingState,
    billingZip,
    billingAccountName,
    billingFirstName,
    billingLastName,
    billingAccountEmail,
  ]);

  const handleOrgBilling = async () => {
    setIsLoading(true);

    try {
      const res = await props.handleBillingAccount();
      props.onNext();
      setIsLoading(false);
      return res;
    } catch (err) {
      console.log('err', err);
      setIsLoading(false);
    }
  };

  const handleCloseCreditCard = () => {
    setIsEnterCard(false);
    getProfilesRefresh();
  };

  const renderCard = method => {
    const cardType =
      method.card_type === 'AmericanExpress' ? 'AMEX' : method.card_type;

    return (
      <ListItem
        button
        key={method.id}
        className={classes.paymentListItem}
        selected={selectedCard === method.url}
        onClick={() => setSelectedCard(method.url)}
      >
        <ListItemIcon>
          <img src={paymentIcons[cardType.toLowerCase()]} />
        </ListItemIcon>

        <ListItemText
          primary={
            <Typography className={classes.methods} variant="body2">
              {`${cardType} ending in `}
              {`${formatLastFour(method.card_number)}`}
              &nbsp;{` | `}&nbsp; {`Expiration ${method.expiration_date}`}
            </Typography>
          }
        />

        <IconButton size="small" aria-label="delete">
          <CloseIcon fontSize="small" />
        </IconButton>
      </ListItem>
    );
  };

  return (
    <Container className={classes.container}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="start"
        justifyContent="space-between"
        height="100%"
        minHeight={710}
        p={6}
      >
        <Box alignSelf="start">
          <Typography variant="h3">
            Enter {name ? `${name}'s` : ''} Billing Information
          </Typography>

          <Box mt={2}>
            <Typography className={classes.subtitle}>
              The information can be modified at any time in the Account
              Settings.
            </Typography>
          </Box>
        </Box>

        <Box width="100%" mt={2} mb={4}>
          <Divider />
        </Box>

        <Box width="90%">
          <Grid container spacing={10}>
            <Grid item xs={6}>
              <Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h5">Billing Address</Typography>

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isSameAddress}
                        onChange={evt =>
                          setIsSameAddress(evt.target.checked)
                        }
                        color="secondary"
                      />
                    }
                    label="Same as Company"
                  />
                </Box>

                <TextField
                  className={classes.textfield}
                  color="secondary"
                  disabled
                  fullWidth
                  label="Billing Company Name"
                  margin="none"
                  value={billingName}
                  onChange={event => setBillingName(event.target.value)}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                      <Tooltip
                        classes={{ arrow: classes.tooltipArrow, tooltip: classes.tooltip }}
                        title={tooltips.lockedCompanyName}
                        placement="top"
                        sx={{ fontWeight: 'regular' }}
                        arrow
                      >
                        <LockOutlinedIcon fontSize="small" />
                      </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                />

                <TextField
                  className={classes.textfield}
                  color="secondary"
                  fullWidth
                  label="Street Address"
                  margin="none"
                  onChange={event => setBillingAddress1(event.target.value)}
                  value={billingAddress1}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                />

                <TextField
                  className={classes.textfield}
                  color="secondary"
                  fullWidth
                  label="Street Address 2"
                  margin="none"
                  onChange={event => setBillingAddress2(event.target.value)}
                  value={billingAddress2}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                />

                <TextField
                  className={classes.textfield}
                  color="secondary"
                  fullWidth
                  label="City"
                  margin="none"
                  onChange={event => setBillingCity(event.target.value)}
                  value={billingCity}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                />

                <Box
                  pt={3}
                  mb={3}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <TextField
                    select
                    className={classes.state}
                    color="secondary"
                    fullWidth
                    label="State"
                    margin="none"
                    onChange={event => setBillingState(event.target.value)}
                    value={billingState}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                  >
                    {states.map(s => (
                      <MenuItem key={s.abbreviation} value={s.abbreviation}>
                        {s.name}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    className={classes.zip}
                    color="secondary"
                    fullWidth
                    label="Zip Code"
                    margin="none"
                    onChange={event => setBillingZip(event.target.value)}
                    value={billingZip}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                  />
                </Box>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box mt={2}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h5">
                    Billing Account Contact
                    <InfoTooltip title={tooltips.billingAccountContact}/>
                  </Typography>
                </Box>

                <Box width="100%" display="flex" justifyContent="space-between">
                  <TextField
                    className={classes.textfieldSameRow}
                    color="secondary"
                    fullWidth
                    label="Billing Contact First Name"
                    margin="none"
                    onChange={event =>
                      setBillingFirstName(event.target.value)
                    }
                    value={billingFirstName}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                  />

                  <TextField
                    className={classes.textfieldSameRow}
                    color="secondary"
                    fullWidth
                    label="Billing Contact Last Name"
                    margin="none"
                    onChange={event =>
                      setBillingLastName(event.target.value)
                    }
                    value={billingLastName}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                  />
                </Box>

                <TextField
                  className={classes.textfield}
                  color="secondary"
                  fullWidth
                  label="Billing Contact Email"
                  margin="none"
                  onChange={event =>
                    setBillingAccountEmail(event.target.value)
                  }
                  value={billingAccountEmail}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                />

                {!isNbcuSubdomain && (
                  <>
                    <Box
                      mt={4}
                      mb={2}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="h5">Payment Method</Typography>
                    </Box>

                    {cards && cards.length > 0 ? (
                      <List className={classes.cards} dense>
                        {cards.map(c => renderCard(c))}
                      </List>
                    ) : (
                      <Button
                        color="secondary"
                        startIcon={<AddIcon fontSize="small" />}
                        onClick={() => setIsEnterCard(true)}
                      >
                        <Typography className={classes.card}>
                          Add Credit Card
                        </Typography>
                      </Button>
                    )}

                    {/* TODO: Enable in fast follow with invoice application */}
                    {/* <Box mt={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isInvoice}
                            onChange={evt => setIsInvoice(evt.target.checked)}
                            color="secondary"
                          />
                        }
                        label="We would like to be billed by invoice  |  Apply now"
                      />
                    </Box> */}
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          mt={9}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Button
            onClick={onBack}
            color="secondary"
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box width="80%">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isSkip}
                    onChange={evt => setIsSkip(evt.target.checked)}
                    color="secondary"
                  />
                }
                label="I'll do this later"
              />
            </Box>

            <AsyncButton
              color="secondary"
              isDisabled={isNextDisabled}
              isLoading={isLoading}
              onClick={handleOrgBilling}
              size="medium"
              textButton="Next"
              variant="contained"
            />
          </Box>
        </Box>
      </Box>

      {isEnterCard && (
        <CreditCardForm
          advertiserId={advertiserId}
          onClose={handleCloseCreditCard}
          isEnterCard={isEnterCard}
        />
      )}
    </Container>
  );
};

OrganizationBilling.propTypes = {
  advertiserId: PropTypes.number,
  isSameAddress: PropTypes.bool,
  setIsSameAddress: PropTypes.func,
  isInvoice: PropTypes.bool,
  setIsInvoice: PropTypes.func,
  isSkip: PropTypes.bool,
  setIsSkip: PropTypes.func,
  name: PropTypes.string,
  org: PropTypes.object,
  cards: PropTypes.array,
  selectedCard: PropTypes.string,
  setSelectedCard: PropTypes.func,
  getProfilesRefresh: PropTypes.func,
  handleSaveOrg: PropTypes.func,
  handleBillingAccount: PropTypes.func,
  onBack: PropTypes.func,
  onNext: PropTypes.func,
  setCompanyName: PropTypes.func,
};

export default OrganizationBilling;
