import React from 'react';
import { CircularProgress, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';

export const RHFTextField = ({ name, control, loading, ...rest }) => {
  const { field, fieldState } = useController({
    name,
    control,
  });

  return (
    <TextField
      color="secondary"
      fullWidth
      margin="none"
      InputLabelProps={{ shrink: true }}
      variant="outlined"
      {...rest}
      {...field}
      error={!!fieldState.error}
      helperText={fieldState.error ? fieldState.error.message : null}
      InputProps={{
        ...rest.InputProps,
        endAdornment: loading ? (
          <CircularProgress size="24px" />
        ) : (
          rest.InputProps?.endAdornment || null
        ),
      }}
    />
  );
};

RHFTextField.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  loading: PropTypes.bool,
};
