import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext, useWatch, useFieldArray } from 'react-hook-form';
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ErrorMessage from './ErrorMessage';

const MESSAGE = 'The Creative Title is longer than 36 characters and may appear cut-off';

const initialTag = { name: '', vast_tag_url: '' };

const useStyles = makeStyles(() => ({
  tooltip: {
    marginRight: '4px',
  },
  tooltipText: {
    fontSize: '0.8125rem',
  },
  error: {
    color: "#f44336",
  },
  info: {
    color: '#069de0',
  },
  deleteButton: {
    margin: "12px 0",
  },
}));

const VastTagAdding = ({
  name = 'vastTags',
  onSubmit
}) => {
  const classes = useStyles();
  const { control, formState, setValue, getFieldState, handleSubmit } = useFormContext();
  const { invalid, isDirty } = getFieldState(name, formState);

  const [warnings, setWarnings] = useState({
    [name]: {},
  });

  const {
    fields,
    append: appendTagRow,
    remove: removeTagRow
  } = useFieldArray({
    keyName: 'internalId',
    control,
    name
  });

  const firstTag = useWatch({
    control,
    name: `${name}.0`,
  });

  const createHandleTagsTitleBlur = useCallback((onBlur, index) => (event) => {
    const { value } = event.target;

    onBlur(event);

    setWarnings((prev) => {
      const next = {...prev};

      if (!next[name][index]) {
        next[name][index] = {};
      }

      next[name][index].name = value.length > 36 ? MESSAGE : null;

      return next;
    });
  }, []);

  const handleRemoveTag = useCallback((index) => {
    if (index === 0) {
      setValue(`${name}.${index}`, initialTag);

      return;
    }

    removeTagRow(index);
  }, []);

  const handleAddNewTag = useCallback(() => {
    appendTagRow({ name: '', vast_tag_url: '' });
  }, []);

  return (
    <>
      <Alert severity="info">tvScientific recommends using one creative per VAST tag. Linking multiple creatives will make it hard to understand which creative drives what performance.</Alert>
      <Box
        border={1}
        borderColor="grey.300"
        p={4}
        mt={2}
        borderRadius={20}
        maxHeight="380px"
        overflow="auto"
      >
        {fields.map((field, index) => {
          const { [index]: tag } = warnings[name];

          return (
            <Grid
              key={field.internalId}
              container
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item xs={4}>
                <Controller
                  control={control}
                  name={`${name}.${index}.name`}
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: 'The field must not be empty',
                    },
                  }}
                  render={({ field: { onBlur, ...field }, fieldState: { error} }) => {
                    const warningMessage = tag ? tag.name : null;
                    const errorMessage = error ? error.message : null;
                    const alertMessage = errorMessage || warningMessage;

                    return (
                      <>
                        <TextField
                          {...field}
                          onBlur={createHandleTagsTitleBlur(onBlur, index)}
                          error={alertMessage !== null}
                          color="secondary"
                          label="Creative Title"
                          placeholder="Enter Creative Title"
                          variant="outlined"
                          fullWidth
                        />
                        {alertMessage && <ErrorMessage>{alertMessage}</ErrorMessage>}
                      </>
                    );
                  }}
                />
              </Grid>
              <Grid item xs={7}>
                <Controller
                  control={control}
                  name={`${name}.${index}.vast_tag_url`}
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: 'The field must not be empty',
                    },
                    pattern: {
                      value: /https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/i,
                      message: 'The VAST Tag URL is not configured correctly'
                    }
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <TextField
                        {...field}
                        error={error !== undefined}
                        color="secondary"
                        label="VAST Tag URL"
                        placeholder="Enter VAST Tag URL"
                        variant="outlined"
                        multiline
                        fullWidth
                      />
                      {error && <ErrorMessage>{error.message}</ErrorMessage>}
                    </>
                  )}
                />
              </Grid>
              {(index > 0 || firstTag.name || firstTag.vast_tag_url) && (
                <Grid item>
                  <IconButton
                    className={classes.deleteButton}
                    onClick={() => handleRemoveTag(index)}
                    size="small"
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          );
        })}

        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <Button
              color="secondary"
              onClick={handleAddNewTag}
              size="small"
              startIcon={<AddIcon />}
            >
              Add another VAST Tag
            </Button>
          </Grid>

          <Grid container item alignItems="center" xs>
            <Tooltip
              className={classes.tooltip}
              title="VAST Tag 2.0 or later supported"
            >
              <InfoOutlinedIcon
                fontSize="inherit"
                className={classes.info}
              />
            </Tooltip>

            <p className={classes.tooltipText}>VAST Tag 2.0 or later supported</p>
          </Grid>
        </Grid>

        <Grid
          container
          alignItems="center"
          justify="flex-end"
        >
          <Grid item xs={6}>
            <Box py={2}>
              <Button
                size="large"
                color="secondary"
                variant="contained"
                disabled={invalid || !isDirty}
                onClick={handleSubmit(onSubmit)}
                fullWidth
              >
                Next: Verify Meta Data
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

VastTagAdding.propTypes = {
  name: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

export default memo(VastTagAdding);
