import { useEffect, useContext, useState } from 'react';

import AdvertiserContext from './AdvertiserContext';
import { useAPI } from './hooks/api';

const Org = ({ children }) => {
  const adContext = useContext(AdvertiserContext)
  const { useGet } = useAPI();

  const [org, setOrg] = useState({});

  useEffect(() => {
    if (adContext && adContext.id && adContext.primary_org) {
      updateOrg();
    }
  }, [adContext]);

  const updateOrg = async () => {
    try {
      const res = await useGet(adContext.primary_org, true);
      console.log('res from OrgContext', res);
      setOrg(res);
    } catch (err) {
      console.error('error updating org', err);
    }
  };

  return children(org);
};

export default Org;
