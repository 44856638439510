import { useContext, useMemo } from 'react';
import AdvertiserContext from '../AdvertiserContext';
import { Themes } from '../../constants';

export const useCopy = copies => {
  const adContext = useContext(AdvertiserContext);

  return useMemo(
    () => copies[adContext.theme] || copies[Themes.DEFAULT],
    [adContext.theme]
  );
};
