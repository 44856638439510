import React, { useContext, useEffect, useState } from 'react'
import { Box, CircularProgress, makeStyles } from '@material-ui/core'
import { Route, Switch, useRouteMatch, } from 'react-router-dom';

import BillingDetailsTable from './BillingDetailsPage';
import BillingForm from './BillingAccounts/AddBillingAccount';
import AdvertiserContext from './AdvertiserContext';
import { useLoader } from './hooks/loader';
import { useAPI } from './hooks/api';

const useStyles = makeStyles(() => ({
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: 375,
    alignItems: 'center',
  },
  tableContainer: {
    width: '100%',
    overflowX: 'auto',
  },
}));

const BillingAccounts = () => {
  const { isLoading, setIsLoading } = useLoader()
  const { path } = useRouteMatch();
  const classes = useStyles();
  const adContext = useContext(AdvertiserContext);
  const { useGet, } = useAPI();

  const [billingAccounts, setBillingAccounts] = useState([])

  useEffect(() => {
    setIsLoading(true)
    const getBillingAccounts = async () => {
      try {
        const response = await useGet('/billing_accounts')
        setBillingAccounts(response.results)
        setIsLoading(false)
      } catch (e) {
        console.log(e)
        setIsLoading(false)
      }
    }

    if (adContext && adContext.id) {
      getBillingAccounts();
    }
  }, [adContext]);

  if (isLoading) {
    return <Box className={classes.spinner}>
      <CircularProgress color="secondary" />
    </Box>
  }

  return (
    <Switch>
      <Route exact path="/manager">
        <BillingDetailsTable billingAccounts={billingAccounts} />
      </Route>
      <Route exact path={`${path}/addBillingAccount`}>
        <BillingForm setBillingAccounts={setBillingAccounts} />
      </Route>
      <Route path={`${path}/billing-account/:id`}>
        <BillingForm setBillingAccounts={setBillingAccounts} />
      </Route>
    </Switch>
  )
}

export default BillingAccounts;
