import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Collapse,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Switch,
  Typography,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { RHFTextField } from '../../RHFTextField';
import { categories as sensitiveCategories } from '../../../constants/sensitiveCategories';
import {
  formValuesToApi,
  getInitialValues,
  validationSchema,
} from './formConfig';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LookerTypeSelect } from '../shared/LookerTypeSelect';
import { AttributionRoleFieldset } from '../shared/AttributionRoleFieldset';
import ModalWrapper from '../../ModalWrapper';
import { RHFCheckbox } from '../../RHFCheckbox';
import { useAPI } from '../../hooks/api';
import AdvertiserContext from '../../AdvertiserContext';
import UserContext from '../../../providers/UserContext';

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: '480px',
    width: '100%',
    margin: '0 auto',
  },
}));

const managementModelOptions = [
  { label: 'Self-serve', value: 'SELFSERVE' },
  { label: 'Internal', value: 'INTERNAL' },
  { label: 'Hybrid', value: 'HYBRID' },
  { label: 'Tenant', value: 'TENANT' },
];

export const CreateAccountModal = ({ onClose, categories, onSuccess }) => {
  const adContext = useContext(AdvertiserContext);
  const user = useContext(UserContext);
  const { usePost } = useAPI();
  const classes = useStyles();
  const [status, setStatus] = useState('');
  const isLoading = status === 'loading';

  const { control, handleSubmit, register, watch } = useForm({
    defaultValues: getInitialValues(),
    resolver: yupResolver(validationSchema),
  });

  const isUncapped = watch('isUncapped');
  const sensitiveCategory = watch('sensitive');

  const onSubmit = handleSubmit(async values => {
    setStatus('loading');

    try {
      const payload = formValuesToApi(values);
      payload.owner = adContext.owner;
      payload.primary_org = adContext.primary_org;

      const { data } = await usePost(`/advertisers/`, payload);
      onSuccess(data);
    } catch (error) {
      setStatus('error');
    }
  });

  return (
    <form noValidate onSubmit={onSubmit}>
      <ModalWrapper
        isOpen
        hasSubmit
        submit="Save Ad Account"
        isLoading={isLoading}
        maxWidth="md"
        onClose={onClose}
      >
        <DialogTitle id="delete-draft-dialog">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            pt={1}
          >
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Box
          mx={'auto'}
          display="flex"
          flexDirection="column"
          gridGap={24}
          className={classes.container}
        >
          <Box mb={4} mt={-5}>
            <Typography variant="h3">Add Ad Account</Typography>
          </Box>

          <RHFTextField
            label="Ad Account Name"
            placeholder="Enter Ad Account Name"
            name="name"
            control={control}
          />

          <RHFTextField
            label="Advertiser Name"
            placeholder="Enter Advertiser Name"
            name="advertiserName"
            control={control}
          />

          <RHFTextField
            label="Advertiser Website Address"
            placeholder="Enter Advertiser Website Address"
            name="website"
            control={control}
          />

          <RHFTextField
            select
            label="Advertiser Category"
            placeholder="Select Advertiser Category"
            name="category"
            control={control}
            loading={!categories.length}
          >
            {categories.map(c => (
              <MenuItem key={c.id} value={c.url}>
                {c.name}
              </MenuItem>
            ))}
          </RHFTextField>

          <RHFTextField
            select
            label="Sensitive Category"
            name="sensitive"
            clearable
            control={control}
          >
            {sensitiveCategories.map(s => (
              <MenuItem key={s.value} value={s.value}>
                {s.label}
              </MenuItem>
            ))}
          </RHFTextField>

          {sensitiveCategory === 'NONE' && (
            <RHFCheckbox
              control={control}
              name="understand"
              label={
                <Typography variant="body2">
                  I understand that failure to disclose sensitive category may
                  result in removal from the tvScientific platform
                </Typography>
              }
            />
          )}

          <Box>
            <Box mb={isUncapped ? 0 : 3}>
              <FormControlLabel
                control={<Switch {...register('isUncapped')} checked={isUncapped} size="small" />}
                label={
                  <Typography variant="body2">
                    Uncapped Ad Account Budget
                  </Typography>
                }
              />
            </Box>
            <Collapse in={!isUncapped}>
              <RHFTextField
                fullWidth
                label="Ad Account Budget"
                name="budget"
                control={control}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Collapse>
          </Box>

          <RHFTextField
            label="Purchase Order Reference"
            placeholder="Enter Purchase Order Reference (optional)"
            name="purchaseOrder"
            control={control}
          />

          { user.is_tvsci_employee ?
            <>
              <Typography variant="h4">Management Model</Typography>

              <RHFTextField
                select
                placeholder="Select management model"
                name="managementModel"
                control={control}
              >
                {managementModelOptions.map(c => (
                  <MenuItem key={c.value} value={c.value}>
                    {c.label}
                  </MenuItem>
                ))}
              </RHFTextField>
            </> : null
          }

          <Typography variant="h5">
            Choose a dashboard that fits this advertiser
          </Typography>
          <LookerTypeSelect control={control} name="looker" />

          <Typography variant="h5">Attribution Defaults</Typography>
          <AttributionRoleFieldset
            control={control}
            primaryOutcomeName="outcome"
            attModelName="attrModel"
            attWindowName="attrWindow"
          />

        </Box>
      </ModalWrapper>
    </form>
  );
};

CreateAccountModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  categories: PropTypes.array,
};
