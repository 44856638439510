import React from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';

export const RHFCheckbox = ({ name, control, label }) => {
  const { field, fieldState } = useController({
    name,
    control,
  });

  return (
    <FormControl required error={!!fieldState.error} variant="standard">
      <FormControlLabel
        control={<Checkbox {...field} name={name} />}
        label={label}
      />
      {fieldState.error && (
        <FormHelperText>{fieldState.error?.message}</FormHelperText>
      )}
    </FormControl>
  );
};

RHFCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  label: PropTypes.any,
};
