import { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';

export const useInternalUser = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [isInternalUser, setIsInternalUser] = useState(false);

  const handleInternalUser = async (authState, oktaAuth) => {
    if (!authState.isAuthenticated) {
      return;
    }

    const user = await oktaAuth.getUser();

    // NOTE: Currently, not all @tvscientific.com users have the is_tvsci_employee attribute set
    // TODO: Stop checking email domains
    setIsInternalUser(
      !!user &&
        (user.email.includes('tvscientific.com') || !!user.is_tvsci_employee)
    );
  };

  useEffect(() => {
    handleInternalUser(authState, oktaAuth);
  }, [authState, oktaAuth]);

  return isInternalUser;
};
