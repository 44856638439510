import { useContext, useEffect, useMemo } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import axios from 'axios';

import AdvertiserContext from '../AdvertiserContext';
import { useErrors } from './errors';
import { useLoader } from './loader';
import LogRocket from 'logrocket';

// API CONSTANTS
const BASE_URL = process.env.API_URL;
const BASE_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};
process.env.USE_LOG_ROCKET === 'true'
  ? LogRocket.getSessionURL(a => (BASE_HEADERS['X-LogRocket-URL'] = a))
  : null;
const VERSION = 'v1';

// API HELPERS
const formatUrl = url => {
  if (url) {
    // Adds trailing slash if doesnt exist
    url = url.slice(-1) !== '/' ? `${url}/` : url;

    // Removes initial slash if exist
    url = url.charAt(0) === '/' ? url.slice(1) : url;
  }

  return `${BASE_URL}/${VERSION}/${url}`;
};

const orgUrls = [
  'organizations',
  'advertiser_users',
  'users',
  'for_org',
  'for_user',
]

/////////////////
// useAPI HOOK
/////////////////
export const useAPI = () => {
  const adContext = useContext(AdvertiserContext);
  const { authState, oktaAuth } = useOktaAuth();
  const { isLoading, setIsLoading } = useLoader();
  const { handleError } = useErrors();

  const apiInstance = useMemo(() => axios.create({
    headers: {
      ...BASE_HEADERS,
    },
  }), []);

  const apiConfig = {
    headers: {
      ...BASE_HEADERS,
    },
  };

  refreshToken();

  if (adContext && adContext.id) {
    apiConfig.headers['X-TVS-AdvertiserContext'] = adContext.id;
  }

  // if (adContext && adContext.primary_org) {
  //   const orgArr = adContext.primary_org.split('/');
  //   const orgId = orgArr[orgArr.length - 2];
    // apiConfig.headers['X-TVS-OrganizationContext'] = orgId;
  // }

  useEffect(() => {
    return () => setIsLoading(false);
  }, []);

  useEffect(() => {
    apiInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response && error.response.status === 403) {
          const newConfig = {
            ...error.config,
            headers: {
              ...error.config.headers,
              'X-TVS-OrganizationContext': undefined,
            },
          };

          return axios(newConfig);
        }

        return Promise.reject(error);
      }
    );
  }, [apiInstance]);

  function refreshToken() {
    if (authState.isAuthenticated) {
      apiConfig.headers[
        'Authorization'
      ] = `Bearer ${oktaAuth.getAccessToken()}`;
    }
  }

  // Rest API Service
  const useGet = (url, isAbsolute) => {
    setIsLoading(true);

    const isOrgUrl = orgUrls.some(o => url.includes(o));

    if (isOrgUrl) {
      if (adContext && adContext.primary_org) {
        const orgArr = adContext.primary_org.split('/');
        const orgId = orgArr[orgArr.length - 2];
        apiConfig.headers['X-TVS-OrganizationContext'] = orgId;
      }
    }

    const request = isAbsolute
      ? apiInstance.get(url, apiConfig)
      : apiInstance.get(formatUrl(url), apiConfig);

    return request
      .catch(error => {
        setIsLoading(false);
        if (error.response) {
          console.error('Error in useGet', error);
          handleError(error, 'error', refreshToken);
          return error;
        }
      })
      .then(response => {
        setIsLoading(false);
        if (
          response &&
          response.status &&
          [200, 201].indexOf(response.status) > -1 &&
          response.data
        ) {
          return response.data;
        }

        return response;
      });
  };

  const usePost = (url, data = {}) => {
    setIsLoading(true);

    const isOrgUrl = orgUrls.some(o => url.includes(o));

    if (isOrgUrl) {
      if (adContext && adContext.primary_org) {
        const orgArr = adContext.primary_org.split('/');
        const orgId = orgArr[orgArr.length - 2];
        apiConfig.headers['X-TVS-OrganizationContext'] = orgId;
      }
    }

    return apiInstance
      .post(formatUrl(url), data, apiConfig)
      .then(response => {
        setIsLoading(false);
        console.log('Response from usePost', response);
        return response;
      })
      .catch(error => {
        setIsLoading(false);
        console.error('Error in usePost', error);
        handleError(error, 'error', refreshToken);
        throw error;
      });
  };

  const usePatch = (url, data = {}) => {
    setIsLoading(true);

    const isOrgUrl = orgUrls.some(o => url.includes(o));

    if (isOrgUrl) {
      if (adContext && adContext.primary_org) {
        const orgArr = adContext.primary_org.split('/');
        const orgId = orgArr[orgArr.length - 2];
        apiConfig.headers['X-TVS-OrganizationContext'] = orgId;
      }
    }

    return apiInstance
      .patch(formatUrl(url), data, apiConfig)
      .then(response => {
        setIsLoading(false);
        console.log('Response from usePatch', response);
        return response;
      })
      .catch(error => {
        setIsLoading(false);
        console.error('Error in usePatch', error);
        handleError(error, 'error', refreshToken);
        throw error;
      });
  };

  const usePut = (url, data = {}) => {
    setIsLoading(true);

    const isOrgUrl = orgUrls.some(o => url.includes(o));

    if (isOrgUrl) {
      if (adContext && adContext.primary_org) {
        const orgArr = adContext.primary_org.split('/');
        const orgId = orgArr[orgArr.length - 2];
        apiConfig.headers['X-TVS-OrganizationContext'] = orgId;
      }
    }

    return apiInstance
      .put(formatUrl(url), data, apiConfig)
      .then(response => {
        setIsLoading(false);
        console.log('Response from usePut', response);
        return response;
      })
      .catch(error => {
        setIsLoading(false);
        console.error('Error in usePut', error);
        handleError(error, 'error', refreshToken);
        return error;
      });
  };

  const useDelete = (url, isAbsolute = false) => {
    const formattedUrl = isAbsolute ? url : formatUrl(url);

    setIsLoading(true);

    return apiInstance
      .delete(formattedUrl, apiConfig)
      .then(response => {
        setIsLoading(false);
        console.log('Response from useDelete', response);
        return response;
      })
      .catch(error => {
        setIsLoading(false);
        console.error('Error in useDelete', error);
        handleError(error, 'error', refreshToken);
        throw error;
      });
  };

  /////////////////
  // CUSTOM APIs
  /////////////////

  // Handles collection of results from recursive requests
  // eslint-disable-next-line default-param-last
  const useGetAll = (url, total = [], callback) => {
    setIsLoading(true);

    const request =
      url && url.indexOf('http') > -1 ? useGet(url, true) : useGet(url);

    return request
      .then(response => {
        setIsLoading(false);

        const totalItems =
          total && response.results
            ? [...total, ...response.results]
            : total || [];

        if (response && response.next && response.next != null) {
          return useGetAll(response.next, totalItems, callback);
        }

        if (response && !response.next) {
          callback(totalItems);
        }

        return response;
      })
      .catch(error => {
        console.error('Error in useGetAll', error);

        setIsLoading(false);
        handleError(error, 'error', refreshToken);

        return error;
      });
  };

  const useGetBlob = (url, isAbsolute) => {
    const config = {
      headers: {
        ...BASE_HEADERS,
        Authorization: `Bearer ${oktaAuth.getAccessToken()}`,
        'X-TVS-AdvertiserContext': adContext.id,
      },
      responseType: 'blob',
    };
    setIsLoading(true);

    const request = isAbsolute
      ? apiInstance.get(url, config)
      : apiInstance.get(formatUrl(url), config);

    return request
      .then(response => {
        setIsLoading(false);

        if (
          response &&
          response.status &&
          [200, 201].indexOf(response.status) > -1 &&
          response.data
        ) {
          return response.data;
        }

        return response;
      })
      .catch(error => {
        setIsLoading(false);
        handleError(error, 'error', refreshToken);
        console.error('Error in useGetBlob', error);
        return error;
      });
  };

  return {
    isLoading,
    setIsLoading,
    useGet,
    usePatch,
    usePost,
    usePut,
    useDelete,
    useGetAll,
    useGetBlob,
  };
};
