import React, { useMemo, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Typography,
  makeStyles,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import AsyncButton from './AsyncButton';
import InfoTooltip from './InfoTooltip';
import { useLoader } from './hooks/loader';
import alcoholIcon from '../images/alcohol.svg';
import cannabisIcon from '../images/cannabis.svg';
import creditIcon from '../images/credit.svg';
import employmentIcon from '../images/employment.svg';
import gamblingIcon from '../images/gambling.svg';
import housingIcon from '../images/housing.svg';
import matureIcon from '../images/mature.svg';
import politicsIcon from '../images/politics.svg';
import AdvertiserContext from './AdvertiserContext';

const useStyles = makeStyles(({ palette, spacing }) => ({
  card: {
    minWidth: 150,
    height: 150,
    marginBottom: spacing(2),
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  cardTooltip: {
    position: 'absolute',
    top: 12,
    right: 12,
  },
  container: {
    minHeight: 710,
  },
  dropdown: {
    width: 210,
  },
  iconWrap: {
    marginTop: spacing(-3),
    width: 98,
  },
  state: {
    width: 270,
  },
  subsection: {
    margin: 0,
    marginTop: spacing(3),
  },
  subtitle: {
    fontSize: '0.75rem',
    color: '#47505d',
    marginTop: spacing(1),
  },
  successMsg: {
    color: palette.primary.light,
  },
  textfield: {
    marginTop: spacing(3),
  },
  zip: {
    maxWidth: 150,
  },
}));

const categories = [
  { label: 'Alcohol', icon: alcoholIcon, value: 'ALCOHOL' , tooltip: `Selling or promoting products or services related to, or containing Alcohol. Ads for alcoholic products are restricted and only available in certain regions. Such ads must not target households with members under 25 years of age.`},
  { label: 'Cannabis / CBD', icon: cannabisIcon, value: 'WEED' , tooltip: `Selling or promoting products or services related to, or containing Hemp, CBD or Cannabis.`},
  { label: 'Credit', icon: creditIcon, value: 'LENDING' , tooltip: `Ads that promote, or directly link to, a credit opportunity, including but not limited to credit card offers, auto loans, personal or business loan services, mortgage loans and long-term financing. This also includes brand ads for credit cards, regardless of a specific offer.`},
  { label: 'Employment', icon: employmentIcon, value: 'EMPLOYMENT' , tooltip: `Ads that promote, or directly link to, an employment opportunity, including but not limited to part- or full-time jobs, internships or professional certification programs. Related ads that fall within this category include promotions for job boards or fairs, aggregation services or ads detailing perks a company may provide, regardless of a specific job offer.`},
  { label: 'Gambling', icon: gamblingIcon, value: 'GAMBLING' , tooltip: `Selling or promoting products or services related to gambling.`},
  { label: 'Housing', icon: housingIcon, value: 'HOUSING' , tooltip: `Ads that promote, or directly link to, a housing opportunity or related service, including but not limited to listings for the sale or rental of a home or apartment, homeowners insurance, mortgage insurance, mortgage loans, housing repairs and home equity or appraisal services. This does not include ads designed to educate consumers or housing providers about their rights and responsibilities under fair housing laws.`},
  { label: 'Mature Content', icon: matureIcon, value: 'NUDITY' , tooltip: `Promoting content, material, or products specifically targeting or containing a mature audience, incl. but not limitied to video games, alcohol, tv shows, or movies.`},
  { label: 'Politics', icon: politicsIcon, value: 'POLITICS' , tooltip: `Ads made by, on behalf of, or about a candidate for public office, a political figure, a political party or advocates for the outcome of an election to public office. Or, about any election, referendum, or ballot initiative, including “go out and vote” election campaigns. Ads regulated as political advertising. Ads that seek to influence public opinion for or against social issues, like health and civil and social rights.`},
];

const SensitiveCategories = props => {
  const classes = useStyles();
  const adContext = useContext(AdvertiserContext);

  const { isLoading, setIsLoading } = useLoader();

  const {
    sensitiveCategory,
    setSensitiveCategory,
    handleSaveAdAccount,
  } = props;

  const [isAgreed, setIsAgreed] = useState(false);

  const isNextDisabled = useMemo(() => !isAgreed, [isAgreed]);

  const handleSensitive = async () => {
    setIsLoading(true);

    try {
      const res = await handleSaveAdAccount({
        regulated_brand_type: sensitiveCategory,
      })

      setIsLoading(false);

      if (res) {
        props.onNext();
      }

      return res;
    } catch (err) {
      console.log('Error in sentive categories', err)
      setIsLoading(false);
      return err;
    }
  };

  return (
    <Container className={classes.container}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="start"
        justifyContent="space-between"
        height="100%"
        minHeight={710}
        p={6}
      >
        <Box alignSelf="start">
          <Typography variant="h3">
            Is {adContext.name} selling or promoting products or services in
            any of the following categories?
          </Typography>

          <Box mt={2}>
            <Typography className={classes.subtitle}>
              This information is needed to match ads against the appropriate
              inventory and audiences. Failure to disclose sensitive category
              may result in removal from the tvScientific platform.
            </Typography>
          </Box>
        </Box>

        <Box width="100%" mt={2} mb={6}>
          <Divider />
        </Box>

        <Box
          mt={2}
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            display="flex"
            justifyContent="space-evenly"
            width={670}
            flexWrap="wrap"
          >
            {categories.map(c => (
              <Card
                key={c.value}
                className={clsx(
                  classes.card,
                  'Card-button',
                  sensitiveCategory === c.value && 'Card-button-selected'
                )}
                onClick={() => setSensitiveCategory(c.value)}
              >
                <CardContent className={classes.cardContent}>
                  <div className={classes.cardTooltip}>
                    <InfoTooltip title={c.tooltip}/>
                  </div>
                  <Box
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    py={2}
                  >
                    <div className={classes.iconWrap}>
                      <img src={c.icon} width="100%" />
                    </div>

                    <Box mt={-1}>
                      <Typography
                        className="Card-button-label"
                        variant="body2"
                      >
                        {c.label}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            ))}
          </Box>
        </Box>

        <Box
          mt={9}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Button
            onClick={props.onBack}
            color="secondary"
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>

          <Box display="flex" justifyContent="flex-end" width="58%">
            <FormControlLabel
              control={
                <Checkbox
                  checked={isAgreed}
                  onChange={e => setIsAgreed(e.target.checked)}
                />
              }
              label="I understand that failure to disclose sensitive category may result in removal from the tvScientific platform"
              style={{
                width: 800,
              }}
            />

            <AsyncButton
              color="secondary"
              isDisabled={isNextDisabled}
              isLoading={isLoading}
              onClick={handleSensitive}
              size="medium"
              textButton="Create Account"
              variant="contained"
            />
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

SensitiveCategories.propTypes = {
  sensitiveCategory: PropTypes.string,
  setSensitiveCategory: PropTypes.func,
  handleSaveAdAccount: PropTypes.func,
  onBack: PropTypes.func,
  onNext: PropTypes.func,
};

export default SensitiveCategories;
