import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
  Button,
} from '@material-ui/core';
import clsx from 'clsx';
import { Link, useRouteMatch, useHistory } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';

// import Chip from '@material-ui/core/Chip';
// import CheckCircleIcon from '@material-ui/icons/Check';
// import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles(({ palette }) => ({
  activeChip: {
    backgroundColor: palette.primary.lightGreen,
    color: palette.common.green,
  },
  notActiveChip: {
    backgroundColor: palette.error.main,
    color: palette.common.white,
  },
  rowHover: {
    '&:hover': {
      backgroundColor: '#d9f1ff',
    },
  },
  tableCell: {
    minHeight: 81,
  },
  tableHeaderRow: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
  tableHeaderCell: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    color: '#13171a',
    fontSize: '0.875rem',
    fontWeight: 'bold'
  },
  tableBodyCell: {
    borderBottom: 'none',
    color: '#13171a',
    fontWeight: 100
  },
  add: {
    color: '#1dafff',
    display: 'flex',
    alignItems: 'center',
    marginTop: '16px',
    textDecoration: 'none'
  }
}));

const BillingDetailsTable = (props) => {
  const classes = useStyles();
  const { url } = useRouteMatch();
  const history = useHistory();

  const headCells = [
    { id: 'Billing Account', label: 'Billing Account' },
    { id: 'Primary Payment Method', label: 'Primary Payment Method' },
    { id: 'Billing Contact', label: 'Billing Contact' },
    // { id: 'Account Standing', label: 'Account Standing' },
    { id: 'Action', label: 'Action' },
  ];

  const { billingAccounts } = props;
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  className={clsx(classes.tableCell, classes.tableHeaderCell)}
                >
                  {headCell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {billingAccounts.map((row, index) => (
              <TableRow key={index} className={classes.rowHover}>
                <TableCell className={clsx(classes.tableCell, classes.tableBodyCell)}>{row.name}</TableCell>
                <TableCell className={clsx(classes.tableCell, classes.tableBodyCell)}>
                  {row.billing_method}
                </TableCell>
                <TableCell className={clsx(classes.tableCell, classes.tableBodyCell)}>
                  {`${row.secondary_first_name} ${row.secondary_last_name}`}
                </TableCell>
                {/* <TableCell className={clsx(classes.tableCell, classes.tableBodyCell)}>
                {row['Account Standing'] === 'Active' ? (
                  <Chip
                    icon={<CheckCircleIcon className={classes.activeChip} />}
                    label="Active"
                    className={classes.activeChip}
                  />
                ) : (
                  <Chip
                    icon={<CancelIcon />}
                    label="Not Active"
                    className={classes.notActiveChip}
                  />
                )}
              </TableCell> */}
                <TableCell className={clsx(classes.tableCell, classes.tableBodyCell)}>
                  <Button variant="text" color="secondary" onClick={() => { history.push(`/manager/billing-account/${row.id}`, {account: row}) }}>
                    Manage
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Link to={`${url}/addBillingAccount`} className={classes.add}>
        <IconButton color="inherit">
          <AddIcon />
        </IconButton>
        Add Billing Account
      </Link>
    </>
  );
};

BillingDetailsTable.propTypes = {
  billingAccounts: PropTypes.array,
};

export default BillingDetailsTable;
