import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  withStyles,
  Tab
} from '@material-ui/core';


export const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

export const StyledTab = withStyles({
  selected: {
    fontSize: "1.5rem",
    fontWeight: 'bold !important',
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2.1,
    letterSpacing: "normal",
    textAlign: "right",
    color: "#13171a !important",
  },
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  wrapper: {
    alignItems: 'flex-end',
  },
})(Tab);

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
