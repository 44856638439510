import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';

import {
  Box,
  Button,
  Typography,
  Grid,
  IconButton,
  TextField,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Select,
  MenuItem,
  Container,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import AdvertiserContext from '../AdvertiserContext';
import CreditCardForm from '../CreditCardForm';
import { Themes } from '../../constants';
import { useAPI } from '../hooks/api';
import { useLoader } from '../hooks/loader';
import { useTenants } from '../hooks/tenants';
import backIcon from '../../images/icons/back-icon.svg';
import amexIcon from '../../images/american-express-dark.svg';
import discoverIcon from '../../images/discover-dark.svg';
import mastercardIcon from '../../images/master-card-dark.svg';
import visaIcon from '../../images/visa-dark.svg';

const validationSchema = yup.object().shape({
  billing_address: yup.string().required("Required"),
  secondary_contact: yup.string().email("Invalid email").required("Required"),
  secondary_first_name: yup.string().required("Required"),
  secondary_last_name: yup.string().required("Required"),
  billing_method: yup.string().required("Required"),
  name: yup.string().required("Required"),
  accepted_tos: yup.bool().required("Required")
});

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
  root: {
    flexGrow: 1,
  },
  tab: {
    fontSize: "1.5rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.33,
    letterSpacing: "normal",
    textAlign: "right",
    color: "#5c6b73",
  },
  helperTextWrap: {
    display: 'inline-block',
  },
  info: {
    cursor: 'pointer',
    display: 'inline-block',
    verticalAlign: 'text-top',
    width: 14,
    height: 14,
  },
  paper: {
    paddingTop: spacing(5),
    paddingBottom: spacing(5),
    paddingLeft: spacing(9),
    paddingRight: spacing(9),
    maxWidth: 900,
    margin: 'auto',
  },
  submit: {
    ...typography.h4,
    color: '#fff',
    padding: spacing(2),
    width: 175,
  },
  backBtn: {
    fontSize: 14,
    color: '#13171a',
    marginTop: 15,
    marginBottom: 100,
    backgroundColor: 'transparent'
  },
  cancel: {
    ...typography.h4,
    color: '#1a9ee5',
    padding: spacing(2),
    width: 175,
  },
  back: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  tos: {
    fontSize: 11
  },
  subtitle: {
    ...typography.subtitle1,
    fontSize: '1.125rem',
  },
  success: {
    backgroundColor: palette.primary.light,
  },
  successMsg: {
    color: palette.primary.light,
  },
  title: {
    ...typography.h3,
  },
  tooltip: {
    backgroundColor: `#f2f3f5`,
    color: palette.text.primary,
    padding: spacing(2),
    maxWidth: 180,
  },
  label: {
    marginBottom: 10
  },
  wrap: {
    height: 'calc(100% - 64px)',
  },
  headerWrapper: {
    marginTop: 70,
    marginBottom: 100,
    textAlign: 'right'
  },
  tabWrapper: {
    marginTop: 35,
  }
}));

const formatLastFour = digits => digits.split('XXXX')[1];

const paymentIcons = {
  amex: amexIcon,
  discover: discoverIcon,
  visa: visaIcon,
  mastercard: mastercardIcon,
};

const BillingForm = ({ setBillingAccounts }) => {
  const adContext = useContext(AdvertiserContext);
  const classes = useStyles();
  const { usePatch, useGet, usePost } = useAPI();
  const { setIsLoading } = useLoader();
  const currentTenant = useTenants();
  console.log("currentTenant, currentTenant", currentTenant)
  // const [paymentMethods, setPaymentMethods] = useState([]);
  const [parentOrg, setParentOrg] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState({});
  const [isEnterCard, setIsEnterCard] = useState(false);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch
  } = useForm({
    defaultValues: {
      billing_address: '',
      secondary_contact: '',
      secondary_first_name: '',
      secondary_last_name: '',
      billing_method: adContext.theme === Themes.NBCU ? 'INVOICE' : '',
      name: '',
      accepted_tos: false
    },
    resolver: yupResolver(validationSchema),
  });

  const name = watch('name');
  const fundingType = watch('billing_method');

  useEffect(() => {
    if (adContext && adContext.url) {
      setParentOrg(adContext.primary_org)
    }
  }, [adContext])

  useEffect(() => {
    console.log('fundingType', fundingType)
  }, [fundingType])

  useEffect(() => {
    setIsLoading(true)
    const getBillingAccount = async () => {
      try {
        const response = await useGet(`/billing_accounts/${id}`)

        reset({
          ...response,
          accepted_tos: response.accepted_tos ?? false
        });

        setIsLoading(false)
      } catch (e) {
        console.log(e)
        setIsLoading(false)
      }
    }
    if (adContext && adContext.id && id) {
      getBillingAccount();
    }
  }, [])

  async function getProfilesRefresh() {
    try {
      const res = await useGet(`/payment_profiles/refresh`);

      if (res && res.results && res.results.length > 0) {
        const reversed = res.results.reverse();

        setSelectedCard(reversed[0].url);
        setCards(reversed);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const createBillingAccount = async (values) => {
    try {
      const res = await usePost('/billing_accounts/',
        {
          ...values,
          parent_org: parentOrg
        })
      enqueueSnackbar(`Billing account ${res.data.name} created`, {
        autoHideDuration: 3000,
        preventDuplicate: true,
        variant: 'success',
      })
      setIsSubmitting(false);
      reset()
      setBillingAccounts(s => [...s, res.data])
      return history.push('/manager')
    } catch (error) {
      setIsSubmitting(false);
      console.log(error)
    }
  }

  const updateBillingAccount = async (values) => {
    setIsSubmitting(true);
    try {
      const res = await usePatch(`/billing_accounts/${id}`,
        {
          ...values,
        })
      enqueueSnackbar(`Billing account ${res.data.name} updated`, {
        autoHideDuration: 3000,
        preventDuplicate: true,
        variant: 'success',
      })
      setIsSubmitting(false);
      reset()
      setBillingAccounts(s =>
        s.map(item => item.id === res.data.id ? res.data : item)
      )
      return history.push('/manager')
    } catch (error) {
      setIsSubmitting(false);
      console.log(error)
    }
  }
  const onSubmit = (values) => {
    if (!id) {
      return createBillingAccount(values)
    }
    return updateBillingAccount(values)
  };

  const cancel = (e) => {
    e.preventDefault()
    history.push('/manager')
  }

  const handleCloseCreditCard = () => {
    setIsEnterCard(false);
    getProfilesRefresh();
  };

  const renderCard = method => {
    const cardType =
      method.card_type === 'AmericanExpress' ? 'AMEX' : method.card_type;

    return (
      <ListItem
        button
        key={method.id}
        className={classes.paymentListItem}
        selected={selectedCard === method.url}
        onClick={() => setSelectedCard(method.url)}
      >
        <ListItemIcon>
          <img src={paymentIcons[cardType.toLowerCase()]} />
        </ListItemIcon>

        <ListItemText
          primary={
            <Typography className={classes.methods} variant="body2">
              {`${cardType} ending in `}
              {`${formatLastFour(method.card_number)}`}
              &nbsp;{` | `}&nbsp; {`Expiration ${method.expiration_date}`}
            </Typography>
          }
        />

        <IconButton size="small" aria-label="delete">
          <CloseIcon fontSize="small" />
        </IconButton>
      </ListItem>
    );
  };

  return (
    <Container>
      <Button onClick={cancel}
        disableTouchRipple
        disableElevation
        disableFocusRipple
        className={classes.backBtn}
      >
        <div className={classes.back}>
          <img src={backIcon} />
          <span style={{ verticalAlign: 'top', paddingLeft: 10 }}>| {name}</span>
        </div>
      </Button>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container>
          <Grid container spacing={3}>
            <Grid container item spacing={2} xs={6}>
              <Grid item xs={12}>
                <Typography variant="h4" component="h2" className={classes.label}>Billing Company Name</Typography>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <TextField {...field} label="Billing Company Name" fullWidth variant="outlined" />
                  )}
                />
                {errors.name && <span>This field is required</span>}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h4" component="h2" className={classes.label}>Billing Address</Typography>
                <Controller
                  name="billing_address"
                  control={control}
                  render={({ field }) => (
                    <TextField {...field} label="Billing Address" fullWidth variant="outlined" />
                  )}
                />
                {errors.billing_address && <span>This field is required</span>}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h4" component="h2" className={classes.label}>Billing Contact</Typography>
                <Controller
                  name="secondary_contact"
                  control={control}
                  render={({ field }) => (
                    <TextField {...field} label="Contact Email" fullWidth variant="outlined" />
                  )}
                />
                {errors.secondary_contact && <span>This field is required</span>}
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="secondary_first_name"
                  control={control}
                  render={({ field }) => (
                    <TextField {...field} label="Contact First Name" fullWidth variant="outlined" />
                  )}
                />
                {errors.secondary_first_name && <span>This field is required</span>}
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="secondary_last_name"
                  control={control}
                  render={({ field }) => (
                    <TextField {...field} label="Contact Last Name" fullWidth variant="outlined" />
                  )}
                />
                {errors.secondary_last_name && <span>This field is required</span>}
              </Grid>
            </Grid>
            <Grid container item xs={6} style={{ height: 238 }}>
              <Grid item xs={12}>
                <Typography variant="h4" component="h2" className={classes.label}>Purchase Order</Typography>
                <Controller
                  name="purchase_order"
                  control={control}
                  render={({ field }) => (
                    <TextField {...field} label="Purchase Order" fullWidth variant="outlined" />
                  )}
                />
                {errors.purchase_order && <span>This field is required</span>}
              </Grid>
              {adContext.theme === Themes.DEFAULT &&
                <Grid item xs={12}>
                  <Typography variant="h4" component="h2" className={classes.label}>Payment Method</Typography>
                  <Controller
                    name="billing_method"
                    control={control}
                    render={({ field }) => (
                      <Select {...field} fullWidth variant="outlined">
                        {["INVOICE", "CC"].map((method) => (
                          <MenuItem key={method} value={method}>{method}</MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {errors.billing_method && <span>This field is required</span>}

                  {fundingType === 'CC' && currentTenant && currentTenant?.includes('1') && (
                    <Box mt={3}>
                      {cards && cards.length > 0 ? (
                        <List className={classes.cards} dense>
                          {cards.map(c => renderCard(c))}
                        </List>
                      ) : (
                        <Button
                          color="secondary"
                          startIcon={<AddIcon fontSize="small" />}
                          onClick={() => setIsEnterCard(true)}
                        >
                          <Typography className={classes.card}>
                            Add Credit Card
                          </Typography>
                        </Button>
                      )}
                    </Box>
                  )}
                </Grid>
              }
            </Grid>
            <Grid item xs={12} container justify='space-between'>
              <Grid item xs={6}>
                <Controller
                  name="agreement"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          className={classes.tos}
                          checked={field.value}
                          color="secondary"
                        />
                      }
                      label={<span className={classes.tos}>I authorize tvScientific to charge my selected payment method according to the payment terms.</span>}
                    />
                  )}
                />
                {errors.agreement && <span>You must agree to the Terms of Service</span>}
              </Grid>

              <Grid item xs={6}>
                <Button
                  className={classes.cancel}
                  color="secondary"
                  variant="text"
                  onClick={cancel}
                >
                  Cancel
                </Button>

                <Button
                  className={classes.submit}
                  type="submit"
                  color="secondary"
                  disabled={isSubmitting}
                  variant="contained"
                >
                  {isSubmitting ? 'Saving...' : 'Save Settings'}
                </Button>
              </Grid>

              {isEnterCard && (
                <CreditCardForm
                  advertiserId={adContext.id}
                  onClose={handleCloseCreditCard}
                  isEnterCard={isEnterCard}
                />
              )}
            </Grid>
          </Grid>
        </Container>
      </form>
    </Container>
  );
};

BillingForm.propTypes = {
  setBillingAccounts: PropTypes.func,
};


export default BillingForm;


