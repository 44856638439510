import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import AsyncButton from './AsyncButton';
import InfoTooltip from './InfoTooltip';
import { states } from '../constants/usaStates';
import { useLoader } from './hooks/loader';

const useStyles = makeStyles(({ palette, spacing }) => ({
  container: {
    minHeight: 710,
  },
  dropdown: {
    width: 210,
  },
  state: {
    width: 270,
  },
  subsection: {
    margin: 0,
    marginTop: spacing(3),
  },
  subtitle: {
    fontSize: '0.75rem',
    color: '#47505d',
    marginTop: spacing(1),
  },
  successMsg: {
    color: palette.primary.light,
  },
  textfield: {
    marginTop: spacing(3),
  },
  zip: {
    maxWidth: 150,
  },
}));

const attribution = {
  model: [
    { label: 'Last Touch', value: 'LAST_TOUCH' },
    { label: 'First Touch', value: 'FIRST_TOUCH' },
    { label: 'Linear', value: 'LINEAR' },
  ],
  window: [
    { label: '30 days', value: '30' },
    { label: '14 days', value: '14' },
    { label: '7 days', value: '7' },
    { label: '3 days', value: '3' },
    { label: '2 days', value: '2' },
    { label: '1 day', value: '1' },
  ],
};

const tooltips = {
  generalInfoTooltip: `Legal Company Name and Company Address fields will be verified against Federal records for authenticity.`,
  defaultBillingContact: `The default values when creating a new Billing Account. This contact will receive invoices and will be the primary contact for any billing related issues.`,
  doingBusinessAs: `DBA stands for “doing business as”, and is used when a company does business under a name other than the legal name. DBA is sometimes also referred to as an “assumed name”, “fictitious business name”, or “trade name”.`,
  defaultattributionRules: `The default values when creating a new Ad Account. The attribution rules determines how credit for tracking events are assigned to different touch-points in the conversion paths.`,
}

const OrganizationInfo = props => {
  const classes = useStyles();
  const { isLoading, setIsLoading } = useLoader();
  const isNbcuSubdomain = window.location.href.includes('peacock');

  // General Info vars
  const {
    name,
    setName,
    address1,
    setAddress1,
    address2,
    setAddress2,
    city,
    setCity,
    state,
    setState,
    zip,
    setZip,
    phone,
    setPhone,
    contact,
    setContact,
    email,
    setEmail,
    dba,
    setDBA,
    attrModel,
    setAttrModel,
    attrWindow,
    setAttrWindow,
  } = props.org;

  const isNextDisabled = useMemo(() => {
      const requiredFields = [
          name,
          address1,
          city,
          state,
          zip,
          phone,
          contact,
          email,
        ];

      if (!isNbcuSubdomain) {
        requiredFields.push(attrModel, attrWindow);
      }

      return (isLoading || requiredFields.some(i => i === ''));
    }, [
    isLoading,
    name,
    address1,
    city,
    state,
    zip,
    phone,
    contact,
    email,
    attrModel,
    attrWindow,
  ]);

  useEffect(() => {
    return () => {
      setIsLoading(false);
    }
  }, []);

  const handleOrgInfo = async () => {
    setIsLoading(true);

    try {
      const res = await props.handleSaveOrg();

      if (res) {
        console.log('res from saving org', res);
      }

      setIsLoading(false);

      return res;
    } catch (err) {
      console.log('Err saving org', err);
      setIsLoading(false);
      return err;
    }
  };

  return (
    <Container className={classes.container}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="start"
        justifyContent="space-between"
        height="100%"
        minHeight={710}
        p={6}
      >
        <Box alignSelf="start">
          <Typography variant="h3">
            Tell us about your organization
          </Typography>

          <Box mt={2}>
            <Typography className={classes.subtitle}>
              The information can be modified at any time in the Account
              Settings.
            </Typography>
          </Box>
        </Box>

        <Box width="100%" mt={2} mb={6}>
          <Divider />
        </Box>

        <Box width="90%">
          <Grid container spacing={10}>
            <Grid item xs={6}>
              <Box>
                <Typography variant="h5">
                  General Information
                  <InfoTooltip title={tooltips.generalInfoTooltip} />
                  </Typography>

                <TextField
                  className={classes.textfield}
                  color="secondary"
                  fullWidth
                  label="Legal Company Name"
                  margin="none"
                  onChange={event => setName(event.target.value)}
                  value={name}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                />

                <TextField
                  className={classes.textfield}
                  color="secondary"
                  fullWidth
                  label="Street Address"
                  margin="none"
                  onChange={event => setAddress1(event.target.value)}
                  value={address1}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                />

                <TextField
                  className={classes.textfield}
                  color="secondary"
                  fullWidth
                  label="Street Address 2 (optional)"
                  margin="none"
                  onChange={event => setAddress2(event.target.value)}
                  value={address2}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                />

                <TextField
                  className={classes.textfield}
                  color="secondary"
                  fullWidth
                  label="City"
                  margin="none"
                  onChange={event => setCity(event.target.value)}
                  value={city}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                />

                <Box
                  pt={3}
                  mb={3}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <TextField
                    select
                    className={classes.state}
                    color="secondary"
                    fullWidth
                    label="State"
                    margin="none"
                    onChange={event => setState(event.target.value)}
                    value={state}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                  >
                    {states.map(s => (
                      <MenuItem key={s.abbreviation} value={s.abbreviation}>
                        {s.name}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    className={classes.zip}
                    color="secondary"
                    fullWidth
                    label="Zip Code"
                    margin="none"
                    onChange={event => setZip(event.target.value)}
                    value={zip}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                  />
                </Box>

                <Typography variant="h5">Company Phone Number</Typography>

                <TextField
                  className={classes.textfield}
                  color="secondary"
                  fullWidth
                  label="Company Phone Number"
                  margin="none"
                  onChange={event => setPhone(event.target.value)}
                  value={phone}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                />
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box>
                <Typography variant="h5">
                  Default Billing Contact
                  <InfoTooltip title={tooltips.defaultBillingContact}/>
                </Typography>

                <TextField
                  className={classes.textfield}
                  color="secondary"
                  fullWidth
                  label="Default Billing Contact Name"
                  margin="none"
                  onChange={event => setContact(event.target.value)}
                  value={contact}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                />

                <TextField
                  className={classes.textfield}
                  color="secondary"
                  fullWidth
                  label="Default Billing Contact Email"
                  margin="none"
                  onChange={event => setEmail(event.target.value)}
                  value={email}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                />

                <Typography className={classes.subsection} variant="h5">
                  Doing Business As
                  <InfoTooltip title={tooltips.doingBusinessAs} />
                </Typography>

                <TextField
                  className={classes.textfield}
                  color="secondary"
                  fullWidth
                  label="DBA / Trade Name (optional)"
                  margin="none"
                  onChange={event => setDBA(event.target.value)}
                  value={dba}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                />

                {!isNbcuSubdomain && <>
                  <Typography className={classes.subsection} variant="h5">
                    Default Attribution Rules
                    <InfoTooltip title={tooltips.defaultattributionRules} />
                  </Typography>

                  <Box mt={3} display="flex" justifyContent="space-between">
                    <TextField
                      select
                      className={classes.dropdown}
                      color="secondary"
                      fullWidth
                      label="Attribution Model"
                      margin="none"
                      onChange={event => setAttrModel(event.target.value)}
                      value={attrModel}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                    >
                      {attribution.model.map(m => (
                        <MenuItem key={m.value} value={m.value}>
                          {m.label}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      select
                      className={classes.dropdown}
                      color="secondary"
                      fullWidth
                      label="Attribution Window"
                      margin="none"
                      onChange={event => setAttrWindow(event.target.value)}
                      value={attrWindow}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                    >
                      {attribution.window.map(w => (
                        <MenuItem key={w.value} value={w.value}>
                          {w.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                </>}
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          mt={9}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Button
            onClick={props.onBack}
            color="secondary"
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>

          <AsyncButton
            color="secondary"
            isDisabled={isNextDisabled}
            isLoading={isLoading}
            onClick={handleOrgInfo}
            size="medium"
            textButton="Next"
            variant="contained"
          />
        </Box>
      </Box>
    </Container>
  );
};

OrganizationInfo.propTypes = {
  org: PropTypes.object,
  onBack: PropTypes.func,
  onNext: PropTypes.func,
  handleSaveOrg: PropTypes.func,
  setCompanyName: PropTypes.func,
};

export default OrganizationInfo;
