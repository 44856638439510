import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Chip, makeStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';

const useStyles = makeStyles(({ spacing }) => ({
  active: {
    backgroundColor: '#c2f1e3',
    color: '#05734e',
  },
  admin: {
    backgroundColor: '#bfe7ff',
    color: '#2592cf',
  },
  chip: {
    marginLeft: spacing(2),
    padding: spacing(1),
  },
  type: {
    textTransform: 'capitalize',
  },
}));

export const OrgChips = ({ org }) => {
  const classes = useStyles();

  return (
    <Box width="100%" display="flex" justifyContent="flex-end">
      {org?.type && (
        <Chip
          className={clsx(classes.chip, classes.type)}
          label={org.type.toLowerCase()}
        />
      )}

      <Chip
        className={clsx(classes.chip, classes.admin)}
        icon={<PersonOutlineIcon fontSize="small" />}
        label="Admin"
      />

      <Chip
        className={clsx(classes.chip, classes.active)}
        icon={<CheckIcon fontSize="small" />}
        label="Active"
      />
    </Box>
  );
};

OrgChips.propTypes = {
  org: PropTypes.object.isRequired,
};
