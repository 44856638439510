export const getInitialValues = advertiser => {
  return {
    outcome: advertiser.primary_kpi || '',
    attrModel: advertiser.attribution_model || '',
    attrWindow: advertiser.attribution_window || '',
    looker: advertiser.looker_experience || '',
    channels: [],
  };
};

export const formValuesToApi = values => {
  return {
    primary_kpi: values.outcome,
    attribution_model: values.attrModel,
    attribution_window: values.attrWindow,
    looker_experience: values.looker,
  };
};


export const parseReferralSourcesToChannels = ({sources, sourcesCost}) => {
  const channelsNames = [
    'Facebook Ads',
    'Google Ads',
    'Instagram Ads',
    'Snapchat',
  ];

  const referrals = sources.filter(
    r => r && r.display_name && channelsNames.includes(r.display_name)
  );

  const parsed = referrals.reduce((acc, source) => {
    const cost = sourcesCost.find(c => c.referral_source === source.url);

    return [
      ...acc,
      {
        cost,
        source,
        selected: !!cost,
        cost_per_click: +cost?.cost_per_click || '',
      }
    ];
  }, []);

  console.log({
    parsed,
  })

  return parsed

}
