import { createMuiTheme } from '@material-ui/core/styles';

export const defaultTheme = createMuiTheme({
  palette: {
    secondary: {
      main: '#1dafff',
      light: 'hsl(201, 100%, 56%)',
      dark: '#0d94df',
      contrastText: '#fff',
    },
    common:{green: '#06734e'},
    primary: {
      // Example of using a color from core
      // main: green[500],
      main: '#009061',
      lightGreen:'#c2f2e2',
      light: '#0fbf84',
      dark: '#014831',
      contrastText: '#fff',
    },
    text: {
      primary: '#47505d',
      overlay: '#fff',
    },
    grey: {
      main: '#9aa0a6',
    },
    background: {
      overlay: 'rgba(38, 47, 60, 0.95)'
    },
    border: {
      overlay: '#fff',
    },
  },
  typography: {
    fontFamily: ['Noto Sans JP', 'Helvetica', 'Arial', 'sans-serif'].join(
      ','
    ),
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 700,
    fontWeightBold: 900,
    h1: {
      fontSize: '2.5rem',
      color: '#47505D',
      fontWeight: 700,
    },
    h2: {
      fontSize: '1.875rem',
      color: '#181C20',
      fontWeight: 700,
    },
    h3: {
      fontSize: '1.5rem',
      color: '#181C20',
      fontWeight: 700,
    },
    h4: {
      fontSize: '1.25rem',
      color: '#031620',
      fontWeight: 700,
    },
    h5: {
      fontSize: '0.875rem',
      color: '#181c20',
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: '1.5rem',
      color: '#727383',
    },
    body1: {},
    body2: {
      fontSize: '0.875rem',
      color: '#30363E',
    },
    paragraph: {
      fontSize: '0.75rem',
      color: '#47505d',
    },
    button: {
      textTransform: 'none',
    },
  },
  shape: {
    borderRadius: 8,
  },
  overrides: {
    MuiToggleButtonGroup: {
      root: {
        background: '#727383',
      },
    },
    MuiToggleButton: {
      root: {
        fontWeight: 'normal',
        borderWidth: '1px',
        background: '#727383',
        color: '#fff',
        '&:hover, &.Mui-selected:hover': {
          color: 'inherit',
          background: '#727383',
        },
        '&.Mui-selected': {
          color: '#fff',
          background: '#2873A2',
        },
        '&:not(:first-child)::before': {
          content: '""',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          left: 0,
          width: '1px',
          height: '50%',
          backgroundColor: '#c0c8cc',
          marginLeft: '-1px',
          zIndex: 1,
        }
      },
    },
  },
});
