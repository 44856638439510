import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  Typography,
  Checkbox,
  Chip,
  Box,
  makeStyles,
} from '@material-ui/core';

import AdvertiserContext from '../AdvertiserContext';
import AppHeader from '../AppHeader';
import SortableTableHead from '../ui/SortableTableHead';
import { useAPI } from '../hooks/api';
import { useSort } from '../hooks/sort';
import { formatToUSD } from '../util';

const useStyles = makeStyles(({ typography }) => ({
  link: {
    textDecoration: 'none',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: '700',
  },
  noWrap: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    height: 81,
  },
  active: {
    color: '#0fbf84',
    background: 'rgba(122, 220, 187, 0.55)',
    // fontSize: '0.625rem',
  },
  paused: {
    color: '#ff0000',
    background: 'rgba(255, 0, 0, 0.31)',
    // fontSize: '0.625rem',
  },
  height: {
    // fontSize: '0.75rem',
    minHeight: 81,
    height: 81,
  },
  scheduled: {
    color: '#1dafff',
    background: 'rgba(29, 175, 255, 0.24)',
  },
  menuIcon: {
    marginRight: 12,
  },
  name: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: 200,
  },
  stats: {
    fontWeight: '700',
    // fontSize: '0.75rem',
    minWidth: 120,
    minHeight: 81,
    height: 81,
  },
  tableCell: {
    // fontSize: '0.75rem',
    minWidth: 120,
    minHeight: 81,
    height: 81,
  },
  tableHeader: {
    ...typography.h5,
    color: 'rgba(114, 115, 131, 0.8)',
    fontSize: '0.75rem',
    minHeight: 81,
    height: 81,
  },
}));

const headCells = [
  { id: 'id', sortable: true, label: 'ID', disablePadding: false },
  { id: 'name', sortable: true, label: 'Creative', disablePadding: false },
  { id: 'adGroupName', sortable: false, label: 'Ad Group', disablePadding: false },
  { id: 'start_date', sortable: false, label: 'Media Upload Date', disablePadding: false },
  { id: 'spend', sortable: true, label: 'Spend', disablePadding: false },
  { id: 'visits', sortable: true, label: 'Visits', disablePadding: false },
  { id: 'costPerVisit', sortable: true, label: 'Cost per visit', disablePadding: false },
  { id: 'active', sortable: true, label: 'Status', disablePadding: false },
];

const CreativeIndexPage = (props) => {
  const classes = useStyles();
  const adContext = useContext(AdvertiserContext);

  const [creatives, setCreatives] = useState([]);
  const [selected, setSelected] = useState([]);
  const [lineitem, setLineItem] = useState({});

  const { adGroupId, campaignId } = useParams();
  const { useGet } = useAPI();
  const {
    order,
    orderBy,
    handleRequestSort,
    getComparator,
    stableSort
  } = useSort();

  useEffect(() => {
    if (adContext.id !== null) {
      getLineItemName(adGroupId);
    }
  }, [adContext]);

  useEffect(() => {
    if (lineitem) {
      //TODO: prob need to refactor here to account for array of line items
      if (lineitem.creatives !== null && lineitem.creatives !== undefined) {
        getData(lineitem.creatives, [])
      }
    }
  }, [lineitem]);

  async function getLineItemName(lineItemId) {
    try {
      const response = await useGet(`/lineitems/${lineItemId}`);
      setLineItem(response);
    } catch (error) {
      console.log(error);
    }
  }

  function getData(urls) {
    const creativesPromises = urls.map(c => useGet(c, true));

    return Promise.all(creativesPromises)
      .then((...responses) => {
        if (responses[0] && responses[0].length > 0) {
          setCreatives([...responses[0]]);
        }
        return responses;
      })
      .catch(error => console.error(error));
  }

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = creatives.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const creativeStatusIconRender = (status) => {
    if (status === true) {
      return (
        <Chip
          label="Active"
          className={classes.active}
        />
      );
    }

    return <Chip label="Paused" className={classes.paused} />;
  };


  return (
    <AppHeader history={props.history}>
      <Box m={4} mb={2}>
        <Typography>
          <strong><Link className={classes.link} to={`/campaigns/`}>Campaigns</Link></strong> &gt; <strong><Link className={classes.link} to={`/campaigns/${campaignId}/adgroups/${adGroupId}`}>Ad Groups</Link></strong> &gt; Creative
        </Typography>
      </Box>
      <Box border={1} borderColor="grey.300" p={6} pt={4} m={4} borderRadius={20}>
        <Toolbar disableGutters>
          <Typography variant="h6" component="div">Creative</Typography>
        </Toolbar>
        <TableContainer>
          <Table>
            <SortableTableHead
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              onSelectAll={handleSelectAllClick}
            />

            <TableBody>
              {stableSort(
                creatives,
                getComparator(order, orderBy)
              ).map((creative, index) => {
                const isItemSelected = isSelected(creative.id);
                const labelId = `creative-table-checkbox-${index}`;
                const {
                  id,
                  stats_caches_by_lineitem_id: stats_caches,
                  lineitem_set: lineitems,
                } = creative;
                const stats = stats_caches[adGroupId];
                const lineitem = lineitems.find(l => l.id === Number(adGroupId));
                const mediaUploadDate = moment(creative.media_upload_date).format('l');

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, id)}
                    role="checkbox"
                    tabIndex={-1}
                    key={`${creative.id}-${index}`}
                    aria-checked={isItemSelected}
                    selected={isItemSelected}
                  >
                    <TableCell className={clsx(classes.height)} padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </TableCell>
                    <TableCell>{creative.id}</TableCell>
                    <TableCell className={classes.tableCell} component="th" id={labelId} scope="row" padding="none">
                      <Link className={classes.link} to={`/campaigns/${campaignId}/adgroups/${adGroupId}/creative/${creative.id}`}>{creative.name}</Link>
                    </TableCell>
                    <TableCell className={clsx(classes.tableCell, classes.noWrap)} align="left">{lineitem.name}</TableCell>
                    <TableCell className={classes.tableCell} align="center">{mediaUploadDate === 'Invalid date' ? 'n/a' : mediaUploadDate}</TableCell>
                    <TableCell className={clsx(classes.stats)} align="left">{stats != null ? formatToUSD(stats.total_spend) : '-'}</TableCell>
                    <TableCell className={clsx(classes.stats)} align="left">
                      {/* TODO Fix hardcoded hyphen */}
                      {/* {stats != null ? stats.visit_count : '-'} */}
                      -
                    </TableCell>
                    <TableCell className={clsx(classes.stats)} align="left">
                      {/* TODO Fix hardcoded hyphen */}
                      {/* {stats != null ? formatToUSD(stats.total_spend / stats.visit_count) : '-'} */}
                      -
                    </TableCell>
                    <TableCell className={clsx(classes.height)} align="left">{creativeStatusIconRender(creative.active)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </AppHeader>
  );
}

CreativeIndexPage.propTypes = {
  history: PropTypes.object,
};

export default CreativeIndexPage;
