/* eslint-disable no-debugger */
import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import update from 'immutability-helper';
import { useHistory } from 'react-router-dom';
import {
  Button,
  ButtonBase,
  Divider,
  Fade,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Menu,
  Paper,
  Switch,
  Box,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import AdvertiserContext from './AdvertiserContext';
import CampaignFooter from './CampaignFooter';
import GeoAutoSuggest from './GeoAutoSuggest';
import Title from './Title';
import { useAPI } from './hooks/api';
import { useGeo } from './hooks/geo';
import { useLoader } from './hooks/loader';
import { useSaveExit } from './hooks/saveExit';
import excludeIcon from '../images/icons/exclude-location-icon.svg';
import includeIcon from '../images/icons/include-location-icon.svg';
import { Themes } from '../constants';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(0),
    paddingLeft: theme.spacing(0),
    height: '100%',
  },
  backBtn: {
    color: `rgba(0, 0, 0, 0.1)`,
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: '#e0e0e0',
  },
  dropdownArrow: {
    color: '#727383',
  },
  filter: {
    cursor: 'pointer',
  },
  filterIconWrap: {
    width: 16,
    ['& img']: {
      width: '100%',
    },
  },
  filterImg: {
    transition: 'all',
  },
  filterLabel: {
    marginLeft: theme.spacing(1),
  },
  locations: {
    backgroundColor: `#e5e7eb`,
    padding: `0 1.125rem`,
    width: `100%`,
  },
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    padding: `1.625rem`,
  },
  result: {
    marginBottom: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  switch: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(1),
    width: '100%',
  },

  background: {
    backgroundColor: '#262f3c',
    height: '100%',
  },
  title: {
    color: 'white',
  },
  input: {
    color: 'white',
  },
  saveBtn: {
    paddingLeft: 50,
    paddingRight: 50,
    fontSize: '1.05rem',
  },
  cancelBtn: {
    color: '#1dafff',
  },
  '.tvs-Mui-disabled': {
    backgroundColor: 'red',
  },
  incldue: {
    color: 'white',
  },
}));

const Copies = {
  [Themes.DEFAULT]: {
    HEAD: 'Set geo-targeting',
    INTRO: null,
    BATCH_UPLOAD: 'Batch Upload Geo-targeting',
    TARGET_ENTIRE_US_SWITCH: 'Target Entire US',
    BACK: 'Initiate Campaign',
    US: 'US',
  },
  [Themes.NBCU]: {
    HEAD: 'Set Geotargeting',
    INTRO: 'Enter the location(s) you wish to target. To target the entire U.S., ensure the toggle is on. To exclude locations, type a location, click the icon next to the location name, and select exclude.',
    BATCH_UPLOAD: 'Batch Upload Geotargeting',
    TARGET_ENTIRE_US_SWITCH: 'Target Entire U.S.',
    BACK: 'Create Campaign',
    US: 'U.S.',
  },
};

/////////////////////////////
// GEO TARGETING COMPONENT
/////////////////////////////
const GeoTargeting = props => {
  const classes = useStyles();
  const history = useHistory();

  const { useGet, usePatch } = useAPI();
  const { geoUrl, formatGeo } = useGeo();
  const { saveProgress } = useSaveExit();
  const { isLoading, setIsLoading } = useLoader();
  const adContext = useContext(AdvertiserContext);

  const {
    isEditing = false,
    combinedTargetingData,
    geoResults,
    setGeoResults,
    geoFilters,
    setGeoFilters,
    targetEntireUS,
    setTargetEntireUS,
    targetGroupName,
    save,
    triggerSave,
    showOverlay,
  } = props;

  const [filterAnchor, setFilterAnchor] = useState(null);
  const [searchResults, setSearchResults] = useState([]);

  const Copy = useMemo(() => Copies[adContext.theme], [adContext.theme]);

  useEffect(() => {
    saveProgress(save, 'GeoTargeting', handleSave, triggerSave);
  }, [save]);

  const handleOpenFilter = (event, key) => {
    setFilterAnchor(event.currentTarget);

    setGeoFilters(prev => ({
      ...prev,
      [key]: true,
    }));
  };

  const handleCloseFilter = (event, key, index) => {
    if (
      filterAnchor != null &&
      filterAnchor.current &&
      filterAnchor.current.contains(event.target)
    ) {
      return;
    }

    setGeoFilters(prev => {
      const newFilters = { ...prev };
      const keys = Object.keys(newFilters);

      keys.forEach(k => {
        newFilters[k] = false;
      });

      return newFilters;
    });

    if (!key) {
      return;
    }

    if (key) {
      const isExcluded = event;

      setGeoResults(prev => {
        // Create immutable copies
        const updatedResult = update(prev[index], {
          blacklist: { $set: isExcluded },
        });

        const newResults = update(prev, {
          $splice: [[index, 1, updatedResult]],
        });

        return newResults;
      });
    }

    setFilterAnchor(null);
  };

  const handleSubmit = suggestion => {
    let found = false;

    // Handle Case for user searching for united states
    if(suggestion.id === "country.14135384517372290"){
      setTargetEntireUS(true);
      return
    }

    _.some(geoResults, result => {
      if (
        _.includes(result, suggestion.code) ||
        _.includes(result, suggestion.id)
      ) {
        found = true;
      }
    });

    if (found) {
      return;
    }

    setGeoResults(prev => {
      return [
        ...prev,
        {
          ...suggestion,
          blacklist: false,
        },
      ];
    });

    if (targetEntireUS) {
      setTargetEntireUS(false);
    }
  };
  // Event Handlers
  const handleSearchSubmit = event => {
    const { type } = event;

    if (searchResults.length !== 0 && type === `click`) {
      handleSubmit(searchResults[0]);
    }
  };

  const handleDeleteResult = (result, index) => {
    // Utilize useEffect function for removing Entire US
    if (result.id && result.id === 'entire-us') {
      setTargetEntireUS(false);
      return;
    }

    // Handle the rest of the results
    const newResults = geoResults.filter(
      (r, i) => r !== result && i !== index
    );

    if (result && result === geoResults[index]) {
      setGeoResults(newResults);
    }

    if (newResults.length === 0) {
      setGeoResults(prev => []); // eslint-disable-line no-unused-vars
    }
  };

  const handleMBSearch = value => {
    const url = geoUrl(value);

    return useGet(url, true)
      .then(response => {
        if (response && response.features) {
          return response.features;
        }

        return response;
      })
      .catch(error => console.warn('error', error));
  };

  const handleSave = async () => {
    const data = {
      campaign: props.campaignUrl,
      creatives: [],
      daily_budget: 0,
      draft: true,
      targeting: JSON.stringify({
        ...combinedTargetingData,
        geo: formatGeo(geoResults),
      }),
    };

    try {
      const response = await usePatch(
        `/lineitems/${props.currentAdGroup.id}/`,
        data
      );

      // If lineItemId doesn't exist, this is a new adGroup
      if (
        response &&
        response.data &&
        response.data.id &&
        !props.currentAdGroup.id
      ) {
        props.setCurrentAdGroup(response.data);
      }

      // Handle Save & Exit button click
      if (save && save.step && save.step === 'GeoTargeting') {
        if (save.exit) {
          history.push('/home');
        }
      }

      // Handle next button click
      if (!save.exit) {
        props.setStep('DemoTargeting');
        props.updateBreadcrumbs('targeting', 25);
      }

      return response;
    } catch (error) {
      console.error('Error saving GeoTargeting', error);
      return error;
    }
  };

  // Render functions
  const renderTargetResult = (target, index) => {
    const name = target.place_name || target.name || target.text;
    const nameCopy = name.replace("US", Copy.US);
    const id = target.id || target.code;

    return (
      <Grid className={classes.result} key={index} item xs={12}>
        <Paper className={classes.locations} elevation={0} square>
          <Grid alignItems="center" container justify="center">
            <Grid
              className={classes.filter}
              container
              item
              justify="flex-start"
              xs={2}
            >
              <Grid item xs={12}>
                <ButtonBase
                  onClick={event => handleOpenFilter(event, id)}
                  disableRipple
                >
                  <Grid container alignItems="baseline">
                    <Grid item xs={6}>
                      <div className={classes.filterIconWrap}>
                        {target.blacklist ? (
                          <Fade in={target.blacklist} timeout={100}>
                            <img
                              className={classes.filterImg}
                              src={excludeIcon}
                            />
                          </Fade>
                        ) : (
                          <Fade in={!target.blacklist} timeout={100}>
                            <img
                              className={classes.filterImg}
                              src={includeIcon}
                            />
                          </Fade>
                        )}
                      </div>
                    </Grid>

                    <Grid className={classes.dropdownArrow} item xs={6}>
                      <ExpandMoreIcon style={{ fontSize: '1rem' }} />
                    </Grid>
                  </Grid>
                </ButtonBase>

                {filterAnchor !== null && (
                  <FormControl className={classes.formControl}>
                    <Menu
                      anchorEl={filterAnchor}
                      elevation={2}
                      getContentAnchorEl={null}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                      open={Boolean(geoFilters[id])}
                      onClose={() => handleCloseFilter()}
                      value={target.blacklist}
                    >
                      <MenuItem
                        onClick={() => handleCloseFilter(false, id, index)}
                        value={false}
                      >
                        <div className={classes.filterIconWrap}>
                          <img src={includeIcon} />
                        </div>

                        <span className={classes.filterLabel}>Include</span>
                      </MenuItem>

                      <MenuItem
                        onClick={() => handleCloseFilter(true, id, index)}
                        value={true}
                      >
                        <div className={classes.filterIconWrap}>
                          <img src={excludeIcon} />
                        </div>

                        <span className={classes.filterLabel}>Exclude</span>
                      </MenuItem>
                    </Menu>
                  </FormControl>
                )}
              </Grid>
            </Grid>

            <Grid item xs={9}>
              {nameCopy}
            </Grid>

            <Grid item xs={1}>
              <IconButton
                aria-label="delete"
                onClick={() => {
                  handleDeleteResult(target, index);
                }}
              >
                <ClearIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  };

  return (
    <Fragment>
      <Grid container style={{ height: '100%' }} justify="center">
        <Box width="100%">
          {!isEditing && (
            <Fragment>
              <Title id="geo-targeting-header">{Copy.HEAD}</Title>

              {Copy.INTRO && <p>{Copy.INTRO}</p>}

              <Divider className={classes.divider} />
            </Fragment>
          )}

          <Grid
            container
            alignItems="center"
            direction="column"
            justify="center"
          >
            <Grid item xs={isEditing ? 5 : 6}>
              {!isEditing && (
                <FormControlLabel
                  className={classes.switch}
                  label={Copy.TARGET_ENTIRE_US_SWITCH}
                  control={
                    <Switch
                      checked={targetEntireUS}
                      onChange={event =>
                        setTargetEntireUS(event.target.checked)
                      }
                      color="secondary"
                      name="targetEntireUs"
                      size="small"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  }
                />
              )}
              <Paper
                className={classes.paper}
                elevation={0}
                variant="outlined"
              >
                <Grid container>
                  {geoResults.length > 0 &&
                    geoResults.map((txt, i) => renderTargetResult(txt, i))}

                  <Grid item xs={12}>
                    <GeoAutoSuggest
                      label="Geographic Targeting"
                      placeholder="Enter City, Zip Code, DMA, or State"
                      value={targetGroupName}
                      handler={handleSubmit}
                      handleSubmitClick={handleSearchSubmit}
                      onSearch={v => handleMBSearch(v)}
                      setSearchResults={setSearchResults}
                    />
                  </Grid>

                  <Grid container item justify="space-between" xs={12}>
                    <Grid item>
                      <Button
                        onClick={() => showOverlay(true)}
                        color="secondary"
                        size="small"
                        startIcon={<CloudUploadOutlinedIcon />}
                      >
                        {Copy.BATCH_UPLOAD}
                      </Button>
                    </Grid>
                    {/* TODO: Uncomment to hook up geo imports/exports */}
                    {/* <Grid item>
                    <Button
                      color="secondary"
                      size="small"
                      startIcon={<SystemUpdateAltIcon />}
                    >
                      Download Template
                    </Button>
                  </Grid> */}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>

        {!isEditing && (
          <CampaignFooter
            isLoading={isLoading}
            back={Copy.BACK}
            next={'Demographics'}
            onBack={() => {
              props.setStep('CreateNewCampaign');
              props.handleCampaignPanel(false);
              props.updateBreadcrumbs('targeting', -1, 'createCampaign', 25);
            }}
            onNext={() => {
              setIsLoading(true);
              triggerSave('GeoTargeting', false, 'DemoTargeting');
            }}
            page={1}
          />
        )}

        {isEditing && (
          <Grid container justify="center">
            <Grid item xs={5}>
              <FormControlLabel
                className={classes.switch}
                label={
                  <Typography variant="body2">{Copy.TARGET_ENTIRE_US_SWITCH}</Typography>
                }
                control={
                  <Switch
                    checked={targetEntireUS}
                    onChange={event =>
                      setTargetEntireUS(event.target.checked)
                    }
                    color="secondary"
                    name="targetEntireUs"
                    size="small"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
};

GeoTargeting.propTypes = {
  isEditing: PropTypes.bool,
  geoResults: PropTypes.array,
  setGeoResults: PropTypes.func,
  geoFilters: PropTypes.object,
  setGeoFilters: PropTypes.func,
  targetEntireUS: PropTypes.bool,
  setTargetEntireUS: PropTypes.func,
  targetGroupName: PropTypes.string,
  handleCampaignPanel: PropTypes.func,
  updateBreadcrumbs: PropTypes.func,
  setStep: PropTypes.func,
  currentAdGroup: PropTypes.object,
  setCurrentAdGroup: PropTypes.func,
  campaignUrl: PropTypes.string,
  advertiserId: PropTypes.number,
  combinedTargetingData: PropTypes.object,
  save: PropTypes.object,
  triggerSave: PropTypes.func,
  showOverlay: PropTypes.func,
};

export default GeoTargeting;
