import { boolean, object, string, number } from 'yup';

export const validationSchema = object().shape({
  name: string().required('Required'),
  advertiserName: string().required('Required'),
  website: string().required('Required'),
  category: string().required('Required'),
  budget: number().typeError('Required').when('isUncapped', {
    is: false,
    then: s => s.required('Required'),
  }),
  isUncapped: boolean().required('Required'),
  understand: boolean().when('sensitive', {
    is: value => value == 'NONE',
    then: s => s.isTrue('Required'),
  }),
  billingAccount: string().required('Required'),
  purchaseOrder: string().required('Required'),
  sensitive: string().required('Required'),
});

export const getInitialValues = adAccount => {
  return {
    name: adAccount?.ad_account_name || '',
    advertiserName: adAccount?.name || '',
    website: adAccount?.domain || '',
    category: adAccount?.category || '',
    budget: adAccount?.ad_account_budget || 0,
    isUncapped: !adAccount?.ad_account_budget || false,
    billingAccount: adAccount?.billing_account || '',
    purchaseOrder: adAccount?.invoice_po || '',
    sensitive: adAccount?.regulated_brand_type === "" ? 'NONE' : adAccount?.regulated_brand_type || '',
    understand: adAccount?.understand || false,
  };
};

export const formValuesToApi = values => {
  return {
    ad_account_name: values.name,
    name: values.advertiserName,
    domain: values.website,
    category: values.category,
    ad_account_budget: values.isUncapped ? null : values.budget,
    understand: values.understand,
    billing_account: values.billingAccount,
    invoice_po: values.purchaseOrder,
    regulated_brand_type: values.sensitive !== 'NONE' ? values.sensitive : '',
  };
};
