import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { LookerEmbedSDK } from '@looker/embed-sdk';
import { embedDashboard } from 'amazon-quicksight-embedding-sdk';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';

import {
  Box,
  Container,
  Grid,
  MenuItem,
  Paper,
  Tabs,
  Tab,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';

import AppHeader from '../AppHeader';
import AdvertiserContext from '../AdvertiserContext';
import { useAPI } from '../hooks/api';
import { useReports } from '../hooks/reports';
import { useInternalUser } from '../hooks/internalUser';
import SankeyChart from '../visualizations/SankeyChart';
import { useLoader } from "../hooks/loader";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import AsyncButton from './../AsyncButton';
import { formatDateData } from '../util';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    maxWidth: 'none',
  },
  paper: {
    padding: theme.spacing(5),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    minHeight: 1200,
  },
  dashboard: {
    '& > iframe': {
      width: '100%',
      height: 1100,
    }
  },
  select: {
    width: 200,
  },
  tabs: {
    minWidth: 275,
    margin: '0 20px',

    ['& .tvs-MuiTypography-root']: {
      fontSize: '1.25rem',
    },

    ['&.tvs-Mui-selected .tvs-MuiTypography-root']: {
      fontWeight: 700,
    }
  },
  endDate: {
    marginLeft: '10px',
  },
  dateFilterBtn: {
    marginTop: '15px'
  }
}));

const QUICKSIGHT_ALL = '[ALL]';

const ReportsPage = props => {
  const location = useLocation();
  const classes = useStyles();
  const adContext = useContext(AdvertiserContext);
  const lookerRef = useRef(null);
  const qsRef = useRef(null);
  const dashboardRef = useRef(null);

  const { useGet } = useAPI();
  const enableView = useInternalUser() || (adContext && adContext.cost_model !== 'CPA');

  const {
    getIncrementalityEmbedUrl,
    getNBCUIncrementalityEmbedUrl,
    getLookerEmbedUrl,
    getP2PEmbedUrl,
    getNBCUP2PEmbedUrl,
    getQsEmbedUrl,
    getDataTableEmbedUrl,
    getDailyPerformanceEmbedUrl,
    embedUrl
  } = useReports();

  const [tab, setTab] = useState(0);
  const [campaigns, setCampaigns] = useState([]);
  const [campaignId, setCampaignId] = useState(QUICKSIGHT_ALL);
  const [embedType, setEmbedType] = useState(null);
  const [qsParams, setQsParams] = useState({
    campaignId: QUICKSIGHT_ALL,
  });
  const { isLoading, setIsLoading } = useLoader(false);
  const [dashboardData, setDashboardData] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(
    new Date(moment().subtract(30, 'days').format('YYYY/MM/DD'))
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    new Date(moment().format('YYYY/MM/DD'))
  );
  const [minStartDate] = useState(
    new Date(moment().subtract(90, 'days').format('YYYY/MM/DD'))
  )

  const tabs = useMemo(() => {
    const tabsList = [
      {
        id: 'campaignPerformance',
        title: 'Campaign Performance',
      }
    ];

    if (enableView) {
      tabsList.push({
        id: 'dailyPerformance',
        title: 'Daily Performance',
      });
    }

    if (adContext.path_to_purchase_validated) {
      tabsList.push({
        id: 'pathToPurchase',
        title: 'Path to Purchase',
      });
    }

    if (adContext.has_incrementality) {
      tabsList.push({
        id: 'campaignIncrementality',
        title: 'Campaign Incrementality',
      });
    }

    if (enableView) {
      tabsList.push({
        id: 'sourceData',
        title: 'Source Data',
      });
    }

    return tabsList;
  }, [enableView, adContext]);

  useEffect(() => {
    getCampaigns();
  }, [adContext.id]);

  useEffect(() => {
    setTab(0);
  }, [adContext.id])

  useEffect(() => {
    if (adContext && adContext.id != null) {
      if (tab === 0) {
        if (adContext.uses_looker && adContext.looker_validated) {
          setEmbedType('looker');
          getLookerEmbedUrl();
        } else {
          setEmbedType('qs');
          getQsEmbedUrl();
        }
      }

      if (tab === 1) {
        if (adContext.uses_looker && adContext.looker_validated) {
          setEmbedType('dailyPerformance');
          getDailyPerformanceEmbedUrl();
        }
      }

      if (tab === 2) {
        if (adContext.path_to_purchase_validated) {
          setEmbedType('p2p');
          fetchP2PData();
          adContext.is_nbcu_tenant ? getNBCUP2PEmbedUrl() : getP2PEmbedUrl();
        } else if (adContext.has_incrementality) {
          setEmbedType('incrementality');
          adContext.is_nbcu_tenant ? getNBCUIncrementalityEmbedUrl() : getIncrementalityEmbedUrl();
        } else {
          setEmbedType('sourceData');
          getDataTableEmbedUrl();
        }
      }

      if (tab === 3) {
        if (adContext.has_incrementality) {
          setEmbedType('incrementality');
          adContext.is_nbcu_tenant ? getNBCUIncrementalityEmbedUrl() : getIncrementalityEmbedUrl();
        } else {
          setEmbedType('sourceData');
          getDataTableEmbedUrl();
        }
      }

      if (tab === 4) {
        setEmbedType('sourceData');
        getDataTableEmbedUrl();
      }
    }
  }, [adContext.id, tab, location]);

  useEffect(() => {
    if (embedType === 'looker' || embedType === 'incrementality' || embedType === 'sourceData' || embedType === 'dailyPerformance') {
      LookerEmbedSDK.init(
        process.env.BASE_URL,
        `${process.env.API_URL}/looker-reporting-embed-url/`
      );
    }

    if (embedType === 'p2p') {
      LookerEmbedSDK.init(
        process.env.BASE_URL,
      );
    }
  }, [embedType]);

  useEffect(() => {
    if (embedUrl) {
      if (embedType === 'looker' || embedType === 'incrementality' || embedType === 'p2p' || embedType === 'sourceData' || embedType === 'dailyPerformance') {
        handleLookerEmbed();
      }

      if (embedType === 'qs') {
        handleQsEmbed();
      }
    }
  }, [embedUrl, embedType, location]);

  useEffect(() => {
    if (qsParams && dashboardRef && dashboardRef.current && dashboardRef.current.iframe.contentWindow) {
      dashboardRef.current.setParameters(qsParams);
    }
  }, [qsParams, dashboardRef]);

  async function getCampaigns() {
    try {
      const response = await useGet('/campaigns');
      setCampaigns(response.results);
    } catch (error) {
      console.error('Error getting campaigns', error);
    }
  }

  const handleSetupDashboard = dashboard => {
    document.querySelector('#run').addEventListener('click', () => {
      dashboard.send('dashboard:run')
    })
  };

  const handleQsFilter = value => {
    setCampaignId(value);

    setQsParams(prev => {
      return ({
        ...prev,
        campaignId: value
      });
    });
  };

  const handleQsEmbed = async () => {
    if (qsRef && qsRef.current && qsRef.current.firstChild) {
      qsRef.current.firstChild.remove();
    }

    dashboardRef.current = await embedDashboard({
      url: embedUrl,
      container: qsRef.current,
      height: 'AutoFit',
    });

    handleQsFilter(QUICKSIGHT_ALL);
  };

  const handleLookerEmbed = () => {
    if (
      lookerRef &&
      lookerRef.current &&
      lookerRef.current.firstChild
    ) {
      lookerRef.current.firstChild.remove();
    }

    LookerEmbedSDK.createDashboardWithUrl(embedUrl)
      .appendTo('#dashboard')
      .build()
      .connect()
      .then(handleSetupDashboard)
      .catch(error => {
        console.error('An unexpected error occurred', error)
      })
  };

  const fetchP2PData = async () => {
    setIsLoading(true)
    const p2pUrl = `${process.env.REPORTING_API}/sankeylast30days`

    try {
      const response = await useGet(
        p2pUrl,
        true
      );

      if (response) {
        const parsed = await parseP2PData(response)
        setDashboardData(parsed)
        setIsLoading(false)
      }
      return response;
    } catch (err) {
      console.log('error loading path to purchase data', err);
      setIsLoading(false)
      return err;
    }
  };

  const parseP2PData = (data) => {
    const dataMap = {
      "links": [
        {
          "source": 0,
          "target": 1,
          "value": null
        },
        {
          "source": 0,
          "target": 2,
          "value": null
        },
        {
          "source": 0,
          "target": 3,
          "value": null
        },
        {
          "source": 0,
          "target": 4,
          "value": null
        },
        {
          "source": 1,
          "target": 5,
          "value": null
        },
        {
          "source": 2,
          "target": 6,
          "value": null
        },
        {
          "source": 3,
          "target": 7,
          "value": null
        },
        {
          "source": 4,
          "target": 8,
          "value": null
        },
        {
          "source": 5,
          "target": 9,
          "value": null
        },
        {
          "source": 6,
          "target": 9,
          "value": null
        },
        {
          "source": 7,
          "target": 9,
          "value": null
        },
        {
          "source": 8,
          "target": 9,
          "value": null
        }
      ],
      "nodes": [
        {
          "color": "#0fbf84",
          "name": "Impressions"
        },
        {
          "color": "#f27799",
          "name": "Direct/Organic"
        },
        {
          "color": "#5744b2",
          "name": "Social"
        },
        {
          "color": "#ffb837",
          "name": "Search"
        },
        {
          "color": "#727383",
          "name": "Other"
        },
        {
          "color": "#f27799",
          "name": "Direct/Organic Outcome"
        },
        {
          "color": "#5744b2",
          "name": "Social Outcome"
        },
        {
          "color": "#ffb837",
          "name": "Search Outcome"
        },
        {
          "color": "#727383",
          "name": "Other Outcome"
        },
        {
          "color": "#009061",
          "name": "Conversion"
        }
      ]
    }

    const values = []
    let results = {};

    data.forEach(arr => {
      arr.forEach(item => {
        if (typeof item === 'number') values.push(item)
      })

    })
    results = dataMap.links.map((link, i) => ({...link, value: values[i]}))

    dataMap.links = results;

    return dataMap;
  }

  const filterP2PData = async (startDate, endDate) => {
    const start = formatDateData(startDate).split('T')[0];
    const end = formatDateData(endDate).split('T')[0];
    const p2pUrl = `${process.env.REPORTING_API}/sankey?startDate=${start}&endDate=${end}`
    try {
      const response = await useGet(
        p2pUrl,
        true
      );

      if (response) {
        const parsed = await parseP2PData(response)
        setDashboardData(parsed)
        setIsLoading(false)
      }
      return response;
    } catch (err) {
      console.log('error loading path to purchase data', err);
      setIsLoading(false)
      return err;
    }
  }

  const handleUpdateStartDate = date => {
    if (!date) {
      date = '';
    }
    setSelectedStartDate(date);
  };

  const handleUpdateEndDate = date => {
    if (!date) {
      date = '';
    }
    setSelectedEndDate(date);
  };

  const renderQuickSight = () => (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        mb={4}
      >
        <Box>
          <Typography variant="h6">
            Reports
          </Typography>
        </Box>

        <Box>
          <TextField
            select
            className={classes.select}
            color="secondary"
            label="Campaigns"
            onChange={event => handleQsFilter(event.target.value)}
            size="small"
            value={campaignId}
            variant="outlined"
          >
            <MenuItem dense value={QUICKSIGHT_ALL}>All</MenuItem>

            {campaigns.map(campaign => (
              <MenuItem dense key={campaign.id} value={campaign.id}>
                <Typography variant="body2" noWrap>
                  {campaign.name}
                </Typography>
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>

      <div ref={qsRef} />
    </>
  );

  // TODO: clean up these Looker views into one
  const renderIncrementality = () => (
    <Box mt={2}>
      <div ref={lookerRef} id="dashboard" className={classes.dashboard} />
    </Box>
  );

  const renderLooker = () => (
    <>
      <Box mt={2}>
        <div ref={lookerRef} id="dashboard" className={classes.dashboard} />
      </Box>
    </>
  );

  const renderSourceData = () => (
    <>
      <Box mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box mt={2} mb={1}>
              <div
                ref={lookerRef}
                id="dashboard"
                className={classes.dashboard}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );

  const renderDailyPerformance = () => (
    <>
      <Box mt={2}>
        <div ref={lookerRef} id="dashboard" className={classes.dashboard} />
      </Box>
    </>
  );

  const renderPathToPurchase = () => (
    <>
      <Box mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box mt={2} mb={1}>
              <div
                ref={lookerRef}
                id="dashboard"
                className={classes.dashboard}
              />
            </Box>

            <Box width="100%">
              <Grid container item xs={12} justify="space-evenly" spacing={2}>
                <Grid item xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container>
                      <Grid item xs={4}>
                        <KeyboardDatePicker
                          color="secondary"
                          disableFuture
                          disableToolbar
                          fullWidth
                          format='MM/dd/yyyy'
                          inputVariant='outlined'
                          id='date-picker-inline'
                          label='Start Date'
                          margin='normal'
                          minDate={minStartDate}
                          maxDate={selectedEndDate}
                          onChange={handleUpdateStartDate}
                          value={selectedStartDate}
                          variant='inline'
                        />
                      </Grid>

                      <Grid item xs={4}>
                        <KeyboardDatePicker
                          className={classes.endDate}
                          color="secondary"
                          disableFuture
                          disableToolbar
                          fullWidth
                          format='MM/dd/yyyy'
                          inputVariant='outlined'
                          id='date-picker-inline'
                          label='End Date'
                          margin='normal'
                          minDate={selectedStartDate}
                          onChange={handleUpdateEndDate}
                          value={selectedEndDate}
                          variant='inline'
                        />
                      </Grid>

                      <Grid item xs={4}>
                        <AsyncButton
                          id="date-filter"
                          isLoading={isLoading}
                          variant="contained"
                          color="secondary"
                          size="medium"
                          className={clsx('Button--medium', classes.dateFilterBtn)}
                          onClick={() => {
                            setIsLoading(true);
                            filterP2PData(selectedStartDate, selectedEndDate);
                          }}
                          loadingButton="Loading..."
                        >
                          Filter
                        </AsyncButton>
                      </Grid>
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>

              {!isLoading && dashboardData ? (
                <SankeyChart data={dashboardData} />
                ) : (
                  <p>loading...</p>
                )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );

  return (
    <AppHeader history={props.history}>
      <Container className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper
              className={clsx(classes.paper, 'background-img-inline-example')}
            >
             {adContext &&
               <Box my={4}>
                 <Tabs value={tab} onChange={(e, v) => setTab(v)} variant='scrollable' scrollButtons='auto'>
                   {tabs.map(tab => (
                     <Tab
                       disableRipple
                       className={classes.tabs}
                       key={tab.id}
                       label={(
                         <Typography>
                           <Box
                             display="flex"
                             alignItems="center"
                             justifyContent="center"
                           >
                             {tab.title}
                           </Box>
                         </Typography>
                       )}
                     />
                   ))}
                 </Tabs>
               </Box>
             }

              <Box>
                {tab === 0 && (
                  <>
                    {adContext &&
                      adContext.uses_looker &&
                      adContext.looker_validated
                        ? renderLooker()
                        : renderQuickSight()}
                  </>
                )}

                {tab === 1 && (
                  <>
                    {adContext &&
                      adContext.uses_looker &&
                      adContext.looker_validated
                        ? renderDailyPerformance() : ''}
                  </>
                )}

                {tab === 2 && (
                  <>
                    {adContext &&
                      adContext.uses_looker &&
                      adContext.looker_validated &&
                      adContext.path_to_purchase_validated ?
                        renderPathToPurchase() :
                        adContext.has_incrementality
                        ? renderIncrementality() :
                          renderSourceData()}
                  </>
                )}

                {tab === 3 && (
                  <>
                    {adContext &&
                      adContext.uses_looker &&
                      adContext.path_to_purchase_validated &&
                      adContext.has_incrementality
                        ? renderIncrementality()
                        : renderSourceData()}
                  </>
                )}

                {tab === 4 && (
                  <>
                    {adContext &&
                      adContext.uses_looker &&
                      adContext.looker_validated &&
                      adContext.path_to_purchase_validated &&
                      adContext.has_incrementality
                      ? renderSourceData()
                      : ''}
                  </>
                )}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </AppHeader>
  );
};

ReportsPage.propTypes = {
  history: PropTypes.object,
};

export default ReportsPage;
