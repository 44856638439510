import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Typography,
  makeStyles,
} from '@material-ui/core';

import AsyncButton from './AsyncButton';
import InfoTooltip from './InfoTooltip';
import { useLoader } from './hooks/loader';
import advertiserIcon from '../images/icons/box-icon.svg';
import agencyIcon from '../images/icons/bullhorn-icon.svg';

const useStyles = makeStyles(theme => ({
  card: {
    width: 325,
    height: 358,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    minHeight: 710,
  },
  iconWrap: {
    width: '50%',
  },
  footer: {
    height: 150,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignSelf: 'flex-end',
  },
  subtitle: {
    fontSize: '0.75rem',
    color: '#47505d',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

const tooltips = {
  advertiser: `Select Advertiser if your company create products or services that you sell to others.`,
  agency: `Select Agency if your company manage digital advertising campaigns on behalf of others, or if you are a game studio that create and promote multiple titles.`,
}


const OrganizationType = ({ onNext, orgType, setOrgType }) => {
  const classes = useStyles();

  const { isLoading, setIsLoading } = useLoader();

  const isNextDisabled = useMemo(
    () => !orgType || orgType === '' || isLoading,
    [orgType, isLoading]
  );

  const handleSaveOrgType = () => {
    setIsLoading(true);
    onNext();
    setIsLoading(false);
  };

  return (
    <Container className={classes.container}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
        minHeight={710}
        p={6}
      >
        <Box>
          <Typography variant="h3">Tell us about your business</Typography>

          <Box mt={2}>
            <Typography className={classes.subtitle}>
              The information can be modified at any time in the Account
              Settings.
            </Typography>
          </Box>

          <Divider />

          <Box
            mt={8}
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box display="flex" justifyContent="space-between" width={670}>
              <Card
                raised={orgType !== 'ADVERTISER'}
                className={clsx(
                  classes.card,
                  'Card-button',
                  orgType === 'ADVERTISER' ? 'Card-button-selected' : {}
                )}
                onClick={() => setOrgType('ADVERTISER')}
              >
                <CardHeader
                  disableTypography
                  title={
                    <Typography variant="h4">
                      We&apos;re an advertiser
                      <InfoTooltip title={tooltips.advertiser} />
                    </Typography>
                  }
                />

                <CardContent className={classes.cardContent}>
                  <Box
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    py={2}
                  >
                    <div className={classes.iconWrap}>
                      <img
                        src={advertiserIcon}
                        alt="Advertiser"
                        width="100%"
                      />
                    </div>

                    <Box mt={5}>
                      <Typography
                        className="Card-button-label"
                        variant="body2"
                      >
                        We promote and sell products or services in digital
                        channels.
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>

              <Card
                raised={orgType !== 'AGENCY'}
                className={clsx(
                  classes.card,
                  'Card-button',
                  orgType === 'AGENCY' ? 'Card-button-selected' : {}
                )}
                onClick={() => setOrgType('AGENCY')}
              >
                <CardHeader
                  disableTypography
                  title={
                    <Typography variant="h4">
                      We&apos;re an agency
                      <InfoTooltip title={tooltips.agency} />
                    </Typography>
                  }
                />

                <CardContent className={classes.cardContent}>
                  <Box
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    py={2}
                  >
                    <div className={classes.iconWrap}>
                      <img src={agencyIcon} alt="Agency" width="100%" />
                    </div>

                    <Box mt={5}>
                      <Typography
                        className="Card-button-label"
                        variant="body2"
                      >
                        We manage digital advertising campaigns on behalf of
                        our clients.
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Box>
        </Box>

        <Box className={classes.footer}>
          <AsyncButton
            color="secondary"
            isDisabled={isNextDisabled}
            isLoading={isLoading}
            onClick={handleSaveOrgType}
            size="medium"
            textButton="Next"
            variant="contained"
          />
        </Box>
      </Box>
    </Container>
  );
};

OrganizationType.propTypes = {
  onNext: PropTypes.func,
  orgType: PropTypes.string,
  setOrgType: PropTypes.func,
};

export default OrganizationType;
