import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Paper,
  Typography,
  Tabs,
  Tab,
  makeStyles,
} from '@material-ui/core';

import AdvertiserContext from '../AdvertiserContext';
import AppHeader from '../AppHeader';
import AdAccounts from '../AdAccounts';
import ManageOrganization from '../ManageOrganization';
import { useAPI } from '../hooks/api';
import BillingAccounts from '../BillingAccounts'
import LoadingSpinner from '../ui/LoadingSpinner';

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    flexGrow: 1,
    marginTop: 60,
    marginBottom: 60,
  },
  headerWrap: {
    position: 'absolute',
    top: spacing(7),
    width: 120,
    textAlign: 'right',
  },
  header: {
    fontSize: '0.75rem',
  },
  company: {
    color: '#1dafff',
    fontSize: '0.75rem',
  },
  tab: {
    textAlign: 'right',
  },
  tabs: {
    borderRight: `1px solid ${palette.divider}`,
    paddingTop: spacing(18),
  },
}));

const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ width: '100%', minHeight: 880, flex: 1 }}
      {...other}
    >
      {value === index && (
        <Box p={7} height="100%">
          {children}
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const BusinessManagerPage = props => {
  const classes = useStyles();
  const adContext = useContext(AdvertiserContext);

  const { useGet } = useAPI();

  const [tabIndex, setTabIndex] = useState(0);
  const [org, setOrg] = useState({});

  useEffect(() => {
    if (adContext && adContext.primary_org) {
      getOrg();
    }
  }, [adContext]);

  async function getOrg() {
    try {
      const res = await useGet('/organizations/my/');
      setOrg(res);
    } catch (err) {
      console.log('error in getting org', err);
    }
  }

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <AppHeader history={props.history}>
      <Paper
        elevation={0}
        style={{
          padding: '0 0 0 40px',
          margin: '145px auto 0',
          minHeight: 880,
          maxWidth: 1512,
        }}
        variant="outlined"
      >
        {!adContext.id ?  <LoadingSpinner /> : (
          <Box display="flex" width="100%" height="100%" position="relative">
            <Box className={classes.headerWrap}>
              <Typography className={classes.header}>
                BUSINESS MANAGER
              </Typography>

              <Typography className={classes.company}>
                {adContext.name}
              </Typography>
            </Box>

            <Tabs
              orientation="vertical"
              value={tabIndex}
              onChange={handleTabChange}
              aria-label="Business Manager Tabs"
              className={classes.tabs}
            >
              <Tab
                className={classes.tab}
                label={<Typography variant="h5">Organization</Typography>}
              />
              <Tab className={classes.tab} label="Ad Accounts" />
              <Tab className={classes.tab} label="Billing Accounts" />
            </Tabs>

            <TabPanel value={tabIndex} index={0}>
              <ManageOrganization org={org} setOrg={setOrg} />
            </TabPanel>

            <TabPanel value={tabIndex} index={1}>
              <AdAccounts org={org} />
            </TabPanel>

            <TabPanel value={tabIndex} index={2}>
              <BillingAccounts org={org} />
            </TabPanel>
          </Box>
        )}
      </Paper>
    </AppHeader>
  );
};

BusinessManagerPage.propTypes = {
  history: PropTypes.object,
};

export default BusinessManagerPage;
