import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Button } from '@material-ui/core';
import uploadIconSvg from '../images/icon-upload.svg';

const FileDropzone = props => {
  const { accept, handleDrop, uploadIcon, description, specifications } =
    props;

  const onDrop = useCallback(files => {
    handleDrop(files);
  }, []);

  const { getInputProps, getRootProps } = useDropzone({ accept, onDrop });

  return (
    <div className="Dropzone" {...getRootProps()}>
      <input data-testid="file-dropzone" {...getInputProps()} />

      <img src={uploadIcon || uploadIconSvg} />

      <p className="Dropzone-description">{description}</p>

      <Button color="secondary" variant="outlined" disableElevation>
        Select Files
      </Button>

      {specifications}
    </div>
  );
};

FileDropzone.propTypes = {
  accept: PropTypes.string.isRequired,
  handleDrop: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  specifications: PropTypes.element.isRequired,
  uploadIcon: PropTypes.any,
};

export default FileDropzone;
