import React from 'react';
import PropTypes from 'prop-types';

import dashboardIcon from '../../../images/general-dashboard.png';
import cartIcon from '../../../images/cart-dashboard.png';
import downloadIcon from '../../../images/download-dashboard.png';
import {
  Box,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useController } from 'react-hook-form';
import clsx from 'clsx';

const lookerOptions = [
  {
    value: 'DEFAULT',
    label: 'General',
    icon: dashboardIcon,
  },
  {
    value: 'D2C',
    label: 'E-commerce',
    icon: cartIcon,
  },
  {
    value: 'GAMING',
    label: 'App install',
    icon: downloadIcon,
  },
];

const useStyles = makeStyles(({ spacing }) => ({
  general: {
    height: 58,
    margin: '0 auto',
    '& > img': {
      objectFit: 'scale-down',
      height: '100%',
    },
  },
  card: {
    width: 150,
    height: 150,
    cursor: 'pointer',

    '&:hover': {
      boxShadow:
        '0px 5px 5px -3px rgb(29 175 255 / 20%), 0px 8px 10px 1px rgb(29 175 255 / 14%), 0px 3px 14px 2px rgb(29 175 255 / 12%)',
    },
  },
  selectedCard: {
    backgroundColor: 'rgba(29, 175, 255, 0.2)',

    '&:hover': {
      boxShadow:
        '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    },
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    paddingBottom: '16px !important',
  },
  cardLabel: {
    marginTop: spacing(1),
    textAlign: 'center',
  },
}));

export const LookerTypeSelect = ({ control, name }) => {
  const classes = useStyles();
  const { field: lookerField, fieldState } = useController({
    control,
    name,
  });

  return (
    <FormControl error={!!fieldState.error} variant="standard">
      <Box py={2} display="flex" gridGap={16}>
        {lookerOptions.map(({ value, label, icon }) => (
          <Card
            key={value}
            raised={lookerField.value !== value}
            className={clsx(classes.card, {
              [classes.selectedCard]: lookerField.value === value,
            })}
            onClick={() => lookerField.onChange(value)}
          >
            <CardContent className={classes.cardContent}>
              <div className={classes.general}>
                <img src={icon} alt="dashboard" width="100%" />
              </div>

              <div className={classes.cardLabel}>
                <Typography>{label}</Typography>
              </div>
            </CardContent>
          </Card>
        ))}
      </Box>
      {fieldState.error && (
        <FormHelperText>{fieldState.error?.message}</FormHelperText>
      )}
    </FormControl>
  );
};

LookerTypeSelect.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
};
