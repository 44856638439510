import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Chip,
  Tabs,
  Tab,
  Typography,
  makeStyles,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckIcon from '@material-ui/icons/Check';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';

import { GeneralInfoForm } from '../GeneralInfoForm';
import { ReportingForm } from '../ReportingForm/ReportingForm';
import { SupportTab } from './SupportTab';
import { useAPI } from '../../hooks/api';

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
  active: {
    backgroundColor: '#c2f1e3',
    color: '#05734e',
  },
  admin: {
    backgroundColor: '#bfe7ff',
    color: '#2592cf',
  },
  card: {
    width: 150,
    height: 150,
    cursor: 'pointer',

    '&:hover': {
      boxShadow:
        '0px 5px 5px -3px rgb(29 175 255 / 20%), 0px 8px 10px 1px rgb(29 175 255 / 14%), 0px 3px 14px 2px rgb(29 175 255 / 12%)',
    },
  },
  selectedCard: {
    backgroundColor: 'rgba(29, 175, 255, 0.2)',

    '&:hover': {
      boxShadow:
        '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    },
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    paddingBottom: '16px !important',
  },
  cardLabel: {
    marginTop: spacing(1),
    textAlign: 'center',
  },
  cart: {
    width: 68,
    height: 58,
    margin: '0 auto',
  },
  chip: {
    marginLeft: spacing(2),
    padding: spacing(1),
  },
  download: {
    width: 35,
    height: 58,
    margin: '0 auto',
  },
  dropdown: {
    width: '48%',
  },
  emptyChannels: {
    height: 120,

    ['& td']: {
      borderBottom: 'none',
    },

    ['& td p']: {
      color: '#67747c',
    },
  },
  general: {
    width: 58,
    height: 58,
    margin: '0 auto',
  },
  submit: {
    ...typography.h4,
    color: '#fff',
    marginLeft: spacing(2),
    padding: spacing(2),
    width: 175,
  },
  successMsg: {
    color: palette.primary.light,
  },
  supportAccess: {
    color: '#67747c',
  },
  tab: {
    ['& h4']: {
      fontWeight: 500,
    },

    ['&.Mui-selected h4']: {
      fontWeight: 700,
    },
  },
  textfield: {
    marginBottom: spacing(3),
  },
}));

const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`horizontal-tabpanel-${index}`}
      aria-labelledby={`horizontal-tab-${index}`}
      style={{ width: '100%', height: '100%' }}
      {...other}
    >
      {value === index && (
        <Box p={2} height="100%">
          {children}
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const ManageAdAccount = ({ currentAccount, onCancel, categories }) => {
  const classes = useStyles();
  const { useGetAll, useGet } = useAPI();
  const [tabIndex, setTabIndex] = useState(0);

  const [advertiser, setAdvertiser] = useState(null);
  const [billingAccounts, setBillingAccounts] = useState([]);

  const handleTabChange = (event, index) => {
    setTabIndex(index);
  };

  useEffect(() => {
    if (!currentAccount.advertiser) return;

    useGet(currentAccount.advertiser, true).then(response => {
      setAdvertiser(response);
    });
  }, [currentAccount.advertiser]);

  useEffect(() => {
    useGetAll('/billing_accounts', [], results =>
      setBillingAccounts(results)
    );
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <Box mb={6}>
        <Box mb={4}>
          <Box
            mb={4}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box width="50%">
              <Button
                startIcon={<ArrowBackIcon color="secondary" />}
                onClick={onCancel}
              >
                <Typography>{currentAccount.ad_account_name}</Typography>
              </Button>
            </Box>

            <Box width="100%" display="flex" justifyContent="flex-end">
              <Chip
                className={clsx(classes.chip, classes.agency)}
                label="Agency"
              />

              <Chip
                className={clsx(classes.chip, classes.admin)}
                icon={<PersonOutlineIcon fontSize="small" />}
                label="Admin"
              />

              <Chip
                className={clsx(classes.chip, classes.active)}
                icon={<CheckIcon fontSize="small" />}
                label="Active"
              />
            </Box>
          </Box>

          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            aria-label="Business Manager Tabs"
            className={classes.tabs}
          >
            <Tab
              className={classes.tab}
              label={<Typography variant="h4">General Info</Typography>}
            />
            <Tab
              className={classes.tab}
              label={<Typography variant="h4">Reporting</Typography>}
            />
            {/* <Tab
              className={classes.tab}
              label={<Typography variant="h4">Support</Typography>}
            /> */}
          </Tabs>
        </Box>

        <Box>
          <TabPanel value={tabIndex} index={0}>
            <GeneralInfoForm
              advertiser={advertiser}
              setAdvertiser={setAdvertiser}
              categories={categories}
              billingAccounts={billingAccounts}
            />
          </TabPanel>

          <TabPanel value={tabIndex} index={1}>
            <ReportingForm
              advertiser={advertiser}
              setAdvertiser={setAdvertiser}
            />
          </TabPanel>

          <TabPanel value={tabIndex} index={2}>
            <SupportTab />
          </TabPanel>
        </Box>
      </Box>
    </Box>
  );
};

ManageAdAccount.propTypes = {
  currentAccount: PropTypes.object,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  categories: PropTypes.array,
};

export default ManageAdAccount;
