import { Box, MenuItem, makeStyles } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

import { RHFTextField } from '../../RHFTextField';

const useStyles = makeStyles(() => ({
  dropdown: {
    width: '100%',
    maxWidth: '300px',
  },
}));

const attribution = {
  model: [
    { label: 'Last Touch', value: 'LAST_TOUCH' },
    { label: 'First Touch', value: 'FIRST_TOUCH' },
    { label: 'Linear', value: 'LINEAR' },
  ],
  window: [
    { label: '30 days', value: '30' },
    { label: '14 days', value: '14' },
    { label: '7 days', value: '7' },
    { label: '3 days', value: '3' },
    { label: '2 days', value: '2' },
    { label: '1 day', value: '1' },
  ],
};

const outcomes = [
  {
    value: 'IMPRESSIONS',
    label: 'Impressions',
  },
  {
    value: 'WEB_SITE_VISITS',
    label: 'Web site visits',
  },
  {
    value: 'PURCHASES',
    label: 'Purchases',
  },
  {
    value: 'APP_INSTALLS',
    label: 'App installs',
  },
  {
    value: 'IN_APP_PURCHASES',
    label: 'In-app purchases',
  },
];

export const AttributionRoleFieldset = ({
  control,
  primaryOutcomeName,
  attModelName,
  attWindowName,
}) => {
  const classes = useStyles();

  return (
    <Box>
      <RHFTextField
        select
        label="Primary Outcome"
        className={classes.dropdown}
        name={primaryOutcomeName}
        control={control}
      >
        {outcomes.map(k => (
          <MenuItem key={k.value} value={k.value}>
            {k.label}
          </MenuItem>
        ))}
      </RHFTextField>

      <Box width="100%" mt={3} display="flex" gridGap={16}>
        <RHFTextField
          select
          className={classes.dropdown}
          label="Attribution Model"
          name={attModelName}
          control={control}
        >
          {attribution.model.map(m => (
            <MenuItem key={m.value} value={m.value}>
              {m.label}
            </MenuItem>
          ))}
        </RHFTextField>

        <RHFTextField
          select
          className={classes.dropdown}
          label="Attribution Window"
          name={attWindowName}
          control={control}
        >
          {attribution.window.map(w => (
            <MenuItem key={w.value} value={w.value}>
              {w.label}
            </MenuItem>
          ))}
        </RHFTextField>
      </Box>
    </Box>
  );
};

AttributionRoleFieldset.propTypes = {
  control: PropTypes.object.isRequired,
  primaryOutcomeName: PropTypes.string.isRequired,
  attWindowName: PropTypes.string.isRequired,
  attModelName: PropTypes.string.isRequired,
};
