import { object, string } from 'yup';

export const validationSchema = object().shape({
  name: string().required('Required'),
  address1: string().required('Required'),
  address2: string().required('Required'),
  city: string().required('Required'),
  state: string().required('Required'),
  zip: string().required('Required'),
  phone: string().required('Required'),

  dba: string(),
  attrModel: string().required('Required'),
  attrWindow: string().required('Required'),
});

export const getInitialValues = data => {
  return {
    name: data?.business_legal_name || '',
    address1: data?.street_address || '',
    address2: data?.street_address_2 || '',
    city: data?.city || '',
    state: data?.state || '',
    zip: data?.postal_code || '',
    phone: data?.business_phone || '',

    dba: data?.doing_business_as || '',
    attrModel: data?.default_attribution_model || '',
    attrWindow: data?.default_attribution_window || '',
  };
};

export const parseFormValuesToApi = values => ({
  business_phone: values.phone,
  // doing_business_as: values.dba,
  default_attribution_model: values.attrModel,
  default_attribution_window: values.attrWindow,
  street_address: values.address1,
  street_address_2: values.address2,
  city: values.city,
  state: values.state,
  postal_code: values.zip,
});
