import React, { useState, useContext, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router-dom';
import { useBeforeunload } from 'react-beforeunload';
import { Container, Paper, makeStyles } from '@material-ui/core';

import AppHeader from '../AppHeader';
import AdvertiserWizard from '../AdvertiserWizard';
import AdvertiserCostPerClick from '../AdvertiserCostPerClick';
import AdvertiserContext from '../AdvertiserContext';
import OrganizationBilling from '../OrganizationBilling';
import OrganizationInfo from '../OrganizationInfo';
import OrganizationType from '../OrganizationType';
import SensitiveCategories from '../SensitiveCategories';
import { useAPI } from '../hooks/api';
import { useAdvertisers } from '../hooks/advertisers';
import { useTenants } from '../hooks/tenants';
import { Themes } from '../../constants';
import { omit } from 'lodash';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    paddingTop: theme.spacing(0),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    paddingBottom: 19,
    minHeight: 750,
    position: 'relative',
    marginTop: -10,
  },
}));

const SetupAdvertiser = () => {
  const classes = useStyles();
  const history = useHistory();
  const adContext = useContext(AdvertiserContext);
  const location = useLocation();

  const { useGet, usePatch, usePost } = useAPI();
  const currentTenant = useTenants();
  const { createAdvertiser, formatAdvertiserUrl } = useAdvertisers();

  const [theme, setTheme] = useState(null);
  const [progress, setProgress] = useState('orgType');

  const [org, setOrg] = useState({});
  const [terms, setTerms] = useState({});
  const [billingAcct, setBillingAcct] = useState({});

  // Org Type
  // TODO: rename
  const [orgType, setOrgType] = useState('');

  // Org Info
  const [name, setName] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [phone, setPhone] = useState('');
  const [contact, setContact] = useState('');
  const [email, setEmail] = useState('');
  const [dba, setDBA] = useState('');
  const [attrModel, setAttrModel] = useState('');
  const [attrWindow, setAttrWindow] = useState('');

  // Org Billing
  const [isSameAddress, setIsSameAddress] = useState(true);
  const [isInvoice, setIsInvoice] = useState(false);
  const [isSkip, setIsSkip] = useState(false);
  const [billingName, setBillingName] = useState('');
  const [billingAddress1, setBillingAddress1] = useState('');
  const [billingAddress2, setBillingAddress2] = useState('');
  const [billingCity, setBillingCity] = useState('');
  const [billingState, setBillingState] = useState('');
  const [billingZip, setBillingZip] = useState('');
  const [billingAccountName, setBillingAccountName] = useState('');
  const [billingFirstName, setBillingFirstName] = useState('');
  const [billingLastName, setBillingLastName] = useState('');
  const [billingAccountEmail, setBillingAccountEmail] = useState('');
  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [fundingType, setFundingType] = useState('');

  // Ad Account
  const [aaName, setAAName] = useState('');
  const [adName, setAdName] = useState('');
  const [domain, setDomain] = useState('');
  const [sfAdvertiserId, setSfAdvertiserId] = useState('');
  const [sfAgencyId, setSfAgencyId] = useState('');
  const [category, setCategory] = useState('');
  const [aaKpi, setAAKpi] = useState('');
  const [aaBudget, setAABudget] = useState('');
  const [looker, setLooker] = useState(null);
  const [aaAttrModel, setAAAttrModel] = useState('');
  const [aaAttrWindow, setAAAttrWindow] = useState('');
  const [sensitiveCategory, setSensitiveCategory] = useState(null);

  // Alerting user to save before leaving page
  useBeforeunload(e => {
    switch (progress) {
      case 'orgType': {
        if (orgType !== '') {
          e.preventDefault();
        }
        break;
      }
      case 'orgInfo': {
        const hasUnsavedInfo = [
          name,
          address1,
          city,
          state,
          zip,
          phone,
          contact,
          email,
          attrModel,
          attrWindow,
        ].some(i => i !== '');

        if (hasUnsavedInfo) {
          e.preventDefault();
        }
        break;
      }
      case 'orgBilling': {
        const hasUnsavedBilling = [
          billingName,
          billingAddress1,
          billingCity,
          billingState,
          billingZip,
          billingAccountName,
          billingAccountEmail,
        ].some(i => i !== '');

        if (hasUnsavedBilling) {
          e.preventDefault();
        }
        break;
      }
    }
  });

  useEffect(() => {
    const userType = localStorage.getItem('userType');
    const name = localStorage.getItem('company');

    adContext.updateAdvertiser({
      name,
      userType,
    });
  }, []);

  useEffect(() => {
    if (adContext && adContext.id) {
      getProfilesRefresh();
    }
  }, [adContext]);

  useEffect(() => {
    if (selectedCard && adContext.theme === Themes.DEFAULT) {
      setFundingType('CC');
    }
  }, [adContext, selectedCard]);

  useEffect(() => {
    if (adContext.theme === Themes.NBCU) {
      setFundingType('Invoice');
    }
  }, [adContext]);

  useEffect(() => {
    if (theme === Themes.NBCU) {
      setLooker('NBCU PADMAN');
    }
  }, [theme]);

  useEffect(() => {
    if (isSameAddress) {
      setBillingName(name);
      setBillingAddress1(address1);
      setBillingAddress2(address2);
      setBillingCity(city);
      setBillingState(state);
      setBillingZip(zip);
      setBillingAccountName(contact);
      setBillingAccountEmail(email);
    }

    if (!isSameAddress) {
      setBillingName('');
      setBillingAddress1('');
      setBillingAddress2('');
      setBillingCity('');
      setBillingState('');
      setBillingZip('');
      setBillingAccountName('');
      setBillingAccountEmail('');
    }
  }, [isSameAddress]);

  useEffect(() => {
    if (adContext && adContext.theme) {
      setTheme(adContext.theme);
    }
  }, [adContext]);

  useEffect(() => {
    if (currentTenant) {
      getTermsAndConditions();
    }
  }, [currentTenant]);

  useEffect(() => {
    if (org && org.url) {
      handleUserAgreement();
    }
  }, [org]);

  const orgData = useMemo(() => {
    const { SFDCAccountId } = location.state || {};
    const dataObj = {
      name,
      business_phone: phone,
      business_physical_address: `${address1}, ${
        address2 && `${address2}, `
      }${city}, ${state}, ${zip}`,
      default_attribution_model: attrModel || null,
      default_attribution_window: attrWindow || null,
      primary_tenant: currentTenant,
      sfdc_account_id: SFDCAccountId,
      owner: adContext.owner,
    };

    if (dba && dba !== '') {
      dataObj.doing_business_as = dba;
    }

    return dataObj;
  }, [
    orgType,
    phone,
    address1,
    address2,
    city,
    dba,
    state,
    zip,
    attrModel,
    attrWindow,
    location.state,
    adContext.owner,
  ]);

  const adAcctData = useMemo(() => {
    return {
      ad_account_name: aaName,
      ad_account_budget: aaBudget || null,
      attribution_model: aaAttrModel,
      attribution_window: aaAttrWindow,
      name: adName,
      category,
      domain: formatAdvertiserUrl(domain),
      looker_experience: looker,
      looker_validated: true,
      primary_kpi: aaKpi,
      regulated_brand_type: sensitiveCategory || null,
      primary_org: org && org.url ? org.url : null,
      type: orgType,
      owner: adContext.owner,
    };
  }, [
    aaName,
    aaBudget,
    adName,
    aaKpi,
    aaAttrWindow,
    aaAttrModel,
    category,
    domain,
    looker,
    orgType,
    sensitiveCategory,
    org?.url,
    adContext?.owner,
  ]);

  const billingData = useMemo(() => {
    return {
      billing_method: currentTenant.includes('1') ? 'CC' : 'Invoice',
      name: billingName,
      parent_org: org.url,
      city: billingCity,
      secondary_contact: billingAccountEmail,
      secondary_first_name: billingFirstName,
      secondary_last_name: billingLastName,
      state: billingState,
      postal_code: billingZip,
      primary_contact: adContext.owner,
      street_address: billingAddress1,
      street_address_2: billingAddress2,
      funding_type_cc_allowed: selectedCard && fundingType === 'CC',
      funding_type_invoice_allowed: fundingType === 'Invoice',
    };
  }, [
    currentTenant,
    org,
    billingName,
    billingAccountName,
    billingFirstName,
    billingLastName,
    billingAccountEmail,
    billingCity,
    billingState,
    billingZip,
    billingAddress1,
    billingAddress2,
    selectedCard,
    fundingType,
  ]);

  async function getProfilesRefresh() {
    try {
      const res = await useGet(`/payment_profiles/refresh`);

      if (res && res.results && res.results.length > 0) {
        const reversed = res.results.reverse();

        setSelectedCard(reversed[0].url);
        setCards(reversed);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getTermsAndConditions() {
    // TODO refactor to get tenant id from /tenants api call
    const arr = currentTenant.split('/');
    const id = arr[arr.length - 2];

    try {
      const res = await useGet(`/terms_and_conditions/latest/${id}`);
      setTerms(res);
      console.log('res from terms and conditions', res);
      return res;
    } catch (error) {
      console.log('error getting terms and conditions');
      return error;
    }
  }

  const handleUserAgreement = async () => {
    const dataObj = {
      terms: terms.url,
      user: adContext.owner,
      organization: org.url,
    };

    try {
      const response = await usePost('/user_agreements/', dataObj);

      if (response) {
        console.log('response in saving user agreement', response);
      }

      return response;
    } catch (error) {
      console.log('error in saving user agreement ', error);

      return error;
    }
  };

  const handleOrgInfo = () => {
    if (isSameAddress) {
      setBillingName(name);
      setBillingAddress1(address1);
      setBillingAddress2(address2);
      setBillingCity(city);
      setBillingState(state);
      setBillingZip(zip);
      setBillingAccountName(contact);
      setBillingAccountEmail(email);
    }

    setProgress('adWizard');
  };

  const handleBillingAccount = async () => {
    if (!isSkip) {
      try {
        const res = billingAcct && billingAcct.id
          ? await usePatch(`/billing_accounts/${billingAcct.id}`, omit(billingData, ['primary_org']))
          : await usePost('/billing_accounts', billingData)

        setBillingAcct(res.data);
        console.log('res from saving Billing Account', res);
      } catch (err) {
        console.error('Error saving billing account', err);
      }
    } else {
      setProgress('lastClick');
    }
  };

  const handleSaveOrg = async () => {
    try {
      const response =
        org && org.id
          ? await usePatch(`/organizations/${org.id}`, omit(orgData, ['primary_tenant']))
          : await usePost('/organizations/', orgData);

      if (response) {
        console.log('response in saving org', response);
        setOrg(response.data);
        setProgress('adWizard');
      }

      return response;
    } catch (error) {
      console.log('error in saving Organization', error);
    }
  };

  const handleSaveAdAccount = async data => {
    const dataObj = {
      ...adAcctData,
      ...data,
    };

    if (isSameAddress) {
      setBillingName(name);
      setBillingAddress1(address1);
      setBillingAddress2(address2);
      setBillingCity(city);
      setBillingState(state);
      setBillingZip(zip);
      setBillingAccountName(contact);
      setBillingAccountEmail(email);
    }

    try {
      const res =
        adContext && adContext.id
          ? await usePatch(`/advertisers/${adContext.id}`, dataObj)
          : await createAdvertiser(dataObj);

      return res;
    } catch (error) {
      console.log('error in creating advertiser', error);
      return error;
    }
  };

  return (
    <AppHeader
      theme={theme}
      hidenav="true"
      checkAuthentication={false}
    >
      <Container
        maxWidth="lg"
        className={clsx(classes.container, 'SetupWizard')}
      >
        <Paper
          className={clsx(classes.paper, '--background-colorbar')}
          elevation={12}
        >
          {progress === 'orgType' && (
            <OrganizationType
              onNext={() => setProgress('orgInfo')}
              orgType={orgType}
              setOrgType={setOrgType}
            />
          )}

          {progress === 'orgInfo' && (
            <OrganizationInfo
              onNext={handleOrgInfo}
              onBack={() => setProgress('orgType')}
              org={{
                name,
                setName,
                address1,
                setAddress1,
                address2,
                setAddress2,
                city,
                setCity,
                state,
                setState,
                zip,
                setZip,
                phone,
                setPhone,
                contact,
                setContact,
                email,
                setEmail,
                dba,
                setDBA,
                attrModel,
                setAttrModel,
                attrWindow,
                setAttrWindow,
              }}
              handleSaveOrg={handleSaveOrg}
            />
          )}

          {progress === 'orgBilling' && (
            <OrganizationBilling
              advertiserId={adContext.id}
              isSameAddress={isSameAddress}
              setIsSameAddress={setIsSameAddress}
              isInvoice={isInvoice}
              isSkip={isSkip}
              setIsSkip={setIsSkip}
              setIsInvoice={setIsInvoice}
              cards={cards}
              selectedCard={selectedCard}
              setSelectedCard={setSelectedCard}
              getProfilesRefresh={getProfilesRefresh}
              onBack={() => setProgress('adWizard')}
              onNext={() => setProgress('lastClick')}
              handleBillingAccount={handleBillingAccount}
              name={name}
              handleSaveOrg={handleSaveOrg}
              org={{
                billingName,
                setBillingName,
                billingAddress1,
                setBillingAddress1,
                billingAddress2,
                setBillingAddress2,
                billingCity,
                setBillingCity,
                billingState,
                setBillingState,
                billingZip,
                setBillingZip,
                billingAccountName,
                setBillingAccountName,
                billingFirstName,
                setBillingFirstName,
                billingLastName,
                setBillingLastName,
                billingAccountEmail,
                setBillingAccountEmail,
              }}
            />
          )}

          {progress === 'adWizard' && (
            <AdvertiserWizard
              adAccount={{
                aaName,
                setAAName,
                adName,
                setAdName,
                domain,
                setDomain,
                category,
                setCategory,
                aaKpi,
                setAAKpi,
                aaBudget,
                setAABudget,
                looker,
                setLooker,
                aaAttrModel,
                setAAAttrModel,
                aaAttrWindow,
                setAAAttrWindow,
                sfAdvertiserId,
                setSfAdvertiserId,
                sfAgencyId,
                setSfAgencyId,
              }}
              showKPI={theme !== Themes.NBCU}
              showModel={theme !== Themes.NBCU}
              showWindow={true}
              showSalesforce={theme === Themes.NBCU}
              handleSaveAdAccount={handleSaveAdAccount}
              onNext={() => setProgress('orgBilling')}
              onBack={() => setProgress('orgInfo')}
            />
          )}

          {progress === 'lastClick' && (
            <AdvertiserCostPerClick
              onNext={() => setProgress('sensitive')}
              onBack={() => setProgress('orgBilling')}
            />
          )}

          {progress === 'sensitive' && (
            <SensitiveCategories
              sensitiveCategory={sensitiveCategory}
              setSensitiveCategory={setSensitiveCategory}
              handleSaveAdAccount={handleSaveAdAccount}
              onNext={() => history.push('/campaign-setup')}
              onBack={() => setProgress('lastClick')}
            />
          )}
        </Paper>
      </Container>
    </AppHeader>
  );
};

export default SetupAdvertiser;
