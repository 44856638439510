import { useContext } from 'react';

import AdvertiserContext from '../AdvertiserContext';
import { Themes } from '../../constants/themes';

const Tenants = {
  [Themes.DEFAULT]: `${process.env.API_URL}/v1/tenants/1/`,
  [Themes.NBCU]: `${process.env.API_URL}/v1/tenants/2/`
};

export const useTenants = () => {
  const adContext = useContext(AdvertiserContext);

  return Tenants[adContext.theme];
};
