import React, { Suspense, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  CircularProgress,
  Grid,
  makeStyles,
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import RefreshIcon from '@material-ui/icons/Refresh';

import FileUploader from './FileUploader';
import CampaignFooter from './CampaignFooter';
import { useLoader } from './hooks/loader';

// TODO: Abstract into UI component
const LoadingSpinner = () => {
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{
        position: 'absolute',
        top: '0',
        left: '0',
        background: 'rgba(255,255,255,0.6)',
      }}
    >
      <CircularProgress color="secondary" />
    </Box>
  );
}

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    height: '100%',
    marginBottom: spacing(0),
  },
  icon: {
    fontSize: '1.25rem',
  },
  main: {
    height: '100%',
  },
  refresh: {
    fontSize: '1.25rem !important',
  },
}));

const CreativeUploader = props => {
  const classes = useStyles();

  const {
    isLoading: isUploading,
    setIsLoading: setIsUploading
  } = useLoader();

  const {
    experimentStatus,
    onSubmit,
    handleAssign,
    handleUnassign,
    handleDeleteCreatives,
    showVastTag,
    isDisplay,
    isExperiment,
    isSubmitDisabled,
    isLoading,
    setIsLoading,
    setCurrentPreview,
    setIsCreativePreview,
    next,
    onBack,
  } = props;

  useEffect(() => {
    return () => {
      setIsLoading(false);
      setIsUploading(false);
    }
  }, []);

  const nextCopy = useMemo(() => {
    if (isExperiment) {
      return experimentStatus.copy;
    }

    if (isDisplay) {
      return 'Upload Display';
    }

    return next;
  }, [isExperiment, isDisplay, experimentStatus, next]);

  return (
    <form onSubmit={onSubmit}>
      <Grid className={classes.container} container>
        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="column"
            height="100%"
            justifyContent="space-between"
          >
            <Box
              className={classes.main}
              flexGrow={1}
              width="100%"
              height="100%"
            >
              <Suspense fallback={<LoadingSpinner />}>
                <FileUploader
                  accept="video/mp4, video/x-m4v, video/*"
                  handleAssign={handleAssign}
                  handleUnassign={handleUnassign}
                  handleDeleteFile={handleDeleteCreatives}
                  showVastTag={showVastTag}
                  setIsLoading={setIsLoading}
                  setCurrentPreview={setCurrentPreview}
                  setIsCreativePreview={setIsCreativePreview}
                  setIsUploading={setIsUploading}
                  type="video"
                />
              </Suspense>
            </Box>

            <Box mt={4}>
              <Grid container item xs={12}>
                <CampaignFooter
                  nativeType="submit"
                  isDisabled={isSubmitDisabled || isUploading}
                  isNextHidden={experimentStatus.completed}
                  isLoading={isLoading}
                  back={'Name Ad Group'}
                  endIcon={isDisplay
                    ? <ArrowForwardIcon />
                    : isLoading
                      ? <CircularProgress color="secondary" size={18} />
                      : <RefreshIcon className={classes.refresh} />
                  }
                  next={nextCopy}
                  onBack={onBack}
                />
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

CreativeUploader.propTypes = {
  displays: PropTypes.array,
  experimentStatus: PropTypes.object,
  onSubmit: PropTypes.func,
  handleAssign: PropTypes.func,
  handleUnassign: PropTypes.func,
  isDisplay: PropTypes.bool,
  isExperiment: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSubmitDisabled: PropTypes.bool,
  handleDeleteCreatives: PropTypes.func,
  showVastTag: PropTypes.bool,
  setCurrentPreview: PropTypes.func,
  setIsCreativePreview: PropTypes.func,
  setIsLoading: PropTypes.func,
  next: PropTypes.string,
  onBack: PropTypes.func,
};

export default CreativeUploader;
